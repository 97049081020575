import React, { useMemo } from 'react'
import { iMessage } from './iMessage.ts'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import MarkdownPreview from 'react-markdown'
import {
  codeMarkdown,
  liMarkdown,
  preMarkdown,
  aMarkdown,
} from '../../utils/markdownComponents.tsx'
import remarkGfm from 'remark-gfm'
import './markdown.scss'
import rehypeStringify from 'rehype-stringify'
import { iCitation } from './sourceFiles/iSourceFileCitations.ts'

type MarkdownProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  value: string
  message?: iMessage
  onClickCitation?: (citation: iCitation | null) => void
}
const Markdown = ({ value, message, onClickCitation }: MarkdownProps) => {
  const preprocessLaTeX = useMemo(() => {
    // Replace inline LaTeX delimiters \( \) with $ $
    let replacedString = value.replace(/\\\([^()]*\)/g, (match) => {
      match = match.replace(/\\\(/g, '') // Remove opening parentheses
      match = match.replace(/\\\)/g, '') // Remove closing parentheses
      return `\`$$${match}$$\``
    })

    // Replace block-level LaTeX delimiters \[ \] with $$ $$
    replacedString = replacedString.replace(
      /(\\\[(?:(\[)??[^\[]*?\]))/gm,
      (match) => {
        match = match.replace(/\\\[/g, '') // Remove opening parentheses
        match = match.replace(/\\]/g, '') // Remove closing parentheses
        return `\n\`\`\`katex \n${match.replace(/[\r\n]+/gm, '')}\n\`\`\``
      },
    )
    return replacedString
  }, [value])
  return (
    <div data-color-mode="light" className={'gaia-markdown'}>
      <MarkdownPreview
        skipHtml={false}
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[rehypeStringify, rehypeKatex]}
        components={
          // replace all li[id^="user-content-fn-cid-"] with Citation component
          {
            h2: (props) => {
              return <h4 className="text-lg font-semibold">{props.children}</h4>
            },
            pre: (props) => {
              return preMarkdown(props)
            },
            li: (props) => {
              return liMarkdown(
                props,
                message,
                (cidNumber) => onClickCitation && onClickCitation(cidNumber),
              )
            },
            code: ({ children = [], className, ...props }) => {
              return codeMarkdown({ children, className, props })
            },
            a: (props) => aMarkdown(props),
          }
        }
      >
        {preprocessLaTeX}
      </MarkdownPreview>
    </div>
  )
}

export default Markdown
