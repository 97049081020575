import React, { useState } from 'react'
import { Card, CardHeader, Spinner } from '@nextui-org/react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

type NavItemProps = React.HTMLAttributes<HTMLDivElement> & {
  onPress?: ((e: any) => void) | undefined
  icon?: React.ReactNode
  title: string
  subtitle?: string
  isLoading?: boolean
  isDisabled?: boolean
}

function NavItem({
  onPress,
  isLoading,
  subtitle,
  icon,
  isDisabled,
  title,
}: NavItemProps) {
  return (
    <Card
      className="my-2 w-full  bg-background/40"
      shadow={'none'}
      isDisabled={isLoading}
      isPressable
      isHoverable
      onPress={onPress}
    >
      <CardHeader className="flex gap-3">
        {isLoading ? (
          <Spinner></Spinner>
        ) : (
          (icon ?? <PlusCircleIcon className="w-8 h-8" />)
        )}

        <div className="flex flex-col">
          <p className="text-start text-md">{title}</p>
          {subtitle && <p className="text-small text-default-500">{subtitle}</p>}
        </div>
      </CardHeader>
    </Card>
  )
}

export default NavItem
