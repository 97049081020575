import React, { useEffect, useState } from 'react'
import { ExecutionEnvironment, iTool } from './iTool.ts'
import { iHttpTemplate } from './httpTemplates/model'
import { ShareType } from '../../../interfaces/iItem'
import MembersSelection from '../../utils/memberSelection'
import { iShared } from '../../../interfaces/iShared'
import ToolService from '../../../services/toolService.ts'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { deepCopy } from '../../utils/deepCopy.ts'
import HttpTemplateEditor from './httpTemplates/httpTemplateEditor'
import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { iValidationError } from '../../utils/iValidation.tsx'
import { ModalBody, ModalFooter, ModalHeader, Tooltip } from '@nextui-org/react'
import { getUserKey } from '../../login/authentication.tsx'
import { OverridableParameterSet } from './model.ts'
import OverridableEditor from './overridableSetEditor.tsx'

interface iToolFormProps {
  currentTool?: iTool // If a tool is passed, we're in edit mode. Otherwise, it's create mode.
  closeFunction: () => void
}

const tooltipShareWith =
  'Limited: Only selected members can access this presetting. Public: Everyone can access this presetting. People enlisted can also modify this presetting. '

function ToolCreateEdit({ currentTool, closeFunction }: iToolFormProps) {
  const [newTool, setNewTool] = useState<iTool>({
    id: currentTool?.id || undefined,
    selected: currentTool?.selected || false,
    name: currentTool?.name || '',
    description: currentTool?.description || '',
    functionName: currentTool?.functionName || '',
    citationRequired: currentTool?.citationRequired || false,
    image: currentTool?.image || '',
    sharedType: currentTool?.sharedType || ShareType.None,
    shared: currentTool?.shared || [],
    executionEnvironment: ExecutionEnvironment.Local,
    settings: currentTool?.settings || {},
    ownerId: getUserKey(),
    fromBaseAssistantInherited: false,
    parameterOverrides: currentTool?.parameterOverrides || [],
    ...currentTool,
  })
  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])
  const [isHttpTool, setIsHttpTool] = useState<boolean>(false)
  const [httpTemplate, setHttpTemplate] = useState<iHttpTemplate>(
    currentTool?.httpTemplate || {
      url: '',
      method: 'auto',
      constants: {},
      parameters: {},
    },
  )
  const [shared, setShared] = useState<iShared[]>([])
  const [definitionStyle, setDefinitionStyle] = useState<
    null | 'default' | 'templateString'
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState<iValidationError[]>([])
  const [parameterOverrides, setParameterOverrides] = useState<string[]>(
    newTool.parameterOverrides || [],
  )
  const [overridables, setOverridables] = useState<OverridableParameterSet>(
    newTool.overridableParameters || {},
  )

  const editingTool = currentTool !== undefined

  useEffect(() => {
    if (isHttpTool) {
      setNewTool({
        ...newTool,
        httpTemplate,
        executionEnvironment: ExecutionEnvironment.ExternalHttp,
      })
    } else {
      setNewTool({
        ...newTool,
        httpTemplate: undefined,
        executionEnvironment: ExecutionEnvironment.Local,
      })
    }
  }, [isHttpTool])

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = +event.target.value as unknown as ShareType
    setNewTool({ ...newTool, sharedType: newValue })
    switch (newValue) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }

  const getTitle = () => {
    if (editingTool) {
      return 'Edit tool'
    } else {
      return 'Create tool'
    }
  }

  const handleErrorResponse = (error: any) => {
    if (error.response && error.response.status === 422) {
      if (error.response.data) {
        const errors = error.response.data.errors as iValidationError[]
        setValidationErrors(errors)
      } else {
        setValidationErrors([{ message: 'Unknown validation error', field: '' }])
      }
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (
      newTool.name &&
      newTool.description &&
      newTool.functionName &&
      newTool.image
    ) {
      setIsLoading(true)
      if (editingTool) {
        ToolService.updateTool(newTool)
          .then(closeFunction)
          .catch(handleErrorResponse)
          .finally(() => setIsLoading(false))
      } else {
        ToolService.createTool(newTool)
          .then(closeFunction)
          .catch(handleErrorResponse)
          .finally(() => setIsLoading(false))
      }
    } else {
      closeFunction()
    }
  }

  useEffect(() => {
    if (currentTool) {
      setNewTool(currentTool)
      setIsHttpTool(!!currentTool.httpTemplate)
      if (!currentTool.shared) {
        setShared([])
        setNewTool({ ...newTool, shared: [] })
      } else {
        setShared(currentTool.shared!)
        setNewTool({ ...newTool, shared: currentTool.shared! })
      }
    }
  }, [])

  useEffect(() => {
    setNewTool({ ...newTool, shared: shared })
  }, [shared])

  useEffect(() => {
    if (definitionStyle === 'default') {
      setNewTool({ ...newTool, httpTemplate: httpTemplate })
    }
    if (definitionStyle === 'templateString') {
      setNewTool({ ...newTool, httpTemplateString: '<HTTP request template>' })
    }
  }, [definitionStyle])

  const handleDeleteParameterOverride = (index: number) => {
    const updatedOverrides = [...parameterOverrides]
    updatedOverrides.splice(index, 1)
    setParameterOverrides(updatedOverrides)
    setNewTool({ ...newTool, parameterOverrides: updatedOverrides })
  }
  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>{getTitle()}</h1>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit} className={'min-w-[350px]'}>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Name</span>
            </label>
            <input
              id="toolName"
              type="text"
              className="input input-bordered w-full"
              value={newTool.name}
              onChange={(e) => setNewTool({ ...newTool, name: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Function name</span>
            </label>
            <input
              id="toolFunctionName"
              type="text"
              className="input input-bordered w-full"
              value={newTool.functionName}
              onChange={(e) =>
                setNewTool({ ...newTool, functionName: e.target.value })
              }
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Image url</span>
            </label>
            <input
              id="toolImage"
              type="text"
              className="input input-bordered w-full"
              value={newTool.image}
              onChange={(e) => setNewTool({ ...newTool, image: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Description</span>
            </label>
            <textarea
              id="toolDescription"
              className="textarea textarea-bordered w-full"
              value={newTool.description}
              onChange={(e) =>
                setNewTool({ ...newTool, description: e.target.value })
              }
              rows={4}
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Citation required</span>
            </label>
            <input
              type="checkbox"
              className="checkbox checkbox-sm"
              checked={newTool.citationRequired}
              onChange={(e) =>
                setNewTool({ ...newTool, citationRequired: e.target.checked })
              }
            />
          </div>
          <div className={'w-full '}>
            <label className="label">
              <span className="label-text flex">
                Share with
                <Tooltip
                  content={tooltipShareWith}
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <InformationCircleIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.0}
                    stroke="currentColor"
                    className="w-4 h-4 ml-1"
                  />
                </Tooltip>
              </span>
              <select
                className="select select-bordered w-1/2 max-w-xs"
                value={newTool.sharedType}
                onChange={onSelectChange}
              >
                <option value={ShareType.None}>None</option>
                <option value={ShareType.Public}>Public</option>
                <option value={ShareType.Limited}>Limited</option>
              </select>
            </label>
          </div>

          {newTool.sharedType !== ShareType.None && (
            <>
              <label className="label ">
                <span className="label-text">Members</span>
              </label>
              <MembersSelection
                shared={shared}
                ownerId={newTool.ownerId}
                setShared={setShared}
                writeOnly={newTool.sharedType === ShareType.Public}
                roles={newTool!.roles}
              />
            </>
          )}

          {
            <div>
              <div className="text-base mb-2 mt-2 flex">
                <input
                  type="checkbox"
                  className="checkbox checkbox-sm mr-2 mt-2"
                  checked={isHttpTool}
                  onChange={() => setIsHttpTool(!isHttpTool)}
                />
                <label className={'label'}>
                  <span className={'label-text text-sm'}>API Tool</span>
                </label>
              </div>
              {isHttpTool && (
                <HttpTemplateEditor
                  currentHttpTemplate={httpTemplate}
                  onChange={(template) =>
                    setNewTool({ ...newTool, httpTemplate: template })
                  }
                />
              )}
            </div>
          }
          <div className="mb-4">
            <OverridableEditor
              overridableSet={overridables}
              onChange={(o) => setNewTool({ ...newTool, overridableParameters: o })}
            ></OverridableEditor>
          </div>
        </form>
        {validationErrors.length > 0 && (
          <div className="alert alert-warning mt-3">
            <ExclamationTriangleIcon className="w-8 h-8" />
            <ul>
              {validationErrors.map((error) => (
                <li key={error.field}>{error.message}</li>
              ))}
            </ul>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn mr-2 w-20" onClick={closeFunction}>
          Cancel
        </button>
        {(!isLoading && (
          <button
            type="submit"
            className="btn btn-primary w-20"
            disabled={
              !newTool.name ||
              !newTool.description ||
              !newTool.functionName ||
              !newTool.image
            }
            onClick={handleSubmit}
          >
            Save
          </button>
        )) || (
          <button className="btn btn-primary w-20" disabled>
            <span className="loading loading-spinner w-8 place-self-center"></span>
          </button>
        )}
      </ModalFooter>
    </>
  )
}

export default ToolCreateEdit
