import { iBlobContainerEdit } from './iBlobUpload.tsx'
import { iKnowledgeContainer, iRagStrategy } from './iKnowledgeContainer.ts'
import React, { useEffect, useMemo, useState } from 'react'
import { useMain } from '../../../stateManagement/contexts/mainContext.tsx'
import { iShared } from '../../../interfaces/iShared.ts'
import MembersSelection from '../../utils/memberSelection.tsx'
import { ShareType } from '../../../interfaces/iItem.ts'
import KnowledgeContainerService from '../../../services/knowledgeContainerService.ts'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { deepCopy } from '../../utils/deepCopy.ts'
import { useTranslation } from 'react-i18next'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { getRagDeveloperRole } from '../../login/authentication.tsx'
import {
  FlagFieldEditor,
  TextFieldEditor,
  SelectFieldEditor,
} from '../../utils/inputfields/fieldEditors.tsx'
import {
  FlagField,
  SelectField,
  SelectOption,
  TextField,
} from '../../utils/inputfields/model.ts'
import ToolService from '../../../services/toolService.ts'
import { iTool } from '../tools/iTool.ts'
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
  Tooltip,
} from '@nextui-org/react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import TeamSelect from '../../team/teamSelect.tsx'
import { Key } from '@react-types/shared'
import DocumentAnalysisSelection from './documentAnalysisSelection.tsx'

const tooltipShareWith =
  'Limited: Only selected members can access this container. Public: Everyone can access this container. People enlisted can also modify this container. '

interface iKnowledgeContainerFormInput {
  name: string
  description: string
  teamId: string
  sharedType: ShareType
  documentAnalysisModelId: string
}

function BlobAddEditContainer({
  currentContainer,
  closeFunction,
}: iBlobContainerEdit) {
  const emptyRagStrategy = () => ({
    indexingUrl: '',
    retrievalToolId: '',
  })

  const { setError } = useMain()
  const [knowledgeContainer, setKnowledgeContainer] =
    useState<iKnowledgeContainer>(currentContainer)

  const {
    setValue,
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<iKnowledgeContainerFormInput>({
    defaultValues: {
      name: knowledgeContainer?.name,
      description: knowledgeContainer?.description,
      teamId: knowledgeContainer.teamId,
      documentAnalysisModelId: knowledgeContainer.documentAnalysisModelId,
    },
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [shared, setShared] = useState<iShared[]>(currentContainer.shared || [])
  const [selectedTeam, setSelectedTeam] = React.useState(new Set([]))
  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])
  const { t } = useTranslation()
  const [isCustomRagAllowed, setIsCustomRagAllowed] = useState<boolean>(false)
  const [ragStrategy, setRagStrategy] = useState<iRagStrategy | undefined>(
    currentContainer.ragStrategy,
  )
  const [retrievalTools, setRetrievalTools] = useState<SelectOption[]>([
    { key: '', display: '' },
  ])
  const [selectedRetrievalTool, setSelectedRetrievalTool] = useState<SelectOption>({
    key: '',
    display: '',
  })

  const isNewContainer = useMemo(
    () => currentContainer.name === '',
    [currentContainer],
  )
  useEffect(() => {
    switch (knowledgeContainer.sharedType) {
      case ShareType.Limited:
        setSharedLimited(shared)
        break
      case ShareType.Public:
        setSharedPublic(shared)
        break
      case undefined:
        // set to None
        setKnowledgeContainer({ ...knowledgeContainer, sharedType: ShareType.None })
    }
    const ragRole = getRagDeveloperRole()
    if (ragRole === 'user') {
      setIsCustomRagAllowed(true)
    }
    ToolService.getTools('').then((tools: iTool[]) => {
      const toolOptions = [
        { key: '', display: '' },
        ...tools.map((p) => ({ key: p.id!, display: p.name! })),
      ]
      setRetrievalTools(toolOptions)
      if (ragStrategy?.retrievalToolId) {
        const toolOption = toolOptions.find(
          (p) => p.key === currentContainer.ragStrategy?.retrievalToolId,
        )
        if (toolOption) {
          setSelectedRetrievalTool(toolOption)
        }
      }
    })
    setSelectedTeam([knowledgeContainer.teamId] as never)
  }, [])

  useEffect(() => {
    const toolId = selectedRetrievalTool?.key || ''
    if (!toolId) {
      return
    }
    setRetrievalTools(retrievalTools.filter((p) => p.key !== ''))
    const s = {
      ...ragStrategy,
      retrievalToolId: toolId,
    } as iRagStrategy
    setRagStrategy(s)
  }, [selectedRetrievalTool])

  useEffect(() => {
    plausabilityCheckContainer(knowledgeContainer?.name)
  }, [knowledgeContainer])

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = +event.target.value as unknown as ShareType
    setKnowledgeContainer({ ...knowledgeContainer, sharedType: newValue })
    switch (newValue) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }

  const getTitle = () => {
    if (currentContainer.name !== '') {
      return 'Edit Knowledge Container'
    } else {
      return 'Add Knowledge Container'
    }
  }

  const plausabilityCheckContainer = (container: string) => {
    // name cannot have umlauts
    if (/[äöüÄÖÜß]/.test(container)) {
      return 'Knowledge Container name cannot contain umlauts'
    }

    return true
  }

  const saveContainer: SubmitHandler<iKnowledgeContainerFormInput> = (data) => {
    const newKnowledgeContainer = { ...knowledgeContainer }
    setMessage('')

    // new container
    if (currentContainer.name === '') {
      KnowledgeContainerService.createKnowledgeContainer(newKnowledgeContainer)
    }
    newKnowledgeContainer.shared = shared
    newKnowledgeContainer.teamId = data.teamId
    newKnowledgeContainer.name = data.name
    newKnowledgeContainer.description = data.description
    newKnowledgeContainer.documentAnalysisModelId = data.documentAnalysisModelId
    newKnowledgeContainer.ragStrategy = ragStrategy
    setIsLoading(true)
    if (isNewContainer) {
      KnowledgeContainerService.createKnowledgeContainer(newKnowledgeContainer)
        .then(() => {
          setIsLoading(false)
          closeFunction()
        })
        .catch((error) => {
          console.error(error)
          if (error?.response?.status === 409) {
            setMessage('Knowledge Container already exists')
          }
        })
    } else {
      KnowledgeContainerService.updateKnowledgeContainerContainer(
        newKnowledgeContainer,
      )
        .then(() => {
          setIsLoading(false)
          closeFunction()
        })
        .catch((error) => {
          console.error(error)
          setError(error)
        })
    }
  }

  const shareTypeItems = [
    { value: ShareType.None, text: 'None' },
    { value: ShareType.Public, text: 'Public' },
    { value: ShareType.Limited, text: 'Limited' },
  ]

  const selectedShareTypeValues: 'all' | Iterable<Key> | undefined = useMemo(
    () =>
      knowledgeContainer
        ? ([knowledgeContainer.sharedType?.toString()] as Iterable<Key>)
        : undefined,
    [knowledgeContainer],
  )

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>{getTitle()}</h1>
      </ModalHeader>
      <ModalBody>
        <div className={'w-[25rem] '}>
          <Input
            type="text"
            label="Name"
            variant="flat"
            {...register('name', {
              required: 'Name is required',
              validate: plausabilityCheckContainer,
            })}
            className="mb-4"
            isInvalid={!!errors.name}
            errorMessage={errors.name?.message}
          />

          <Textarea
            label="Description"
            variant="flat"
            {...register('description', { required: 'Description is required' })}
            rows={2}
            placeholder={t('translation:container.description.placeholder')}
            className="mb-4"
            isInvalid={!!errors.description}
            errorMessage={errors.description?.message}
          />

          <div className={'w-full '}>
            <label className="label ">
              <span className="label-text flex">
                Team cost center
                <Tooltip
                  content={
                    'Indexing a knowledge container is associated with costs. For this reason, please select the team to which the costs are to be allocated.'
                  }
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <InformationCircleIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.0}
                    stroke="currentColor"
                    className="w-4 h-4 ml-1"
                  />
                </Tooltip>
              </span>
              <Controller
                name="teamId"
                control={control}
                rules={{ required: 'Team is required' }}
                render={({ field }) => (
                  <TeamSelect
                    selectedKeys={selectedTeam}
                    {...field}
                    className="w-1/2 max-w-xs"
                    withoutDefaultTeam
                    withoutTeamIcon
                    withoutCostcenter
                    isDisabled={!isNewContainer}
                    isInvalid={!!errors.teamId}
                    errorMessage={errors.teamId?.message}
                    onSelectionChange={(team) => {
                      setValue('teamId', team.id!)
                      setSelectedTeam([team.id] as never)
                    }}
                  ></TeamSelect>
                )}
              />
            </label>
          </div>

          <Controller
            name="documentAnalysisModelId"
            control={control}
            rules={{ required: 'Document analysis is required' }}
            render={({ field }) => (
              <DocumentAnalysisSelection
                {...field}
                isInvalid={!!errors.documentAnalysisModelId}
                errorMessage={errors.documentAnalysisModelId?.message}
                onSelectionChange={(analysisType) => {
                  setValue('documentAnalysisModelId', analysisType)
                }}
                defaultValue={knowledgeContainer.documentAnalysisModelId}
              ></DocumentAnalysisSelection>
            )}
          />
          <div className={'w-full '}>
            <label className="label ">
              <span className="label-text flex">
                {t('translation:popup.share_with')}
                <Tooltip
                  content={tooltipShareWith}
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <InformationCircleIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.0}
                    stroke="currentColor"
                    className="w-4 h-4 ml-1"
                  />
                </Tooltip>
              </span>
              <Select
                aria-label="Share with"
                selectedKeys={selectedShareTypeValues}
                items={shareTypeItems}
                className="w-1/2 max-w-xs"
                onChange={(e) => onSelectChange(e)}
              >
                {(shareType) => (
                  <SelectItem
                    key={shareType.value}
                    textValue={shareType.text}
                    value={shareType.value}
                  >
                    <div className="flex gap-2 items-center">
                      <div className="flex flex-col truncate">
                        <span className="text-small truncate">{shareType.text}</span>
                      </div>
                    </div>
                  </SelectItem>
                )}
              </Select>
            </label>
          </div>

          {knowledgeContainer.sharedType !== ShareType.None && (
            <MembersSelection
              shared={shared}
              ownerId={knowledgeContainer.ownerId}
              setShared={setShared}
              roles={knowledgeContainer.roles}
              writeOnly={knowledgeContainer.sharedType === ShareType.Public}
            />
          )}
          {isCustomRagAllowed && (
            <FlagFieldEditor
              field={{
                key: 'ragStrategy',
                name: 'Custom RAG',
                info: 'Enable custom RAG for this container',
                value: !!ragStrategy,
              }}
              onChange={(field: FlagField) => {
                field.value
                  ? setRagStrategy(
                      knowledgeContainer.ragStrategy || emptyRagStrategy(),
                    )
                  : setRagStrategy(undefined)
              }}
            ></FlagFieldEditor>
          )}
          {!!ragStrategy && (
            <>
              <TextFieldEditor
                field={{
                  key: 'indexingUrl',
                  name: 'Indexing URL',
                  info: 'URL to the indexing service',
                  value: ragStrategy.indexingUrl,
                }}
                onChange={(field: TextField) =>
                  setRagStrategy({ ...ragStrategy, indexingUrl: field.value })
                }
              ></TextFieldEditor>
              <SelectFieldEditor
                field={{
                  key: 'retrievalToolId',
                  name: 'Retrieval Tool',
                  info: 'Select the retrieval tool for this container',
                  value: selectedRetrievalTool,
                  options: retrievalTools,
                }}
                onChange={(field: SelectField) =>
                  setSelectedRetrievalTool(field.value)
                }
              ></SelectFieldEditor>
            </>
          )}
          {message !== '' && (
            <div className="alert alert-warning mt-3">
              <ExclamationTriangleIcon className="w-8 h-8" />
              <span>{message}</span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant={'light'} onClick={closeFunction} isDisabled={isLoading}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          className="ml-3"
          color={'primary'}
          onClick={handleSubmit(saveContainer)}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

export default BlobAddEditContainer
