import { iAssistantEdit, iAssistantSettings } from './iAssistantTypes.tsx'
import React, { useEffect, useState } from 'react'
import AssistantSettingsService from '../../../services/sessionSettingsService.ts'
import { useMain } from '../../../stateManagement/contexts/mainContext.tsx'
import MembersSelection from '../../utils/memberSelection.tsx'
import { deepCopy } from '../../utils/deepCopy.ts'
import { ShareType } from '../../../interfaces/iItem.ts'
import './blobStyle.css'
import {
  iShared,
  iShareLink,
  SharableEntityType,
} from '../../../interfaces/iShared.ts'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Badge, Tab, Tabs, Tooltip } from '@nextui-org/react'
import useAssistantStore from '../../../stateManagement/assistantState.ts'
import AssistantModelSelection from '../../settings/system/assistantModelSelection.tsx'
import ToolSelection from '../../utils/toolSelection.tsx'
import KnowledgeContainerSelection from '../../utils/knowledgeContainerSelection.tsx'
import { shallow } from 'zustand/shallow'
import InvitationLinkSettings from '../../invitation/invitationLinkSettings.tsx'

const tooltipShareWith =
  'Limited: Only selected members can access this presetting. Public: Everyone can access this presetting. People enlisted can also modify this presetting. '

export default function AddEditpreSettings({
  currentPreSetting,
  closeFunction,
}: iAssistantEdit) {
  const { setError } = useMain()

  const { loadAssistants, createAssistant } = useAssistantStore(
    (state) => ({
      loadAssistants: state.loadAssistants,
      createAssistant: state.createAssistant,
    }),
    shallow,
  )

  const [preSetting, setPreSetting] = useState<iAssistantSettings>(currentPreSetting)
  const [maxOutputTokens, setMaxOutputTokens] = useState<number>()
  const [message, setMessage] = useState<string>('')
  const [shared, setShared] = useState<iShared[]>(currentPreSetting?.shared || [])
  const [shareLinks, setShareLinks] = useState<iShareLink[]>(
    currentPreSetting?.shareLinks || [],
  )

  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])

  useEffect(() => {
    switch (currentPreSetting.sharedType) {
      case ShareType.Limited:
        setSharedLimited(shared)
        break
      case ShareType.Public:
        setSharedPublic(shared)
        break
    }
  }, [])

  useEffect(() => {
    plausabilityCheck(preSetting?.title)
  }, [preSetting])

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = +event.target.value as unknown as ShareType
    setPreSetting({ ...preSetting, sharedType: newValue })
    switch (newValue) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }

  const getTitle = () => {
    if (preSetting.blueprint!) {
      return 'Edit Assistant'
    } else {
      return 'Add Assistant'
    }
  }

  const plausabilityCheck = (title: string) => {
    if (!title) {
      return true
    }
    // //This name may only contain lowercase letters, numbers, and hyphens, and must begin with a letter or a number. Each hyphen must be preceded and followed by a non-hyphen character. The name must also be between 3 and 63 characters long.
    if (title.length < 3 || title.length > 63) {
      setMessage('Name must be between 3 and 63 characters long')
      return true
    }
    // Name may only contain lowercase letters, numbers, and hyphens, and empty spaces, and must begin with a letter or a number.
    if (!/^[a-zA-Z0-9][a-zA-Z0-9- ]*$/.test(title)) {
      setMessage(
        'Name may only contain lowercase letters, numbers, and hyphens, and empty spaces, and must begin with a letter or a number.',
      )
      return true
    }
    setMessage('')
    return false
  }

  const savePreSetting = () => {
    const preSettingCopy = deepCopy(preSetting)
    if (preSetting.title === '') {
      setMessage('Please enter a name')
      return
    }
    if (preSetting.description === '') {
      setMessage('Please enter a description')
      return
    }

    if (plausabilityCheck(preSetting.title)) {
      return
    }

    if (!preSetting.blueprint) preSettingCopy.id = undefined
    preSettingCopy.blueprint = true
    preSettingCopy.shared = shared
    preSettingCopy.shareLinks = shareLinks

    // reset error/warning message
    setMessage('')

    // new container
    // check the preSetting object from the page, not the new from the saving process
    if (!preSetting.blueprint!) {
      createAssistant(preSettingCopy)
        .then(() => {
          closeFunction()
        })
        .catch((error) => {
          console.error(error)
          setError(error)
        })
        .then(() => {
          loadAssistants()
        })
    } else {
      AssistantSettingsService.updateAssistantSettings(preSettingCopy) // TODO
        .then(() => {
          closeFunction()
        })
        .catch((error) => {
          console.error(error)
          setError(error)
        })
        .then(() => {
          loadAssistants()
        })
    }
  }

  return (
    <div className={'max-w-fit w-50 min-w-[28rem]'}>
      <div>
        <h1 className={'text-xl font-semibold mb-2'}>{getTitle()}</h1>
      </div>

      <div className={'PreSettingModalContentHeight overflow-x-auto'}>
        <div className={'w-full mb-3 mt-3'}>
          <label className="label ">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            placeholder="Name"
            className="input input-bordered w-full "
            value={preSetting.title}
            onChange={(e) => setPreSetting({ ...preSetting, title: e.target.value })}
          />
        </div>
        <div className={'w-full mb-2'}>
          <label className="label ">
            <span className="label-text">Description</span>
          </label>
          <textarea
            placeholder="Description"
            className="input input-bordered w-full"
            value={preSetting.description ?? ''}
            onChange={(e) =>
              setPreSetting({ ...preSetting, description: e.target.value })
            }
            rows={3}
          />
        </div>
        <div className={'w-full '}>
          <label className="label cursor-pointer">
            <span className="label-text flex">
              Share with
              <Tooltip
                content={tooltipShareWith}
                color={'primary'}
                className={'max-w-96'}
              >
                <InformationCircleIcon
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.0}
                  stroke="currentColor"
                  className="w-4 h-4 ml-1"
                />
              </Tooltip>
            </span>
            <select
              className="select select-bordered w-1/2 max-w-xs"
              value={preSetting.sharedType}
              onChange={onSelectChange}
            >
              <option value={ShareType.None}>None</option>
              <option value={ShareType.Public}>Public</option>
              <option value={ShareType.Limited}>Limited</option>
            </select>
          </label>
        </div>
        {preSetting.sharedType !== ShareType.None && (
          <>
            <label className="label ">
              <span className="label-text">Members</span>
            </label>
            <Tabs aria-label="members">
              <Tab key="members" title="Current Members">
                <MembersSelection
                  shared={shared}
                  ownerId={preSetting.ownerId}
                  setShared={setShared}
                  roles={preSetting.roles}
                  writeOnly={preSetting.sharedType === ShareType.Public}
                />
              </Tab>
              <Tab
                key="links"
                title={
                  <Badge
                    isInvisible={!shareLinks.length}
                    isOneChar
                    content={shareLinks.length}
                    color="primary"
                    shape="rectangle"
                    classNames={{
                      badge: '-right-2',
                    }}
                  >
                    Invitation Links
                  </Badge>
                }
              >
                <InvitationLinkSettings
                  links={shareLinks}
                  resourceType={SharableEntityType.Assistant}
                  resourceId={preSetting!.id!}
                  roles={preSetting.roles}
                  writeOnly={false}
                  onChange={(links) => setShareLinks(links)}
                ></InvitationLinkSettings>
              </Tab>
              <Tab key="requests" title="Requests" isDisabled={true}></Tab>
            </Tabs>
          </>
        )}

        <div className={'grid grid-col-2'}>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Model</span>
            </label>
            <AssistantModelSelection
              disableHint
              modelId={preSetting.settings!.modelId!}
              onChange={(e) => {
                setMaxOutputTokens(e.maxOutputTokens)
                preSetting!.settings!.maxTokens =
                  preSetting.settings!.maxTokens! > e.maxOutputTokens
                    ? e.maxOutputTokens
                    : preSetting.settings?.maxTokens
                setPreSetting({
                  ...preSetting,
                  settings: {
                    ...preSetting.settings,
                    modelId: e.modelId,
                  },
                })
              }}
            ></AssistantModelSelection>
          </div>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Instructions</span>
            </label>
            <textarea
              className="textarea textarea-primary textarea-sm w-full"
              id="systemPrompt"
              placeholder="System Prompt"
              value={preSetting.instruction}
              onChange={(e) =>
                setPreSetting({ ...preSetting, instruction: e.target.value })
              }
            />
          </div>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Max-Output-Tokens</span>
            </label>
            <input
              type="range"
              id="range-mt"
              min={1}
              max={maxOutputTokens}
              step={16}
              inputMode="numeric"
              value={preSetting.settings?.maxTokens}
              className="range  range-xs"
              onChange={(e) =>
                setPreSetting({
                  ...preSetting,
                  settings: { ...preSetting.settings, maxTokens: +e.target.value },
                })
              }
            />
            <div className={'text-center'}>{preSetting.settings?.maxTokens}</div>
          </div>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Temperature</span>
            </label>
            <input
              type="range"
              id="range-temp"
              min={0}
              max={2}
              step={0.01}
              inputMode="numeric"
              value={preSetting.settings?.temperature}
              className="range  range-xs"
              onChange={(e) =>
                setPreSetting({
                  ...preSetting,
                  settings: { ...preSetting.settings, temperature: +e.target.value },
                })
              }
            />
            <div className={'text-center'}>{preSetting.settings?.temperature}</div>
          </div>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Top-P</span>
            </label>
            <input
              type="range"
              id="range-top-p"
              min={0}
              max={1}
              step={0.01}
              inputMode="numeric"
              value={preSetting.settings?.topP}
              className="range  range-xs"
              onChange={(e) =>
                setPreSetting({
                  ...preSetting,
                  settings: { ...preSetting.settings, topP: +e.target.value },
                })
              }
            />
            <div className={'text-center'}>{preSetting.settings?.topP}</div>
          </div>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Transferred</span>
            </label>
            <input
              type="range"
              id="range-fp"
              min={0}
              max={21}
              step={1}
              inputMode="numeric"
              value={preSetting.settings?.transferLength}
              className="range  range-xs"
              onChange={(e) =>
                setPreSetting({
                  ...preSetting,
                  settings: {
                    ...preSetting.settings,
                    transferLength: +e.target.value,
                  },
                })
              }
            />
            <div className={'text-center'}>
              {preSetting.settings?.transferLength === 21
                ? 'all'
                : preSetting.settings?.transferLength}
            </div>
          </div>
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Active Tools</span>
            </label>
            <ToolSelection
              selectedItemId={preSetting.toolIds ?? []}
              setSelectedItemId={(ids) => {
                setPreSetting({ ...preSetting, toolIds: ids })
              }}
            ></ToolSelection>
          </div>

          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Active Knowledge Container</span>
            </label>
            <KnowledgeContainerSelection
              selectedItemId={preSetting.knowledgeContainerIds ?? []}
              setSelectedItemId={(ids) => {
                setPreSetting({ ...preSetting, knowledgeContainerIds: ids })
              }}
            ></KnowledgeContainerSelection>
          </div>
        </div>
      </div>

      <div className={'text-right mt-2'}>
        <button className="btn" onClick={closeFunction}>
          Cancel
        </button>
        <button className="btn btn-primary ml-3" onClick={savePreSetting}>
          Submit
        </button>
      </div>
      {message !== '' && (
        <div className="alert alert-warning mt-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>{message}</span>
        </div>
      )}
    </div>
  )
}
