import { iItem } from '../../interfaces/iItem'
import { iFileData } from '../sessionControls/files/fileSetting'
import { iFeedback } from './feedback/iFeedback.ts'
import { iSourceFileCitation } from './sourceFiles/iSourceFileCitations.ts'

export interface iMessage extends iItem {
  role: string
  error?: iGenerationError
  content?: string
  contentItems?: iContentItem[]
  contentType?: string
  contentObject?: string | undefined
  ownerId?: string
  hidden?: boolean
  autoHide?: boolean
  messageTokenCount?: number
  promptTokenCount?: number
  completionTokenCount?: number
  totalTokenCount?: number
  citations?: iSourceFileCitation
  sessionId: string
  toolCalls?: iToolCall[]
  isAudio?: boolean
  files?: File[]
  filesData?: iFileData[]
  feedback?: iFeedback
  generationTime?: iGenerationTime
  isLoading?: boolean
  tmpId?: string
}

export interface iToolCall {
  toolCallId: string
  toolCallName: string
  toolCallDisplayName: string
  toolCallArguments: string
  toolCallContent: string
  toolCallHttpContext: iToolCallHttpContext
}

export interface iContentItem {
  index: number
  content: string
  type: ContentItemType
  mimeType?: string
}

export enum ContentItemType {
  Text,
  Image,
}

export interface iFunctionCallsResponse {
  function_call_name: string
  function_call_arguments: string
  function_call_response: string
}

export interface iGenerationTime {
  backend: number
  end2end: number
}

export interface iToolCallHttpContext {
  requestCompileError: string
  requestError: string
  responseError: string
  request: iHttpToolRequest
  response: iHttpToolResponse
}

export interface iHttpToolRequest {
  url: string
  method: string
  body: string
  headers: { [key: string]: string }
}

export interface iHttpToolResponse {
  statusCode: number
  body: string
  headers: { [key: string]: string }
}

export interface iGenerationError {
  message: string
  code: string
}
