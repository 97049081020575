export interface iSourceFileCitation {
  files: iFile[]
}

export interface iFile {
  accessUrl: string
  container: string
  documentName: string
  expireDate: string
  viewerType: ViewerType
  mimeType: string
  citations: iCitation[]
}

export interface iCitation {
  citation: string
  page: number
  citationId: number
}

export interface iKnowledgeReference {
  id: number
  documentAccess: iDocumentAccess
  container: string
  page: number
  content: string
}

export interface iDocumentAccess {
  accessUrl: string
  documentName: string
  mimeType: string
  viewerType: ViewerType
}

export enum ViewerType {
  GaiaViewer,
  HttpNewTab,
}
