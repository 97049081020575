import React, { useCallback, useEffect, useState } from 'react'
import {
  MagnifyingGlassIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import iSettingsProps from '../../settings/iSettingsProps.ts'
import UserManagementUsage from './userManagementUsage.tsx'
import UserManagementCreateEdit from './userManagementCreateEdit.tsx'
import { iTeam } from './iTeam.tsx'
import TeamService from '../../../services/teamService.ts'
import { UserManagementState } from './iUserManagement.tsx'
import { getUserKey, getUserRole } from '../../login/authentication.tsx'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from '@nextui-org/react'
import useTeamStore from '../../../stateManagement/teamState.ts'
import { iShared, Role } from '../../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'

function UserManagementList({ closeFunction }: iSettingsProps) {
  const { teams, getTeams, deleteTeam } = useTeamStore(
    (state) => ({
      teams: state.teams,
      getTeams: state.getTeams,
      deleteTeam: state.deleteTeam,
    }),
    shallow,
  )
  const [userTeamLoading, setTeamLoading] = useState<boolean>(false)
  const [localTeams, setLocalTeams] = useState<iTeam[]>([])
  const [userManagementState, setUserManagementState] =
    useState<UserManagementState>(UserManagementState.List)
  const [selectedTeam, setSelectedTeam] = useState<iTeam | undefined>(undefined)

  useEffect(() => {
    setLocalTeams(teams)
    setTeamLoading(false)
  }, [teams])

  const handleCancelButtonClick = () => {
    if (userManagementState === UserManagementState.List) {
      closeFunction()
      return
    }
    setUserManagementState(UserManagementState.List)
  }

  const handleOnSaveButtonClick = async () => {
    await getTeams()
    setUserManagementState(UserManagementState.List)
  }

  const deleteTeamByName = async (team: iTeam) => {
    if (!confirm('Are you sure you want to delete this team?')) {
      return
    }
    await deleteTeam(team.id!)
  }

  const addTeamFunction = () => {
    setUserManagementState(UserManagementState.Create)
  }

  const setLoading = () => {
    setTeamLoading(true)
  }
  const defaultTeam: iTeam = {
    name: '',
    description: '',
    costCenter: '',
    spent: 0,
    budget: 0,
    shareLinks: [],
    shared: [],
    resourceRestrictions: {
      assistantIds: null,
      knowledgeContainerIds: null,
      modelIds: null,
      toolIds: null,
    },
    ownerId: getUserKey(),
    roles: [
      { type: Role.Manager, permissions: [] },
      { type: Role.Member, permissions: [] },
      { type: Role.LimitedMember, permissions: [] },
    ],
  }

  const allowEdit = useCallback((team: iTeam) => {
    const userId = getUserKey()
    const user = team.shared.find((user: iShared) => user.id === userId)
    return user?.role === Role.Manager || team.ownerId === userId
  }, [])

  const allowDelete = useCallback((team: iTeam) => {
    const userId = getUserKey()
    return team.ownerId === userId
  }, [])
  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={handleCancelButtonClick}
      size={undefined}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <>
          {userManagementState === UserManagementState.List && (
            <>
              <ModalHeader>
                <div className={'flex flex-col'}>
                  <h1 className={'text-xl font-semibold mb-2'}>Teams</h1>
                  <p className={''}>Manage your teams and their budgets.</p>
                  {getUserRole() === 'admin' && (
                    <div className={'text-red-500'}>
                      You are logged in as admin.
                      <button
                        className="btn btn-error btn-sm m-3 "
                        onClick={() => {
                          setUserManagementState(UserManagementState.Usage)
                          const adminAll = {
                            name: 'adminAll',
                            id: 'supergeheimeradmin',
                          } as iTeam
                          setSelectedTeam(adminAll)
                        }}
                      >
                        Show All Tokens
                      </button>
                      <button
                        className="btn btn-error btn-sm m-3 "
                        onClick={() => {
                          TeamService.getTeams(true, true).then((response) => {
                            setLocalTeams(response)
                            setTeamLoading(false)
                          })
                        }}
                      >
                        Show All Teams
                      </button>
                    </div>
                  )}
                </div>
              </ModalHeader>
              <ModalBody>
                <>
                  {/* <div className={"w-full "}>
                    <input type="text" placeholder="Search for Team" className="input input-bordered w-full " />
                </div> */}
                  {userTeamLoading && (
                    <div className={'w-full grid'}>
                      <span className="loading loading-spinner loading-lg place-self-center"></span>
                    </div>
                  )}
                  {!userTeamLoading && localTeams.length > 0 && (
                    <div className="overflow-x-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Name</td>
                            <td>Cost Center</td>
                            <td>Monthly Bugdet</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {localTeams.map((team, key) => {
                            return (
                              <tr key={key}>
                                <td>{team.name}</td>
                                <td>{team.costCenter}</td>
                                <td>
                                  <Tooltip
                                    content={team.spent + ' / ' + team.budget + ' €'}
                                    color={'primary'}
                                    className={'max-w-96'}
                                  >
                                    <progress
                                      className="progress progress-primary w-60"
                                      value={team.spent}
                                      max={team.budget}
                                    />
                                  </Tooltip>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-ghost btn-circle"
                                    onClick={() => {
                                      setUserManagementState(
                                        UserManagementState.Usage,
                                      )
                                      setSelectedTeam(team)
                                    }}
                                  >
                                    <MagnifyingGlassIcon className="w-5 h-5" />
                                  </button>
                                  {allowEdit(team) && (
                                    <button
                                      className="btn btn-ghost btn-circle"
                                      onClick={() => {
                                        setUserManagementState(
                                          UserManagementState.Edit,
                                        )
                                        setSelectedTeam(team)
                                      }}
                                    >
                                      <PencilIcon className="w-5 h-5" />
                                    </button>
                                  )}
                                  {allowDelete(team) && (
                                    <button
                                      className="btn btn-ghost btn-circle"
                                      onClick={() => {
                                        deleteTeamByName(team)
                                      }}
                                    >
                                      <TrashIcon className="w-5 h-5" />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {
                    // only admins and billingGroupManagers can add new teams
                    (getUserRole() === 'admin' ||
                      getUserRole() === 'billingGroupManager') && (
                      <div className="w-full  grid place-items-center">
                        <Button
                          className={'m-1'}
                          fullWidth
                          isIconOnly
                          variant={'light'}
                          color={'primary'}
                          onClick={addTeamFunction}
                        >
                          <PlusCircleIcon className="w-8 h-8" />
                        </Button>
                      </div>
                    )
                  }
                </>
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleCancelButtonClick}>Close</Button>
              </ModalFooter>
            </>
          )}
          {userManagementState === UserManagementState.Usage && (
            <>
              <UserManagementUsage
                closeFunction={handleCancelButtonClick}
                submitFunction={handleOnSaveButtonClick}
                setLoading={setLoading}
                team={selectedTeam}
              />
            </>
          )}
          {userManagementState === UserManagementState.Edit && (
            <>
              <UserManagementCreateEdit
                submitFunction={handleOnSaveButtonClick}
                closeFunction={handleCancelButtonClick}
                setLoading={setLoading}
                mode={UserManagementState.Edit}
                team={selectedTeam}
              />
            </>
          )}
          {userManagementState === UserManagementState.Create && (
            <>
              <UserManagementCreateEdit
                submitFunction={handleOnSaveButtonClick}
                closeFunction={handleCancelButtonClick}
                setLoading={setLoading}
                mode={UserManagementState.Create}
                team={defaultTeam}
              />
            </>
          )}
        </>
      </ModalContent>
    </Modal>
  )
}

export default UserManagementList
