export function getMarkdownId(markdown: string): string | null {
  const regex =
    /\[G-ID\]: (\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\b)/i

  const match = markdown.match(regex)
  // Find all dates in the changelog
  if (!match) {
    throw new Error('No [G-ID] found in markdown')
  }
  const id = match[1]
  return id
}

export function getMarkdownTag(
  gTag: 'G-ID' | 'G-Title' | 'G-Description' | 'G-Categories',
  markdown: string,
): string | string[] | null {
  const regex = new RegExp(
    String.raw`^\[\/\/\]: # '\[${gTag}\]: (.*?)'`,
    'm', // Enable multiline matching
  )
  const match = markdown.match(regex)
  if (!match) {
    throw new Error(`No [${gTag}] found in markdown`)
  }

  if (gTag === 'G-Categories') {
    return match[1].split(',').map((s) => s.trim())
  }
  return match[1]
}
