import { iKnowledgeContainer } from './iKnowledgeContainer.ts'

export interface iBlobContainerView {
  closeFunction: () => void
  addContainerFunction: () => void
  editContainerFunction: (container: iKnowledgeContainer) => void
  manageContainerFunction: (container: iKnowledgeContainer) => void
  variant: EBlobContainerViewVariant
}

export enum EBlobContainerViewVariant {
  Action,
  Selection,
}

export interface iBlobContainerEdit {
  currentContainer: iKnowledgeContainer
  closeFunction: () => void
}

export enum BlobViewTab {
  Containers,
  Edit,
  ManageFiles,
  Selection,
}

export interface iBlobContainer extends iKnowledgeContainer {
  selected: boolean
}

export interface iBlobManageFiles {
  containerid: string
  closeFunction: () => void
  submitFunction?: () => void
}

export interface iBlobList {
  node: string
  type: ContainerType
  lastModified: string | null
  size: number
  children: iBlobList[]

  showChildren: boolean
  file: File | null
  newStatus: newStatus
}

export enum ContainerType {
  Folder,
  File,
}

export interface iBlobDictionary {
  [key: string]: iBlobList
}

export enum newStatus {
  None,
  Existing,
  New,
  Deleted,
  Updated,
}

export enum savingStatus {
  None,
  Uploading,
  Analyzing,
  Indexing,
  Deleting,
  Completed,
  Failed,
}

export interface iProgressUpdate {
  status: EprogressUpdateStatus
  progress: number
  filename: string
}

export enum EprogressUpdateStatus {
  uploading = 'uploading',
  analyzing = 'analyzing',
  indexing = 'indexing',
  error = 'error',
  completed = 'completed',
}

export enum BlobUploadEvents {
  uploading = 'knowledgeContainerUploading',
  analyzing = 'knowledgeContainerAnalyzing',
  indexing = 'knowledgeContainerIndexing',
}
