import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MainContext } from '../contexts/mainContext'
import { Popup } from '../../components/sessionControls/contextPopup'
import useAssistantModelStore from '../assistantModelState.ts'
import useAssistantStore from '../assistantState.ts'
import useKnowledgeContainerStore from '../knowledgeContainerState.ts'
import useToolStore from '../toolState.ts'
import useUserProfileStore from '../userProfileState.ts'
import useSessionStore from '../sessionState.ts'
import useTeamStore from '../teamState.ts'
import useFolderStore from '../folderState.ts'
import useSignalRStore from '../signalRState.ts'
import { iTeam } from '../../components/sessionControls/userManagement/iTeam.tsx'
import { iUser } from '../../components/login/iUser.ts'
import { shallow } from 'zustand/shallow'

type MainProviderProps = React.HTMLAttributes<HTMLDivElement>

function MainProvider({ children }: MainProviderProps) {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [popup, setPopupType] = useState(Popup.None)
  const [popupArgs, setPopupArgs] = useState<any | null>(null)
  const [showSettings, setShowSettings] = useState<boolean>(false)
  const [feedback, setFeedback] = useState('')
  const [feedbackType, setFeedbackType] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [rerunOnboarding, setRerunOnboarding] = useState<boolean>(false)

  const { loadUserProfile } = useUserProfileStore(
    (state) => ({
      loadUserProfile: state.loadUserProfile,
    }),
    shallow,
  )
  const { loadAssistantModels, setAssistantModels } = useAssistantModelStore(
    (state) => ({
      loadAssistantModels: state.loadAssistantModels,
      setAssistantModels: state.setAssistantModels,
    }),
    shallow,
  )
  const { loadTools } = useToolStore(
    (state) => ({
      loadTools: state.loadTools,
    }),
    shallow,
  )
  const { loadAssistants } = useAssistantStore(
    (state) => ({
      loadAssistants: state.loadAssistants,
    }),
    shallow,
  )
  const { loadKnowledgeContainer } = useKnowledgeContainerStore(
    (state) => ({
      loadKnowledgeContainer: state.loadKnowledgeContainer,
    }),
    shallow,
  )

  const { getSessions } = useSessionStore(
    (state) => ({
      getSessions: state.getSessions,
    }),
    shallow,
  )

  const { getTeams, setSelectedTeam } = useTeamStore(
    (state) => ({
      getTeams: state.getTeams,
      setSelectedTeam: state.setSelectedTeam,
    }),
    shallow,
  )

  const { getFolders } = useFolderStore(
    (state) => ({
      getFolders: state.getFolders,
    }),
    shallow,
  )

  const { initSignalR } = useSignalRStore(
    (state) => ({
      initSignalR: state.initSignalR,
    }),
    shallow,
  )

  const setPopup = (popup: Popup, args?: any) => {
    setPopupArgs(args)
    setPopupType(popup)
  }

  // on initial load, get the assistants, tools and knowledge containers
  useEffect(() => {
    let team: iTeam | undefined
    let user: iUser | undefined
    loadUserProfile()
      .then((u) => {
        user = u
        // remove state and code params
        searchParams.delete('state')
        searchParams.delete('code')
        //restore search params after okta redirect
        setSearchParams(searchParams)
        return initSignalR(user.id!)
      })
      .then(() => loadAssistants())
      .then(() => loadTools())
      .then(() => loadKnowledgeContainer())
      .then(() => getTeams())
      .then((teams) => {
        const teamId = searchParams.get('teamId')
        const searchParamTeam = teams.find((team) => team.id === teamId)
        const usersLastTeam = teams.find((team) => team.id === user?.lastTeamId)

        if (searchParamTeam) team = searchParamTeam
        else if (usersLastTeam) team = usersLastTeam
        else if (teams) team = teams[0]

        if (team) {
          searchParams.set('teamId', team.id!)
          setSearchParams(searchParams)
          setSelectedTeam(team)
        }
        return getFolders({ teamId: team?.id })
      })
      .then(() => loadAssistantModels(['chat', 'text']))
      .then(async (models) => {
        setAssistantModels(models)
      })
      .then(() =>
        getSessions({ teamId: team?.id, sessionId: searchParams.get('sessionId') }),
      )
  }, [])

  const value = useMemo(
    () => ({
      isLoading,
      setLoading,
      error,
      setError,
      feedback,
      setFeedback,
      feedbackType,
      setFeedbackType,
      setPopup,
      popup,
      popupArgs,
      showSettings,
      setShowSettings,
      rerunOnboarding,
      setRerunOnboarding,
    }),
    [
      isLoading,
      setLoading,
      error,
      setError,
      feedback,
      setFeedback,
      feedbackType,
      setFeedbackType,
      setPopup,
      popup,
      popupArgs,
      showSettings,
      setShowSettings,
      rerunOnboarding,
      setRerunOnboarding,
    ],
  )
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}

export default MainProvider
