// MyComponent.jsx
import React from 'react'
import FileSettings from './files/fileSetting'
import ToolSettings from './tools/toolSettings.tsx'
import KnowledgeContainerSetting from './knowledgeContainer/knowledgeContainerSetting.tsx'
import UserManagementList from './userManagement/userManagementList.tsx'
import Assistant from './assistants/assistantModal.tsx'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import Changelog from './changelog/changelog.tsx'
import PatManager from '../utils/patManager.tsx'
import OktaChangePopup from './OktaChangePopup.tsx'
import SessionFeedback from '../chatWindow/feedback/sessionFeedback.tsx'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { shallow } from 'zustand/shallow'
import SecretManager from '../utils/secretManager.tsx'

export enum Popup {
  None,
  Files,
  Wiki,
  ToolSelector,
  Tools,
  Workitems,
  Container,
  ContainerSelector,
  UserManagement,
  Roadmap,
  UserSettings,
  AssistantNew,
  AssistantContainer,
  AssistantEdit,
  AssistantInspect,
  AssistantOnlyInspect,
  PatKeys,
  Changelog,
  Okta,
  SessionFeedback,
  Secrets,
}

const ContextPopup = () => {
  const { activeSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const { setPopup, popup, popupArgs } = useMain()
  const showPopupContent = () => {
    switch (popup) {
      case Popup.Files:
        return <FileSettings closeFunction={() => setPopup(Popup.None)} />
      case Popup.ToolSelector:
        return (
          <ToolSettings
            closeFunction={() => setPopup(Popup.None)}
            selector={'Selection'}
          />
        )
      case Popup.Tools:
        return (
          <ToolSettings
            closeFunction={() => setPopup(Popup.None)}
            selector={'Action'}
          />
        )
      case Popup.Container:
        return (
          <KnowledgeContainerSetting
            closeFunction={() => setPopup(Popup.None)}
            selector={false}
          />
        )
      case Popup.ContainerSelector:
        return (
          <KnowledgeContainerSetting
            closeFunction={() => setPopup(Popup.None)}
            selector={true}
          />
        )
      case Popup.UserManagement:
        return <UserManagementList closeFunction={() => setPopup(Popup.None)} />
      case Popup.AssistantNew:
        return (
          <Assistant closeFunction={() => setPopup(Popup.None)} selector={'New'} />
        )
      case Popup.AssistantContainer:
        return (
          <Assistant
            closeFunction={() => setPopup(Popup.None)}
            selector={'Containers'}
          />
        )
      case Popup.AssistantEdit:
        return (
          <Assistant
            closeFunction={() => setPopup(Popup.None)}
            selector={'Edit'}
            currentPreSetting={popupArgs}
          />
        )
      case Popup.AssistantInspect:
        return (
          <Assistant
            closeFunction={() => setPopup(Popup.None)}
            selector={'Inspect'}
            currentPreSetting={popupArgs}
          />
        )
      case Popup.AssistantOnlyInspect:
        return (
          <Assistant
            closeFunction={() => setPopup(Popup.None)}
            selector={'OnlyInspect'}
            currentPreSetting={popupArgs}
          />
        )
      case Popup.Secrets:
        return <SecretManager onClose={() => setPopup(Popup.None)} />
      case Popup.PatKeys:
        return <PatManager onClose={() => setPopup(Popup.None)} />
      case Popup.Changelog:
        return <Changelog onClose={() => setPopup(Popup.None)} />
      case Popup.Okta:
        return <OktaChangePopup closeFunction={() => setPopup(Popup.None)} />
      case Popup.SessionFeedback:
        return (
          <SessionFeedback
            sessionId={activeSession!.id!}
            onClose={() => {
              setPopup(Popup.None)
              if (popupArgs?.onClose) popupArgs?.onClose()
            }}
            onClear={() => {
              setPopup(Popup.None)
              popupArgs?.onClear()
            }}
          />
        )
      case Popup.None:
        return <></>
      default:
        return <></>
    }
  }
  return <>{showPopupContent()}</>
}

export default ContextPopup
