import React, { useState, useEffect } from 'react'
import SecretService from '../../services/secretService.ts'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { iSecret } from './iSecret.ts'
import ToolService from '../../services/toolService.ts'
import { iTool } from '../sessionControls/tools/iTool.ts'
import { BiSave } from 'react-icons/bi'

interface iSecretManagerProps {
  onClose: () => void
}

function SecretManager({ onClose }: iSecretManagerProps) {
  const [secrets, setSecrets] = useState<iSecret[]>([])
  const [tools, setTools] = useState<iTool[]>([])
  const [secretLoading, setSecretLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [newSecret, setNewSecret] = useState<iSecret | null>(null)

  useEffect(() => {
    setSecretLoading(true)
    SecretService.getSecrets()
      .then((fetchedSecrets) => {
        setSecrets(fetchedSecrets)
        setSecretLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching secrets:', error)
        setAlert(true)
        setAlertMessage('Failed to fetch secrets')
        setSecretLoading(false)
      })
  }, [])

  useEffect(() => {
    ToolService.getTools().then((tools) => {
      setTools(tools.filter((tool) => tool.overridableParameters))
    })
  }, [])

  const handleAddSecret = () => {
    setAlert(false)
    setAlertMessage('')
    setNewSecret({
      id: '',
      name: '',
      secretValue: '',
      displayValue: '',
      secretId: '',
      expiryDate: new Date(),
      secretGroups: [],
    })
  }

  const handleSaveNewSecret = () => {
    if (!newSecret) return
    setSecretLoading(true)
    SecretService.createSecret(newSecret)
      .then((createdSecret) => {
        setSecrets([...secrets, createdSecret])
        SecretService.getSecrets().then((fetchedSecrets) => {
          setSecrets(fetchedSecrets)
        })
        setNewSecret(null)
        setSecretLoading(false)
      })
      .catch((error) => {
        console.error('Error creating secret:', error)
        setAlert(true)
        setAlertMessage('Failed to create secret')
        setSecretLoading(false)
      })
  }

  const handleDeleteSecret = (secretId: string) => {
    if (!window.confirm('Are you sure you want to delete this secret?')) return

    setSecretLoading(true)
    SecretService.deleteSecret(secretId)
      .then(() => {
        setSecrets(secrets.filter((secret) => secret.id !== secretId))
        setSecretLoading(false)
      })
      .catch((error) => {
        console.error('Error deleting secret:', error)
        setAlert(true)
        setAlertMessage('Failed to delete secret')
        setSecretLoading(false)
      })
  }
  const handleMapSecret = (
    secretId: string,
    groupName: string,
    toolId: string,
    parameterName: string,
  ) => {
    const secret = secrets.find((s) => s.id === secretId)
    if (!secret) return

    const secretGroup = {
      groupName,
      toolId,
      parameterName,
    }

    const updatedSecret = { ...secret, secretGroups: secret.secretGroups || [] }

    // Remove the secretGroup from any other secret that has it and update it via SecretService
    const otherSecrets = secrets.map((s) => {
      if (s.id !== secretId) {
        const updatedGroups = s.secretGroups.filter(
          (group) =>
            !(
              group.groupName === groupName &&
              group.toolId === toolId &&
              group.parameterName === parameterName
            ),
        )
        if (updatedGroups.length !== s.secretGroups.length) {
          const updatedOtherSecret = { ...s, secretGroups: updatedGroups }

          const oldSecret = updatedOtherSecret.secretValue
          SecretService.updateSecret(updatedOtherSecret).catch((error) => {
            console.error('Error updating other secret:', error)
          })
          updatedOtherSecret.secretValue = oldSecret
          return updatedOtherSecret
        }
      }
      return s
    })

    updatedSecret.secretGroups = updatedSecret.secretGroups.filter(
      (group) =>
        !(
          group.groupName === groupName &&
          group.toolId === toolId &&
          group.parameterName === parameterName
        ),
    )

    updatedSecret.secretGroups.push(secretGroup)

    // Temporarily remove secretValue if it contains '***'
    const originalSecretValue = updatedSecret.secretValue
    SecretService.updateSecret(updatedSecret)
      .then(() => {
        // Restore the original secretValue for the frontend
        updatedSecret.secretValue = originalSecretValue

        setSecrets(
          otherSecrets.map((s) => (s.id === updatedSecret.id ? updatedSecret : s)),
        )
      })
      .catch((error) => {
        console.error('Error mapping secret:', error)
        setAlert(true)
        setAlertMessage('Failed to map secret')
      })
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: '!max-w-[60vw]',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center w-full justify-between">
            <h3 className="text-xl font-semibold">Secret Manager</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          {alert && (
            <div className="alert alert-warning mb-1">
              <span>{alertMessage}</span>
            </div>
          )}
          {secretLoading && (
            <div className={'w-full grid mt-8'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )}
          {!secretLoading && (
            <>
              <div className="flex items-center justify-between mb-4">
                <h4>Secrets</h4>
                <Button onClick={handleAddSecret}>Add Secret</Button>
              </div>

              <div>
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Secret Value</th>
                      <th>Expiry Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {secrets.map((secret) => (
                      <tr key={secret.id}>
                        <td>{secret.name}</td>
                        <td>
                          <input
                            readOnly
                            value={secret.displayValue}
                            className="input input-bordered input-xs"
                          />
                        </td>
                        <td>{new Date(secret.expiryDate).toLocaleString()}</td>
                        <td>
                          <button onClick={() => handleDeleteSecret(secret.id)}>
                            <TrashIcon className="w-5 h-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {newSecret && (
                      <tr>
                        <td>
                          <input
                            value={newSecret.name}
                            onChange={(e) =>
                              setNewSecret({ ...newSecret, name: e.target.value })
                            }
                            className="input input-bordered input-xs"
                          />
                        </td>
                        <td>
                          <input
                            value={newSecret.secretValue}
                            onChange={(e) =>
                              setNewSecret({
                                ...newSecret,
                                secretValue: e.target.value,
                              })
                            }
                            type="password"
                            className="input input-bordered input-xs"
                          />
                        </td>
                        <td>
                          <input
                            value={newSecret.expiryDate.toISOString().split('T')[0]}
                            onChange={(e) =>
                              setNewSecret({
                                ...newSecret,
                                expiryDate: new Date(e.target.value),
                              })
                            }
                            type="date"
                            className="input input-bordered input-xs"
                          />
                        </td>
                        <td>
                          <button onClick={handleSaveNewSecret}>
                            <BiSave className="w-6 h-6" />
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-8">
                <h4>Map Secrets to Tool Parameters</h4>
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Tool</th>
                      <th>Parameter</th>
                      <th>Secret</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tools.map((p) =>
                      Object.keys(p.overridableParameters || {}).map((param) => {
                        const selectedSecret = secrets.find((secret) =>
                          secret.secretGroups?.some(
                            (group) =>
                              group.groupName === 'default' &&
                              group.toolId === p.id &&
                              group.parameterName === param,
                          ),
                        )
                        return (
                          <tr key={`${p.id}-${param}`}>
                            <td>{p.name}</td>
                            <td>{param}</td>
                            <td>
                              <select
                                onChange={(e) =>
                                  handleMapSecret(
                                    e.target.value,
                                    'default',
                                    p.id!,
                                    param,
                                  )
                                }
                                value={selectedSecret ? selectedSecret.id : ''}
                              >
                                <option value={''}>Select Secret</option>
                                {secrets.map((secret) => (
                                  <option key={secret.id} value={secret.id}>
                                    {secret.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                        )
                      }),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color={'primary'} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SecretManager
