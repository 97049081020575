import { iMessage } from '../components/chatWindow/iMessage'
import axiosClient from '../utils/axiosClient'
import { iSession } from '../components/chatWindow/iSession'
import { iSourceFileCitation } from '../components/chatWindow/sourceFiles/iSourceFileCitations'

class SessionService {
  private static instance: SessionService

  private constructor() {}

  public static getInstance(): SessionService {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService()
    }

    return SessionService.instance
  }

  public async createSession(session: iSession): Promise<iSession> {
    const ret = (await axiosClient.post(`/session`, session)).data
    ret.modified = new Date(ret!.modified)
    ret.created = new Date(ret!.created)
    return ret as iSession
  }

  public async deleteSession(sessionId: string): Promise<void> {
    await axiosClient.delete(`/session/${sessionId}`)
  }

  public async getSession(sessionId: string): Promise<iSession> {
    const ret = (await axiosClient.get(`/session/${sessionId}`)).data
    ret.modified = new Date(ret!.modified)
    ret.created = new Date(ret!.created)
    return ret as iSession
  }

  public async getSessions(teamId?: string): Promise<iSession[]> {
    const ret = (
      await axiosClient.get(`/sessions${teamId ? '?teamId=' + teamId : ''}`)
    ).data
    ret.map((session: { created: Date; modified: Date }) => {
      session.modified = new Date(session!.modified)
      session.created = new Date(session!.created)
    })
    return ret as iSession[]
  }

  public async updateSession(session: iSession): Promise<void> {
    await axiosClient.put(`/session`, session)
  }

  public async getSourceFiles(
    sessionId: string,
    messageId: string,
  ): Promise<iSourceFileCitation> {
    const data = (
      await axiosClient.get(`/session/${sessionId}/message/${messageId}/sourcefiles`)
    ).data
    return data as iSourceFileCitation
  }

  public async deleteMessage(sessionId: string, messageId: string): Promise<void> {
    await axiosClient.delete(`/session/${sessionId}/message/${messageId}`)
  }

  public async deleteMessages(sessionId: string): Promise<void> {
    await axiosClient.delete(`/session/${sessionId}/messages`)
  }

  public async getMessages(sessionId: string): Promise<iMessage[]> {
    return (await axiosClient.get(`/session/${sessionId}/messages`))
      .data as iMessage[]
  }

  public async getMessage(sessionId: string, messageId: string): Promise<iMessage> {
    return (await axiosClient.get(`/session/${sessionId}/message/${messageId}`))
      .data as iMessage
  }

  public async createMessage(
    sessionId: string,
    message: iMessage,
  ): Promise<iMessage> {
    return (await axiosClient.post(`/session/${sessionId}/message`, message))
      .data as iMessage
  }

  public async updateMessage(sessionId: string, message: iMessage): Promise<void> {
    await axiosClient.put(`/session/${sessionId}/message`, message)
  }
}

export default SessionService.getInstance()
