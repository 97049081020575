import React from 'react'
import FeedbackWidget from './feedbackWidget'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'

interface iSessionFeedbackProps {
  sessionId: string
  onClose?: () => void
  onClear?: () => void
}

function SessionFeedback({ sessionId, onClose, onClear }: iSessionFeedbackProps) {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <ModalHeader>Clear this session</ModalHeader>
        <ModalBody>
          <p>Did you like this session?</p>
          <p className="py-8 text-center text-3xl">
            <FeedbackWidget
              ratingType="thumbs"
              commentingEnabled={false}
              entityType="session"
              entityId={sessionId}
              onFeedbackSaved={onClear}
              onBeforeFeedbackSaved={() => setIsLoading(true)}
            ></FeedbackWidget>
          </p>
          <span className="text-sm">
            (Session is automatically deleted as soon as a rating has been submitted)
          </span>
        </ModalBody>
        <ModalFooter>
          <Button variant="light" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            color={'primary'}
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onClear}
          >
            Clear
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SessionFeedback
