import { useEffect, useState } from 'react'
import {
  OverridableParameterSet,
  iOverridableParameter,
  iOverridableParameterItem,
} from './model'
import HttpOverridableEditor from './overridableEditor'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@nextui-org/react'

const tooltipOverridables = `
  These parameters can be overridden by user secrets. The values are used on tool execution.
  Parameter to secret mapping is configured in the user menu under "Secrets".
  Overridable parameters are mapped to HTTP constants in two ways:
  (1) A HTTP constant is replaced as a whole, when the parameter name is equal to a constant name.
  (2) Placeholders within HTTP constants in the form of {OVERRIDABLE_PARAMETER_NAME} are replaced by the overridable parameter value.
  NOTE: When not all overridable parameters without default value are set, the tool cannot be attached to a session.
`

interface iOverridableParameterSetEditorProps {
  overridableSet: OverridableParameterSet
  onChange: (overridableSet: OverridableParameterSet) => void
}

function OverridableParameterSetEditor({
  overridableSet,
  onChange,
}: iOverridableParameterSetEditorProps) {
  const [overridables, setOverridables] = useState<iOverridableParameterItem[]>(
    toOverridablesList(overridableSet),
  )
  const [isExpanded, setIsExpanded] = useState(false)

  function toOverridablesList(
    overridables: OverridableParameterSet,
  ): iOverridableParameterItem[] {
    return Object.keys(overridables).map((name) => ({
      name,
      overridableParameter: overridables[name],
    }))
  }

  function toOverridablesSet(
    overridables: iOverridableParameterItem[],
  ): OverridableParameterSet {
    const set: OverridableParameterSet = {}
    overridables.forEach((item) => {
      set[item.name] = item.overridableParameter
    })
    return set
  }

  const handleNameChange = (index: number, newName: string) => {
    setOverridables(
      overridables.map((item, i) => {
        if (i === index) {
          return { name: newName, overridableParameter: item.overridableParameter }
        } else {
          return item
        }
      }),
    )
  }

  const handleValueChange = (
    index: number,
    overridableParameter: iOverridableParameter,
  ) => {
    setOverridables(
      overridables.map((item, i) => {
        if (i === index) {
          return { name: item.name, overridableParameter }
        } else {
          return item
        }
      }),
    )
  }

  useEffect(() => {
    onChange(toOverridablesSet(overridables))
  }, [overridables])

  const addNewOverridable = () => {
    setOverridables([
      ...overridables,
      {
        name: '',
        overridableParameter: { defaultValue: '', hasDefaultValue: false },
      },
    ])
    setIsExpanded(true)
  }

  const removeOverridable = (index: number) =>
    setOverridables(overridables.filter((_, i) => i !== index))

  return (
    <div className="mb-4">
      <label className="label ">
        <span className="label-text flex  text-base">
          Overridable Parameters ({overridables.length})
          <Tooltip
            content={tooltipOverridables}
            color={'primary'}
            className={'max-w-96'}
          >
            <InformationCircleIcon
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.0}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
            />
          </Tooltip>
        </span>
      </label>
      <div className="ml-0">
        {overridables &&
          overridables.map((item, i) => (
            <HttpOverridableEditor
              index={i}
              item={item}
              onNameChange={handleNameChange}
              onValueChange={handleValueChange}
              onRemove={removeOverridable}
            />
          ))}
      </div>
      <div className="text-center">
        <PlusCircleIcon
          onClick={addNewOverridable}
          className="w-8 h-8 inline cursor-pointer"
        />
      </div>
    </div>
  )
}
export default OverridableParameterSetEditor
