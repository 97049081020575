import { useState } from 'react'
import { iTool } from './iTool.ts'
import { iSecret } from '../../utils/iSecret.ts'
import { KeyIcon } from '@heroicons/react/24/outline'

const checkSecrets = (tool: iTool, secrets: iSecret[]) => {
  const overridableParameters = tool.overridableParameters || {}
  if (Object.keys(overridableParameters).includes('LANG')) {
  }
  if (Object.keys(overridableParameters).length === 0) {
    return { result: true, mappedSecrets: [], missing: [] }
  }

  const mappedSecrets: string[] = []
  const missing: string[] = []

  Object.keys(overridableParameters).forEach((key) => {
    const secret = secrets.find((s) =>
      s.secretGroups.some((sg) => sg.toolId === tool.id && sg.parameterName === key),
    )
    if (key === 'LANG') {
    }
    if (overridableParameters[key].hasDefaultValue || secret) {
      mappedSecrets.push(key)
    } else {
      missing.push(key)
    }
  })

  return {
    result: missing.length === 0,
    mappedSecrets: mappedSecrets,
    missing: missing,
  }
}

interface iSecretsDisplay {
  secrets: iSecret[]
  tool: iTool
}

function SecretsDisplay({ tool, secrets }: iSecretsDisplay) {
  const [hover, setHover] = useState(false)

  return (
    <div className={'text-sm flex items-center'}>
      {(checkSecrets(tool, secrets).mappedSecrets.length > 0 ||
        checkSecrets(tool, secrets).missing.length > 0) && (
        <div
          className={`relative w-5 h-5 ${checkSecrets(tool, secrets).missing.length > 0 ? 'text-red-500' : ''}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <KeyIcon className={'w-5 h-5'} />
          {hover && (
            <div className="absolute left-6 top-0 bg-white border p-2 text-left shadow-lg z-10">
              {checkSecrets(tool, secrets).mappedSecrets.length > 0 && (
                <div className="text-sm text-primary">
                  {checkSecrets(tool, secrets).mappedSecrets.join(', ')}
                </div>
              )}
              {checkSecrets(tool, secrets).missing.length > 0 && (
                <div className="text-sm text-red-500">
                  Missing secrets: {checkSecrets(tool, secrets).missing.join(', ')}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export { SecretsDisplay, checkSecrets }
