import React from 'react'
import { Card, CardBody, Input, Tab, Tabs } from '@nextui-org/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

type ExploreGaiaPageProps = {
  theme: string
}

function ExploreGaiaPage({ theme }: ExploreGaiaPageProps) {
  const tabs = [
    {
      id: 'toppicks',
      label: 'Top Picks',
    },
    {
      id: 'research',
      label: 'Research and Analysis',
    },
    {
      id: 'programming',
      label: 'Programming',
    },
  ]
  return (
    <div className={'flex justify-center w-full'}>
      <div className={'w-11/12'}>
        <h1 className={'font-bold text-7xl text-center'}>Assistants</h1>
        <h6 className={'text-center'}>
          Discover and create custom Assistants that combine instructions, extra
          knowledge containers, plugins and any combination of skills.
        </h6>
        <Input
          label="Search"
          isClearable
          radius="lg"
          classNames={{
            label: 'text-black/50 dark:text-white/90',
            input: [
              'bg-transparent',
              'text-black/90 dark:text-white/90',
              'placeholder:text-default-700/50 dark:placeholder:text-white/60',
            ],
            innerWrapper: 'bg-transparent',
            inputWrapper: [
              'shadow-xl',
              'bg-default-200/50',
              'dark:bg-default/60',
              'backdrop-blur-xl',
              'backdrop-saturate-200',
              'hover:bg-default-200/70',
              'dark:hover:bg-default/70',
              'group-data-[focus=true]:bg-default-200/50',
              'dark:group-data-[focus=true]:bg-default/60',
              '!cursor-text',
            ],
          }}
          placeholder="Type to search..."
          startContent={<MagnifyingGlassIcon className="w-5 h-5" />}
        />

        <div className="flex w-full flex-col">
          <Tabs aria-label="Dynamic tabs" items={tabs}>
            {(item) => <Tab key={item.id} title={item.label}></Tab>}
          </Tabs>
        </div>
        <h1 className={'font-bold text-7xl text-center'}>By GAIA</h1>
        <h6 className={'text-center'}>Assistants created by the GAIA Team</h6>
      </div>
    </div>
  )
}

export default ExploreGaiaPage
