import { createWithEqualityFn } from 'zustand/traditional'
import * as signalR from '@microsoft/signalr'
import { SignalRStatus } from '../enums/signalRStatus.ts'
import AssistantService from '../services/assistantService.ts'
import { getToken } from '../components/login/authentication.tsx'
import SessionState from './sessionState.ts'

export interface iAssistantPayload {
  instanceId?: string
  sessionId?: string
  connectionId?: string
  messageId?: string
  userId?: string
  token?: string
}

interface iSignalRState {
  connection: signalR.HubConnection | null
  signalRstatus: SignalRStatus
  setConnection: (connection: signalR.HubConnection | null) => void
  setSignalRStatus: (status: SignalRStatus) => void
  joinSession: (sessionId: string, userId: string) => void
  leaveSession: (sessionId: string, userId: string) => void
  startAssistant: (sessionId: string, userId: string) => void
  initSignalR: (userId: string) => Promise<void>
  isConnected: () => boolean
}

const useSignalRStore = createWithEqualityFn<iSignalRState>((set, getState) => ({
  connection: null,
  signalRstatus: SignalRStatus.Disconnected,
  selectedTeam: null,
  setConnection: (connection: signalR.HubConnection | null) =>
    set(() => ({ connection })),
  setSignalRStatus: (signalRstatus: SignalRStatus) => set(() => ({ signalRstatus })),
  joinSession: (sessionId: string, userId: string) => {
    const connection = getState().connection
    if (connection) {
      const payload: iAssistantPayload = {
        sessionId: sessionId,
        userId: userId,
        connectionId: connection.connectionId!,
      }
      connection.send('join', payload)
    }
  },
  leaveSession: (sessionId: string, userId: string) => {
    const connection = getState().connection
    if (connection) {
      const payload: iAssistantPayload = {
        sessionId: sessionId,
        userId: userId,
        connectionId: connection.connectionId!,
      }

      connection.send('leave', payload)
    }
  },
  startAssistant: (sessionId: string, userId: string) => {
    const connection = getState().connection
    if (connection) {
      const payload: iAssistantPayload = {
        sessionId: sessionId,
        userId: userId,
        token: getToken(),
        connectionId: connection.connectionId!,
      }

      connection.send('StartAssistant', payload)
      // await AssistantService.startAssistant(sessionId, message);
    }
  },
  initSignalR: async (userId: string) => {
    if (getState().isConnected()) {
      return
    }

    getState().setSignalRStatus(SignalRStatus.Connecting)
    const connectToSignalR = async () => {
      const connectionInfo = await AssistantService.connectSignalR(userId)
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(connectionInfo.url, {
          accessTokenFactory: async () => {
            const connectInfo = await AssistantService.connectSignalR(userId)
            return connectInfo.accessToken
          },
        })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => {
            return 5000
          },
        })
        .build()
      try {
        await hubConnection.start()
        hubConnection.onreconnecting(() => {
          console.warn('Connection lost, reconnecting...')
          getState().setSignalRStatus(SignalRStatus.Reconnecting)
        })
        hubConnection.onclose((error) => {
          if (error) {
            // console.debug('Connection lost, error details: ', error)
            if (error.message) {
              // console.debug('Error message: ', error.message)
            }
            if (error.stack) {
              // console.debug('Error stack: ', error.stack)
            }
          } else {
            // console.debug('Connection closed without error')
          }
          console.error('Connection lost, reconnecting... ', error)
          getState().setSignalRStatus(SignalRStatus.Disconnected)
        })
        hubConnection.onreconnected(() => {
          getState().setSignalRStatus(SignalRStatus.Connected)
          const activeSession = SessionState.getState().activeSession
          if (activeSession) {
            getState().joinSession(activeSession.id!, userId)
          }
        })
        getState().setSignalRStatus(SignalRStatus.Connected)
        getState().setConnection(hubConnection)
      } catch (error) {
        console.error('Connection failed: ', error)
        getState().setSignalRStatus(SignalRStatus.Disconnected)
      }
    }
    connectToSignalR()
      .then()
      .catch((e) => {
        getState().setSignalRStatus(SignalRStatus.Disconnected)
        console.error(e)
      })
  },
  isConnected: () => {
    return getState().signalRstatus === SignalRStatus.Connected
  },
}))

export default useSignalRStore
