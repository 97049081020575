import React from 'react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { Button } from '@nextui-org/react'

const EmptySessionFolder = () => {
  return (
    <div className={'flex flex-col max-w-full  w-full mx-auto px-2 sm:px-4'}>
      <div className={'grid place-content-center h-full place-items-stretch'}>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Welcome to GAIA!</h2>
            <p>You have no sessions yet.</p>
            <p>Click on your folder on the left to expand it.</p>
            <p className={'flex items-center'}>
              Then click on
              <Button
                className={'m-1'}
                isIconOnly
                variant={'light'}
                color={'primary'}
              >
                <PlusCircleIcon className="w-8 h-8" />
              </Button>
              on the left to create your first session.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptySessionFolder
