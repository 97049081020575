import React, { useEffect, useMemo, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import './systemSettings.css'
import { PencilSquareIcon, SwatchIcon } from '@heroicons/react/24/outline'
import { Popup } from '../../sessionControls/contextPopup.tsx'
import { useMain } from '../../../stateManagement/contexts/mainContext.tsx'
import 'tippy.js/dist/tippy.css'
import Tippy from '@tippyjs/react'
import AssistantModelSelection from './assistantModelSelection.tsx'
import useTeamStore from '../../../stateManagement/teamState.ts'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { Role } from '../../../interfaces/iShared.ts'
import { Button } from '@nextui-org/react'
import AssistantSettingsService from '../../../services/sessionSettingsService.ts'
import useAssistantStore from '../../../stateManagement/assistantState.ts'
import { iAssistantSettings } from '../../sessionControls/assistants/iAssistantTypes.tsx'
import { shallow } from 'zustand/shallow'
import { iSession } from '../../chatWindow/iSession.ts'
import { isDefaultTeam } from '../../sessionControls/userManagement/iTeam.tsx'

const toolTipModel =
  'Select the model that you would like to use for generating text.'
const toolTipMaxTokens =
  'This parameter determines the maximum number of tokens that the model will generate in a single response. A token can be as short as one character or as long as one word. For example, "ChatGPT is great!" is five tokens. If the max tokens are set to 32, the model will stop generating more text after 32 tokens, regardless of whether it has finished the thought.'
const toolTipTemperature =
  'Lower temperatures make the model more confident, but also more conservative. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperatures cause the model to take more chances and increase diversity of results, but at a cost of more mistakes.'
const toolTipTopP =
  'Also known as nucleus sampling, this parameter filters out the least likely options for the next token. If top-p is set to 0.9, for example, the model will only consider the top 10% of options for the next token. This can help to ensure that the generated text is relevant and coherent.'
const toolTipTransfer =
  'This parameter determines how many messages from the chat history will be used as input to the model. If set to "all", the entire chat history will be used. If set to a number, the last n messages will be used.'
const toolTipSystemPrompt =
  "This prompt will change GAIA's behavior. You can use this to clarify the context of your conversation, the topic or the output format."
const toolTipStream = 'Enables streaming of tokens'

interface iSystemSettingsProps {
  activeSession: iSession
}

function SystemSettings({ activeSession }: iSystemSettingsProps) {
  const { updateSession, updateSessionInternal, setInstructions } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      updateSessionInternal: state.updateSessionInternal,
      setInstructions: state.setInstructions,
    }),
    shallow,
  )
  const { setPopup } = useMain()
  const { hasRightTo, selectedTeam } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const {
    assistants,
    updateLocalAssistant,
    hasRightTo: hasAssistantRightTo,
  } = useAssistantStore(
    (state) => ({
      assistants: state.assistants,
      hasRightTo: state.hasRightTo,
      updateLocalAssistant: state.updateLocalAssistant,
    }),
    shallow,
  )

  const [instructionsValue, setInstructionsValue] = useState<string | undefined>()
  const [maxOutputTokens, setMaxOutputTokens] = useState<number>()

  useEffect(() => {
    setInstructionsValue(activeSession?.assistantSettings?.instruction)
  }, [activeSession])

  const maxTokens = 16384

  const minOutputToken = 1
  const temperature = 2
  const topP = 1
  const transfer = 21

  const isO1Model = useMemo(() => {
    return [
      'o1-preview-sweden-central',
      'o1-mini-sweden-central',
    ].includes(activeSession?.assistantSettings?.settings?.modelId ?? '')
  }, [activeSession?.assistantSettings?.settings?.modelId])
  const handleModelChange = (modelValue: {
    modelName: string
    modelRegion: string
    modelId: string
    maxOutputTokens: number
  }) => {
    if (!activeSession.assistantSettings?.settings) {
      return
    }
    if (
      ['o1-preview-sweden-central', 'o1-mini-sweden-central'].includes(
        modelValue.modelId,
      )
    ) {
      activeSession.assistantSettings.settings.stream = false
    } else {
      activeSession.assistantSettings.settings.stream = true
    }
    activeSession.assistantSettings.settings.modelId = modelValue.modelId
    setMaxOutputTokens(modelValue.maxOutputTokens)

    if (activeSession.assistantSettings.settings.maxTokens === 0) {
      activeSession.assistantSettings.settings.maxTokens = modelValue.maxOutputTokens
    } else {
      activeSession.assistantSettings.settings.maxTokens =
        activeSession.assistantSettings.settings.maxTokens! >
        modelValue.maxOutputTokens
          ? modelValue.maxOutputTokens
          : activeSession.assistantSettings.settings.maxTokens
    }
    updateSession(activeSession)
  }
  const handleRangeMTChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeSession.assistantSettings?.settings) {
      return
    }

    try {
      if (isNaN(parseInt(event.target.value))) {
        activeSession.assistantSettings.settings.maxTokens = 1
        return
      }

      const value = parseInt(event.target.value)
      if (value === 0) {
        activeSession.assistantSettings.settings.maxTokens = 1
        return
      }

      const maxValue = parseInt(event.target.max)
      const minValue = parseInt(event.target.min)

      if (value > maxValue) {
        activeSession.assistantSettings.settings.maxTokens = maxValue
        return
      }

      if (value < minValue) {
        activeSession.assistantSettings.settings.maxTokens = minValue
        return
      }

      activeSession.assistantSettings.settings.maxTokens = value
    } catch (error) {
      console.error(error)
    } finally {
      await updateSessionInternal(activeSession)
    }
  }

  const handleRangeTMChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeSession.assistantSettings?.settings) {
      return
    }
    try {
      if (isNaN(parseFloat(event.target.value))) {
        activeSession.assistantSettings.settings.temperature = 1.2
        return
      }

      const value = parseFloat(event.target.value)
      const maxValue = parseFloat(event.target.max)
      const minValue = parseFloat(event.target.min)
      if (value > maxValue) {
        activeSession.assistantSettings.settings.temperature = maxValue
        return
      }
      if (value < minValue) {
        activeSession.assistantSettings.settings.temperature = minValue
        return
      }
      activeSession.assistantSettings.settings.temperature = value
    } catch (error) {
      console.error(error)
    } finally {
      await updateSessionInternal(activeSession)
    }
  }

  const handleRangeTransferChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!activeSession.assistantSettings?.settings) {
      return
    }
    try {
      if (isNaN(parseFloat(event.target.value))) {
        activeSession.assistantSettings.settings.transferLength = 21
        return
      }

      const value = parseFloat(event.target.value)
      const maxValue = parseFloat(event.target.max)
      const minValue = parseFloat(event.target.min)
      if (value > maxValue - 1) {
        activeSession.assistantSettings.settings.transferLength = 21
        return
      }
      if (value < minValue) {
        activeSession.assistantSettings.settings.transferLength = minValue
        return
      }

      activeSession.assistantSettings.settings.transferLength = parseFloat(
        event.target.value,
      )
    } catch (error) {
      console.error(error)
    } finally {
      await updateSessionInternal(activeSession)
    }
  }

  const handleRangeTPChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!activeSession.assistantSettings?.settings) {
      return
    }
    try {
      if (isNaN(parseFloat(event.target.value))) {
        activeSession.assistantSettings.settings.topP = 0.9
        return
      }

      const value = parseFloat(event.target.value)
      const maxValue = parseFloat(event.target.max)
      const minValue = parseFloat(event.target.min)
      if (value > maxValue) {
        activeSession.assistantSettings.settings.topP = maxValue
        return
      }
      if (value < minValue) {
        activeSession.assistantSettings.settings.topP = minValue
        return
      }
      activeSession.assistantSettings.settings.topP = parseFloat(event.target.value)
    } catch (error) {
      console.error(error)
    } finally {
      await updateSessionInternal(activeSession)
    }
  }

  const handleUpdateSystemPrompt = async (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    await setInstructions(activeSession, event.target.value)
    await updateSession(activeSession)
    await updateSessionInternal(activeSession)
  }

  const handleStreamChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (activeSession.assistantSettings?.settings) {
      activeSession.assistantSettings.settings.stream = event.target.checked
      await updateSession(activeSession)
    }
  }

  const basedAssistant = useMemo(() => {
    if (!activeSession.assistantSettings?.baseAssistantSettingsId) return undefined
    return assistants.find(
      (a) => a.id === activeSession.assistantSettings?.baseAssistantSettingsId,
    )
  }, [
    activeSession,
    assistants,
    activeSession.assistantSettings?.baseAssistantSettingsId,
  ])

  const updateAssistant = () => {
    if (!activeSession.assistantSettings?.baseAssistantSettingsId) return
    if (basedAssistant) {
      const assistant: iAssistantSettings = basedAssistant
      assistant.settings!.stream = activeSession.assistantSettings.settings?.stream
      assistant.instruction = activeSession.assistantSettings.instruction
      assistant.settings!.topP = activeSession.assistantSettings.settings?.topP
      assistant.settings!.transferLength =
        activeSession.assistantSettings.settings?.transferLength
      assistant.settings!.temperature =
        activeSession.assistantSettings.settings?.temperature
      assistant.settings!.maxTokens =
        activeSession.assistantSettings.settings?.maxTokens
      assistant.settings!.modelId = activeSession.assistantSettings.settings?.modelId
      assistant.knowledgeContainerIds =
        activeSession.assistantSettings.knowledgeContainerIds
      assistant.toolIds = activeSession.assistantSettings.toolIds
      assistant.archiveChatHistory =
        activeSession.assistantSettings.archiveChatHistory
      AssistantSettingsService.updateAssistantSettings(assistant)
      updateLocalAssistant(assistant)
    }
  }

  return (
    activeSession &&
    (hasRightTo(Role.GaiaUser) || isDefaultTeam(selectedTeam!)) && (
      <div className="grid grid-cols-1 gap-4 m-3 place-content-center min-w-[222px]">
        {/* <SystemSettingsOnboardingGuide></SystemSettingsOnboardingGuide> */}

        <div data-guide-id="instruction" className="mt-4">
          <Tippy
            content={toolTipSystemPrompt}
            placement="left"
            arrow={true}
            delay={100}
            className={'tippyBox'}
          >
            <div className={'flex place-content-center'}>
              Instructions
              <InformationCircleIcon
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.0}
                stroke="currentColor"
                className="w-4 h-4 ml-1"
              />
            </div>
          </Tippy>

          <div>
            <textarea
              className="textarea textarea-primary textarea-sm w-full"
              id="systemPrompt"
              placeholder="System Prompt"
              value={instructionsValue}
              onChange={(e) => setInstructionsValue(e.target.value)}
              onBlurCapture={handleUpdateSystemPrompt}
              rows={5}
            />
          </div>
        </div>

        <div data-guide-id="model" className="mt-4">
          <Tippy
            content={toolTipModel}
            placement="left"
            arrow={true}
            delay={100}
            className={'tippyBox'}
          >
            <div className={' flex place-content-center'}>
              Model
              <InformationCircleIcon
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.0}
                stroke="currentColor"
                className="w-4 h-4 ml-1"
              />
            </div>
          </Tippy>

          <AssistantModelSelection
            variant="bordered"
            modelId={activeSession.assistantSettings!.settings!.modelId!}
            onChange={handleModelChange}
            context={'team'}
          ></AssistantModelSelection>

          <div className="collapse collapse-arrow border border-gray-300 mt-2">
            <input type="checkbox" />
            <div className="collapse-title text-l font-medium">More options</div>
            <div className="collapse-content">
              {!isO1Model && (
                <div data-guide-id="stream" className="mt-4">
                  <label className="label cursor-pointer">
                    <input
                      type="checkbox"
                      className="toggle toggle-primary toggle-ml"
                      checked={activeSession.assistantSettings?.settings?.stream}
                      onChange={handleStreamChange}
                    />
                    <Tippy
                      content={toolTipStream}
                      placement="left"
                      arrow={true}
                      delay={100}
                      className={'tippyBox'}
                    >
                      <div className={'flex place-content-center'}>
                        Stream
                        <InformationCircleIcon
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.0}
                          stroke="currentColor"
                          className="w-4 h-4Ï ml-1"
                        />
                      </div>
                    </Tippy>
                  </label>
                </div>
              )}
              <div data-guide-id="max-tokens" className="mt-4">
                <Tippy
                  content={toolTipMaxTokens}
                  placement="left"
                  arrow={true}
                  delay={100}
                  className={'tippyBox'}
                >
                  <div className={' flex place-content-center'}>
                    Max-Output-Tokens
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </Tippy>
                <div>
                  <input
                    type="range"
                    id="range-mt"
                    min={minOutputToken}
                    max={maxOutputTokens}
                    step={16}
                    inputMode="numeric"
                    value={activeSession.assistantSettings?.settings?.maxTokens}
                    className="range  range-xs"
                    onChange={handleRangeMTChange}
                    onBlurCapture={() => updateSession(activeSession)}
                  />
                </div>

                <input
                  type="text"
                  className="input input-primary input-xs w-full"
                  placeholder="Max-Tokens"
                  value={activeSession.assistantSettings?.settings?.maxTokens}
                  onChange={handleRangeMTChange}
                  onBlurCapture={() => updateSession(activeSession)}
                  max={maxOutputTokens}
                  min={0}
                />
              </div>
              {!isO1Model && (
                <div data-guide-id="temperature" className="mt-4">
                  <Tippy
                    content={toolTipTemperature}
                    placement="left"
                    arrow={true}
                    delay={100}
                    className={'tippyBox'}
                  >
                    <div className={'flex place-content-center'}>
                      Temperature
                      <InformationCircleIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.0}
                        stroke="currentColor"
                        className="w-4 h-4 ml-1"
                      />
                    </div>
                  </Tippy>
                  <input
                    type="range"
                    id="range-tm"
                    min={0}
                    max={temperature}
                    value={activeSession.assistantSettings?.settings?.temperature}
                    step={0.01}
                    className="range  range-xs"
                    onChange={handleRangeTMChange}
                    onBlurCapture={() => updateSession(activeSession)}
                  />

                  <input
                    type="text"
                    className="input input-primary input-xs w-full"
                    placeholder="Temperature"
                    value={activeSession.assistantSettings?.settings?.temperature}
                    onChange={handleRangeTMChange}
                    onBlurCapture={() => updateSession(activeSession)}
                    max={maxTokens}
                    min={0}
                    step={0.01}
                  />
                </div>
              )}
              {!isO1Model && (
                <div data-guide-id="top-p" className="mt-4">
                  <Tippy
                    content={toolTipTopP}
                    placement="left"
                    arrow={true}
                    delay={100}
                    className={'tippyBox'}
                  >
                    <div className={'flex   place-content-center'}>
                      Top-P
                      <InformationCircleIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.0}
                        stroke="currentColor"
                        className="w-4 h-4 ml-1"
                      />
                    </div>
                  </Tippy>
                  <input
                    type="range"
                    id="range-tp"
                    min={0}
                    max={topP}
                    step={0.01}
                    value={activeSession.assistantSettings?.settings?.topP}
                    className="range range-xs"
                    onChange={handleRangeTPChange}
                    onBlurCapture={() => updateSession(activeSession)}
                  />

                  <input
                    type="text"
                    className="input input-primary  input-xs w-full"
                    placeholder="Temperature"
                    value={activeSession.assistantSettings?.settings?.topP}
                    onChange={handleRangeTPChange}
                    onBlur={() => updateSession(activeSession)}
                    max={topP}
                    min={0}
                    step={0.01}
                  />
                </div>
              )}
              <div data-guide-id="transferred" className="mt-4">
                <Tippy
                  content={toolTipTransfer}
                  placement="left"
                  arrow={true}
                  delay={100}
                  className={'tippyBox'}
                >
                  <div className={'flex place-content-center '}>
                    Transferred
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </Tippy>
                <input
                  type="range"
                  id="range-transfer"
                  min={2}
                  max={transfer}
                  step={1}
                  value={activeSession.assistantSettings?.settings?.transferLength}
                  className="range  range-xs"
                  onChange={handleRangeTransferChange}
                  onBlurCapture={() => updateSession(activeSession)}
                />

                <input
                  type="text"
                  className="input input-primary  input-xs w-full"
                  placeholder="Message Transfer Mode"
                  value={
                    activeSession.assistantSettings?.settings?.transferLength === 21
                      ? 'all'
                      : activeSession.assistantSettings?.settings?.transferLength
                  }
                  onChange={handleRangeTransferChange}
                  onBlurCapture={() => updateSession(activeSession)}
                  max={transfer}
                  min={2}
                  step={1}
                />
              </div>

              <div data-guide-id="load-export" className="mt-4">
                <div
                  className={
                    'grid  justify-items-center min-w-fit min-w-[220px] pb-2'
                  }
                >
                  {/*<button*/}
                  {/*    className="btn btn-outline btn-primary btn-sm mt-3 w-4/5 min-w-fit w-full mr-1"*/}
                  {/*    onClick={() => {*/}
                  {/*        setPopup(Popup.AssistantSelector)*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*    <SwatchIcon className="h-5 w-5" fill="none"*/}
                  {/*                viewBox="0 0 24 24" stroke="currentColor"/>*/}
                  {/*    Load*/}
                  {/*</button>*/}

                  <Button
                    variant={'bordered'}
                    className="mt-3 min-w-fit w-full ml-1"
                    onClick={() => {
                      setPopup(Popup.AssistantEdit)
                    }}
                  >
                    <SwatchIcon
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    />
                    Save as new Assistant
                  </Button>
                  {!!basedAssistant &&
                    hasAssistantRightTo(Role.Manager, basedAssistant.id!) && (
                      <Button
                        variant={'bordered'}
                        className="mt-3 min-w-fit w-full ml-1"
                        onClick={updateAssistant}
                      >
                        <PencilSquareIcon
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        />
                        Update Assistant
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default SystemSettings
