import React, { useEffect, useState } from 'react'
import {
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Menu,
  MenuItem,
} from '@nextui-org/react'
import FaqItem from '../components/faq/faqItem.tsx'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { getMarkdownTag } from '../components/utils/markdownUtils.ts'

type FaqPageProps = {
  theme: string
}

interface ParsedFaq {
  markdown: string
  title: string
  description: string
  categories: string[]
  content: string
}

const faqs = import.meta.glob('/cms/faq/*.md', { import: 'default', eager: true })

function FaqPage({ theme }: FaqPageProps) {
  const [searchValue, setSearchValue] = useState('')
  const [allFaqs, setAllFaqs] = useState<ParsedFaq[]>([])
  const [filteredFaqs, setFilteredFaqs] = useState<ParsedFaq[]>([])
  const [searchField, setSearchField] = useState('all') // State for selected search field
  const [isPopoverOpen, setIsPopoverOpen] = useState(false) // State for Popover open/close

  useEffect(() => {
    const parsedFaqs: ParsedFaq[] = []
    for (const key in faqs) {
      if (Object.prototype.hasOwnProperty.call(faqs, key)) {
        const markdown: string = faqs[key] as string
        try {
          const title = getMarkdownTag('G-Title', markdown) as string
          const description = getMarkdownTag('G-Description', markdown) as string
          const categories = getMarkdownTag('G-Categories', markdown) as string[]

          const contentWithoutMetadata = markdown
            .replace(/^\[\/\/\]: # '.*?'\n?/gm, '')
            .trim()

          const parsedFaq: ParsedFaq = {
            markdown,
            title,
            description,
            categories,
            content: contentWithoutMetadata,
          }
          parsedFaqs.push(parsedFaq)
        } catch (error) {
          console.error(`Error parsing markdown file ${key}:`, error)
        }
      }
    }

    setAllFaqs(parsedFaqs)
    setFilteredFaqs(parsedFaqs)
  }, [])

  const handleSearch = (text: string) => {
    setSearchValue(text)

    const lowerText = text.toLowerCase()

    const filtered = allFaqs.filter((faq) => {
      if (searchField === 'all') {
        // Search in all fields
        const faqText =
          `${faq.title} ${faq.description} ${faq.content} ${faq.categories.join(
            ' ',
          )}`.toLowerCase()
        return faqText.includes(lowerText)
      } else {
        // Search in selected field
        let faqFieldValue
        if (searchField === 'category') {
          faqFieldValue = faq.categories.join(' ').toLowerCase()
        } else {
          faqFieldValue = faq[searchField as keyof ParsedFaq]
          faqFieldValue =
            typeof faqFieldValue === 'string' ? faqFieldValue.toLowerCase() : ''
        }
        return faqFieldValue.includes(lowerText)
      }
    })
    setFilteredFaqs(filtered)
  }

  const searchFields = ['all', 'title', 'category', 'content', 'description']

  return (
    <div className="flex justify-center w-full h-full overflow-auto">
      <div className="w-10/12 max-w-4xl">
        <h1 className="font-bold text-7xl text-center">FAQs</h1>
        <div className="flex-1 mr-2 my-6">
          <div className="relative">
            <Input
              isClearable
              variant="bordered"
              placeholder={`Search ${
                searchField === 'all'
                  ? ''
                  : 'in ' +
                    searchField.charAt(0).toUpperCase() +
                    searchField.slice(1)
              }...`}
              className="flex-1"
              classNames={{
                inputWrapper: 'bg-white h-12 shadow-none !bg-[#f6f6f6]',
              }}
              value={searchValue}
              onValueChange={(value) => handleSearch(value)}
              startContent={
                <Popover
                  placement="bottom-start"
                  isOpen={isPopoverOpen}
                  onOpenChange={setIsPopoverOpen}
                >
                  <PopoverTrigger>
                    <button
                      className="focus:outline-none"
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <MagnifyingGlassIcon className="h-5 w-5" />
                    </button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <Menu
                      aria-label="Search Fields"
                      onAction={(field) => {
                        setSearchField(field as string)
                        setIsPopoverOpen(false) // Close the Popover after selection
                      }}
                      selectedKeys={new Set([searchField])}
                      disallowEmptySelection
                      selectionMode="single"
                    >
                      {searchFields.map((field) => (
                        <MenuItem key={field}>
                          {field === 'all'
                            ? 'All Fields'
                            : field.charAt(0).toUpperCase() + field.slice(1)}
                        </MenuItem>
                      ))}
                    </Menu>
                  </PopoverContent>
                </Popover>
              }
            />
          </div>
        </div>
        <div className="flex flex-col items-center w-full pb-24">
          {filteredFaqs.map((faq) => (
            <div key={faq.title} className="flex flex-col w-full">
              <FaqItem markdown={faq.markdown} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FaqPage
