import React, { useState } from 'react'
import iSettingsProps from '../../settings/iSettingsProps'
import { iTool } from './iTool.ts'
import ToolCreateEdit from './toolCreateEdit.tsx'
import { ToolViewTab } from './iToolSettings.ts'
import ToolView from './toolView.tsx'
import { Modal, ModalContent } from '@nextui-org/react'

interface iToolProps extends iSettingsProps {
  selector: 'Selection' | 'Action'
}

function ToolSettings({ closeFunction, selector }: iToolProps) {
  const [toolViewTab, setToolViewTab] = useState<ToolViewTab>(
    selector === 'Selection' ? ToolViewTab.Selection : ToolViewTab.Action,
  )
  const [currentTool, setCurrentTool] = useState<iTool | undefined>(undefined)

  const editToolFunction = (tool: iTool | undefined) => {
    setCurrentTool(tool)
    setToolViewTab(ToolViewTab.Edit)
  }

  const backToToolView = () => {
    if (selector === 'Selection') {
      setToolViewTab(ToolViewTab.Selection)
    } else {
      setToolViewTab(ToolViewTab.Action)
    }
  }

  let currentComponent = <></>
  switch (toolViewTab) {
    case ToolViewTab.Selection:
      currentComponent = (
        <ToolView
          closeFunction={closeFunction}
          editToolFunction={editToolFunction}
          selector={selector}
        />
      )
      break
    case ToolViewTab.Action:
      currentComponent = (
        <ToolView
          closeFunction={closeFunction}
          editToolFunction={editToolFunction}
          selector={selector}
        />
      )
      break
    case ToolViewTab.Edit:
      currentComponent = (
        <ToolCreateEdit closeFunction={backToToolView} currentTool={currentTool} />
      )
      break
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={closeFunction}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent className={''}>{currentComponent}</ModalContent>
    </Modal>
  )
}

export default ToolSettings
