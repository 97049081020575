import { iToolCall } from '../iMessage'
import { useState } from 'react'
import { HttpToolCall } from './httpToolCall'
import { JsonDisplay } from '../../../utils/jsonDisplay'

interface iToolCallProps {
  initialToolCall: iToolCall
}

export function ToolCall({ initialToolCall }: iToolCallProps) {
  const [toolCall, setToolCall] = useState(initialToolCall)

  return (
    <div className="ml-4">
      <h3 className="mt-2 text-xl text-primary">
        {toolCall.toolCallDisplayName || toolCall.toolCallName}
      </h3>
      <details open={true} className="ml-4 mt-1 text-primary">
        <summary>Parameters</summary>
        <JsonDisplay jsonString={toolCall.toolCallArguments} />
      </details>
      {toolCall.toolCallContent && (
        <details className="ml-4 mt-1 text-primary">
          <summary>Content</summary>
          <JsonDisplay jsonString={toolCall.toolCallContent} />
        </details>
      )}
      {toolCall.toolCallHttpContext && (
        <HttpToolCall httpContext={toolCall.toolCallHttpContext} />
      )}
    </div>
  )
}
