import axiosClient from '../utils/axiosClient'
import { iAssistantModel } from '../interfaces/iAssistantModel.ts'

class AssistantService {
  private static instance: AssistantService

  private constructor() {}

  public static getInstance(): AssistantService {
    if (!AssistantService.instance) {
      AssistantService.instance = new AssistantService()
    }
    return AssistantService.instance
  }

  public async connectSignalR(
    userId: string,
  ): Promise<{ url: string; accessToken: string }> {
    // add user id to the request header
    const response = await axiosClient.post('/assistant/connect?userid=' + userId)
    return response.data
  }

  public async joinSession(sessionId: string, connectionId: string): Promise<void> {
    await axiosClient.post(
      `/assistant/session/${sessionId}/join?connectionId=${connectionId}`,
    )
  }

  public async leaveSession(sessionId: string, connectionId: string): Promise<void> {
    await axiosClient.post(
      `/assistant/session/${sessionId}/leave?connectionId=${connectionId}`,
    )
  }

  public async startAssistant(
    sessionId: string,
    message: string | null = null,
  ): Promise<any> {
    // message is optional and a query parameter
    const response = await axiosClient.post(
      `/assistant/session/${sessionId}/start${message ? `?message=${message}` : ''}`,
    )
    return response.data
  }

  public async cancelAssistant(instanceId: string): Promise<void> {
    await axiosClient.post(`/assistant/instance/${instanceId}/cancel`)
  }

  public async getAssistantModels(scopes: string[]): Promise<iAssistantModel[]> {
    const scopesQuery = scopes.join(',')
    const response = await axiosClient.get(`/assistant/models?scopes=${scopesQuery}`)
    return response.data
  }
}

export default AssistantService.getInstance()
