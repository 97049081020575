import { iFolder } from '../components/sessionsGroups/interfaces/iFolder.ts'
import axiosClient from '../utils/axiosClient'

class FolderService {
  private static instance: FolderService

  private constructor() {}

  public static getInstance(): FolderService {
    if (!FolderService.instance) {
      FolderService.instance = new FolderService()
    }

    return FolderService.instance
  }

  public async createFolder(folder: iFolder): Promise<iFolder> {
    return (await axiosClient.post(`/folder`, folder)).data as iFolder
  }

  public async deleteFolder(folderId: string): Promise<void> {
    await axiosClient.delete(`/folder/${folderId}`)
  }

  public async getFolder(folderId: string): Promise<iFolder> {
    return (await axiosClient.get(`/folder/${folderId}`)).data as iFolder
  }

  public async getFolders(teamId?: string): Promise<iFolder[]> {
    return (await axiosClient.get(`/folders/${teamId ?? ''}`)).data as iFolder[]
  }

  public async updateFolder(folder: iFolder): Promise<void> {
    await axiosClient.put(`/folder`, folder)
  }
}

export default FolderService.getInstance()
