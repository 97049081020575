// /Users/malte/Repositories/GAIA/src/services/secretService.ts
import { iSecret } from '../components/utils/iSecret.ts'
import axiosClient from '../utils/axiosClient.ts'

class SecretService {
  private static instance: SecretService

  private constructor() {}

  public static getInstance(): SecretService {
    if (!SecretService.instance) {
      SecretService.instance = new SecretService()
    }
    return SecretService.instance
  }

  public async getSecrets(): Promise<iSecret[]> {
    return (await axiosClient.get(`/secrets`)).data as iSecret[]
  }

  public async createSecret(secret: iSecret): Promise<iSecret> {
    return (await axiosClient.post(`/secret`, secret)).data as iSecret
  }

  public async deleteSecret(secretId: string) {
    return await axiosClient.delete(`/secret/${secretId}`)
  }

  public async updateSecret(secret: iSecret) {
    return await axiosClient.put(`/secret`, secret)
  }
}

export default SecretService.getInstance()
