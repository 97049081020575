import React, { useState } from 'react'
import SessionsFolders from '../sessionsGroups/sessionsFolders.tsx'
import NavBarButtons from '../sessionControls/navBarButtons.tsx'
import Drawer from '../basic/drawer/drawer.tsx'
import {
  Bars3BottomLeftIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { Divider, Button, Listbox, ListboxItem } from '@nextui-org/react'
import TeamSelect from '../team/teamSelect.tsx'
import RoadmapNotification from '../utils/roadmapNotification.tsx'
import AccountMenu from '../utils/accountMenu.tsx'
import ManageTeamsButton from '../utils/manageTeamsButton.tsx'
import DraegerLogo from '../basic/logo/draegerLogo.tsx'
import { Outlet, useNavigate } from 'react-router-dom'
import NavItem from '../nav/navItem.tsx'
import { hasFeatureFlag } from '../../utils/featureFlags.ts'

const AppDrawer = () => {
  const [theme] = useState<string>('draeger')
  const navigate = useNavigate()
  const [chatDrawer, setChatDrawer] = useState(true)
  function toggleChatDrawer() {
    setChatDrawer(!chatDrawer)
  }
  return (
    <Drawer
      className={`${chatDrawer ? 'lg:drawer-open' : ''} h-full`}
      open={chatDrawer}
      onClickOverlay={toggleChatDrawer}
      onToggleOpen={(val) => setChatDrawer(val)}
      side={
        <div className="menu flex p-0 w-80 h-full bg-neutral-content text-base-content overflow-auto ">
          <div className={'flex flex-col flex-nowrap w-full h-full'}>
            <div className={'sticky top-0 bg-neutral-content z-20 px-4 pt-4'}>
              <div className={'w-full flex'}>
                <Button isIconOnly variant={'light'} onClick={toggleChatDrawer}>
                  <Bars3BottomLeftIcon className={'h-5 w-5'} />
                </Button>
                <h1
                  className={
                    'flex justify-center items-center font-bold text-xl text-center w-full'
                  }
                >
                  GAIA
                </h1>
                <RoadmapNotification />
              </div>

              <div className={'flex items-center mt-8'}>
                <TeamSelect classNames={{ trigger: 'bg-ghost shadow-none' }} />
                <ManageTeamsButton className={'ml-2'} />
              </div>
              <Divider className={'mt-4'}></Divider>
            </div>
            <SessionsFolders className={'px-4 pt-4'} />
            <div className="sticky pb-4 px-4 -bottom-0 mt-4 bg-neutral-content z-10">
              <Divider className={'mb-2'}></Divider>
              <>
                {/*                  <NavItem
                    title={'Explore'}
                    onPress={(key) => navigate('/explore')}
                    icon={<SquaresPlusIcon className={'w-6 h-6'} />}
                  ></NavItem>*/}
                <NavItem
                  title={'FAQ'}
                  onPress={(key) => navigate('/faq')}
                  icon={<QuestionMarkCircleIcon className={'w-6 h-6'} />}
                ></NavItem>
                <AccountMenu />
              </>
            </div>
          </div>
        </div>
      }
    >
      <div id="mainContainer" className="flex flex-col h-full">
        <div className="navbar bg-base-100">
          <div className="navbar-start">
            <Button
              isIconOnly
              variant={'light'}
              onClick={toggleChatDrawer}
              className={`${chatDrawer ? 'hidden' : ''}`}
            >
              <Bars3BottomLeftIcon className={'h-5 w-5'} />
            </Button>
            <DraegerLogo className={'h-9 ml-8'} />
          </div>
          {/*
            <div className="navbar-end">
              <NavBarButtons />
            </div>
          */}
        </div>
        {/* Main Content */}
        <div className={'flex flex-1 bg-base-100 max-h-[calc(100vh_-_56px)]'}>
          <Outlet />
        </div>
      </div>
    </Drawer>
  )
}

export default AppDrawer
