import { iItem } from '../../../interfaces/iItem'
import { iHttpTemplate } from './httpTemplates/model'
import { iRole } from '../../../interfaces/iShared.ts'
import { OverridableParameterSet } from './model.ts'

export enum ExecutionEnvironment {
  Local = 0,
  ExternalHttp = 1,
}

export interface iTool extends iItem {
  selected: boolean
  name: string
  description: string
  ownerEmail?: string
  ownerId?: string
  image?: string
  functionName?: string
  citationRequired?: boolean
  parameterOverrides?: string[] | null
  overridableParameters?: OverridableParameterSet
  executionEnvironment: ExecutionEnvironment
  httpTemplate?: iHttpTemplate
  httpTemplateString?: string
  settings: { [key: string]: any } | null
  roles?: iRole[]
  fromBaseAssistantInherited: boolean
}
