import React, { useState } from 'react'
import { Button, Input, Tooltip } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { FolderPlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import DialogSessionFolderModal from './dialogSessionFolderModal.tsx'

type SessionFoldersSearchProps = React.HTMLAttributes<HTMLDivElement> & {
  onValueChange?: (value: string) => void
  isLoading?: boolean
}
const SessionFoldersSearch = ({
  onValueChange,
  isLoading,
}: SessionFoldersSearchProps) => {
  const { t } = useTranslation()
  const [dialogSessionFolderModalDialog, setDialogSessionFolderModalDialog] =
    useState<boolean>(false)

  return (
    <>
      <DialogSessionFolderModal
        open={dialogSessionFolderModalDialog}
        onClose={() => setDialogSessionFolderModalDialog(false)}
      />
      <div className={'flex items-center bg-neutral-content z-20'}>
        <Input
          isClearable
          variant="bordered"
          placeholder="Type to search ..."
          className="flex-1 mr-2 "
          isDisabled={isLoading}
          classNames={{
            inputWrapper: 'bg-white h-12 shadow-none !bg-[#f6f6f6]',
          }}
          startContent={<MagnifyingGlassIcon className="h-5 w-5" />}
          onValueChange={(value) => onValueChange && onValueChange(value)}
        />
        <Tooltip
          content={t('translation:addFolder')}
          color={'primary'}
          className={'max-w-96'}
        >
          <Button
            className={'mt-3 mb-3'}
            fullWidth
            isLoading={isLoading}
            isIconOnly
            variant={'light'}
            color={'primary'}
            onClick={() => setDialogSessionFolderModalDialog(true)}
          >
            <FolderPlusIcon className={'w-5 h-5'} />
          </Button>
        </Tooltip>
      </div>
    </>
  )
}

export default SessionFoldersSearch
