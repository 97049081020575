import { iItem } from '../../../interfaces/iItem'

export interface iFolder extends iItem {
  title: string
  teamId: string
  settingsId?: string
}

export enum SessionModalMode {
  None,
  Create,
  Edit,
}
