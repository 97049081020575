import { createWithEqualityFn } from 'zustand/traditional'
import { iTool } from '../components/sessionControls/tools/iTool.ts'
import ToolService from '../services/toolService.ts'
import { Role } from '../interfaces/iShared.ts'
import { getUserKey } from '../components/login/authentication.tsx'

interface iToolState {
  isLoading: boolean
  tools: iTool[]
  getToolRole: (toolId: string) => Role | undefined
  setIsLoading: (isLoading: boolean) => void
  hasRightTo: (minRole: Role, assistantId: string) => boolean
  setTools: (teams: iTool[]) => void
  loadTools: (assistantId?: string) => Promise<iTool[]>
}

const useToolStore = createWithEqualityFn<iToolState>((set, getState) => ({
  isLoading: false,
  tools: [],
  setTools: (tools: iTool[]) => set(() => ({ tools })),
  getToolRole: (toolId) => {
    const tool = getState().tools.find((p: iTool) => p.id === toolId)
    const shared = tool?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  hasRightTo: (minRole: Role, toolId) => {
    const tool = getState().tools.find((p: iTool) => p.id === toolId)
    const isOwner = tool?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getToolRole(toolId)
    if (role === undefined) return false
    return role <= minRole
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  loadTools: async (assistantId?: string) => {
    const tools = await ToolService.getTools(assistantId)
    getState().setTools(tools)
    return tools
  },
}))

export default useToolStore
