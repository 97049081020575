import { createWithEqualityFn } from 'zustand/traditional'
import { iAssistantSettings } from '../components/sessionControls/assistants/iAssistantTypes.tsx'
import sessionSettingsService from '../services/sessionSettingsService.ts'
import { Role } from '../interfaces/iShared.ts'
import { getUserKey } from '../components/login/authentication.tsx'
import AssistantSettingsService from '../services/sessionSettingsService.ts'

interface iAssistantState {
  isLoading: boolean
  assistants: iAssistantSettings[]
  getAssistantRole: (assistantId: string) => Role | undefined
  hasRightTo: (minRole: Role, assistantId: string) => boolean
  updateLocalAssistant: (assistant: iAssistantSettings) => void
  deleteAssistant: (id: string) => Promise<void>
  createAssistant: (assistant: iAssistantSettings) => Promise<iAssistantSettings>
  setIsLoading: (isLoading: boolean) => void
  getAssistantsByContext: (
    context: 'all' | 'team',
    teamId?: string,
  ) => iAssistantSettings[]
  setAssistants: (assistants: iAssistantSettings[]) => void
  loadAssistants: () => Promise<iAssistantSettings[]>
}

const useAssistantStore = createWithEqualityFn<iAssistantState>((set, getState) => ({
  isLoading: false,
  assistants: [],
  setAssistants: (assistants: iAssistantSettings[]) => set(() => ({ assistants })),
  deleteAssistant: async (id) => {
    await AssistantSettingsService.deleteAssistantSettings(id)
    const newAssistants = getState().assistants.filter(
      (assistant) => assistant.id !== id,
    )
    getState().setAssistants(newAssistants)
  },
  createAssistant: async (assistant: iAssistantSettings) => {
    const newAssistant =
      await AssistantSettingsService.createAssistantSettings(assistant)
    const tmpAssistants = [...getState().assistants, newAssistant]
    getState().setAssistants(tmpAssistants)
    return newAssistant
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  getAssistantRole: (assistantId) => {
    const assistant = getState().assistants.find(
      (a: iAssistantSettings) => a.id === assistantId,
    )
    const shared = assistant?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  getAssistantsByContext: (context: 'all' | 'team', teamId?: string) => {
    const assistants = getState().assistants
    if (context === 'team') {
      if (!teamId) throw new Error('teamId param is necessary for team context')
      return assistants.filter((a) => {
        return a.teamReferences?.find((teamRefId) => teamRefId === teamId)
      })
    }
    return assistants
  },
  hasRightTo: (minRole: Role, assistantId) => {
    const assistant = getState().assistants.find(
      (a: iAssistantSettings) => a.id === assistantId,
    )
    const isOwner = assistant?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getAssistantRole(assistantId)
    if (role === undefined) return false
    return role <= minRole
  },
  loadAssistants: async () => {
    const blueprints = await sessionSettingsService.getBlueprintAssistants()
    getState().setAssistants(blueprints)
    return blueprints
  },
  updateLocalAssistant: (assistant) => {
    const allAssistants = getState().assistants
    const assistantIndex = allAssistants.findIndex((a) => a.id === assistant.id)
    if (assistantIndex > -1) {
      allAssistants[assistantIndex] = assistant
      getState().setAssistants([...allAssistants])
    }
  },
}))

export default useAssistantStore
