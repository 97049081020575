import React, { useMemo } from 'react'
import { Spinner } from '@nextui-org/react'
import useSessionStore from '../stateManagement/sessionState.ts'
import useFolderStore from '../stateManagement/folderState.ts'
import { shallow } from 'zustand/shallow'
import useAssistantStore from '../stateManagement/assistantState.ts'
import useTeamStore from '../stateManagement/teamState.ts'
import SessionChatContent from '../components/sessionsGroups/sessionChatContent.tsx'
import NoRelatedTeam from '../components/emptyStates/noRelatedTeam.tsx'
import EmptyTeam from '../components/emptyStates/emptyTeam.tsx'
import EmptySessionFolder from '../components/emptyStates/emptySessionFolder.tsx'
import LegalDialog from '../components/legal/legalDialog.tsx'

interface iSessionContentProps {
  theme: string
}

function SessionContent({ theme }: iSessionContentProps) {
  const { sessions, isLoading, activeSession } = useSessionStore(
    (state) => ({
      isLoading: state.isLoading,
      sessions: state.sessions,
      activeSession: state.activeSession,
    }),
    shallow,
  )

  const { assistants } = useAssistantStore(
    (state) => ({
      assistants: state.assistants,
    }),
    shallow,
  )

  const { folders } = useFolderStore(
    (state) => ({ folders: state.folders }),
    shallow,
  )
  const { teams, selectedTeam } = useTeamStore(
    (state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const termsOfUseAssistant = useMemo(() => {
    const assistantId = activeSession?.assistantSettings?.baseAssistantSettingsId
    const assistant = assistants.find((assistant) => assistant.id === assistantId)
    return assistant?.termsOfUse
  }, [activeSession?.assistantSettings?.baseAssistantSettingsId])

  const displayLoading = useMemo(
    () => !teams || !sessions || isLoading,
    [teams, sessions, isLoading],
  )
  return (
    <>
      {displayLoading ? (
        <div className={'flex flex-1 justify-center align-center'}>
          <Spinner size={'lg'} />
        </div>
      ) : selectedTeam && sessions && activeSession ? (
        <SessionChatContent theme={theme} />
      ) : teams?.length === 0 ? (
        <NoRelatedTeam />
      ) : teams?.length && folders.length === 0 ? (
        <EmptyTeam />
      ) : folders.length > 0 && sessions.length === 0 ? (
        <EmptySessionFolder />
      ) : (
        <></>
      )}
      {termsOfUseAssistant && (
        <LegalDialog
          termsOfUse={termsOfUseAssistant}
          resourceId={activeSession!.assistantSettings!.baseAssistantSettingsId!}
        ></LegalDialog>
      )}
    </>
  )
}

export default SessionContent
