import React, { useEffect, useMemo, useState } from 'react'
import {
  AssistantModelAttribute,
  iAssistantModel,
} from '../../../interfaces/iAssistantModel.ts'
import {
  DocumentTextIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import { Select, SelectItem, Tooltip } from '@nextui-org/react'
import useAssistantModelStore from '../../../stateManagement/assistantModelState.ts'
import useTeamStore from '../../../stateManagement/teamState.ts'
import { shallow } from 'zustand/shallow'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Key } from '@react-types/shared'

interface iAIModelSelectionProps {
  modelId: string
  onChange?: (value: {
    modelName: string
    modelRegion: string
    modelId: string
    maxOutputTokens: number
  }) => void
  disableHint?: boolean
  isDisabled?: boolean
  context?: 'all' | 'team'
  variant?: 'bordered' | 'flat' | undefined
}

function AssistantModelSelection({
  modelId,
  onChange,
  disableHint,
  isDisabled,
  variant,
  context = 'all',
}: iAIModelSelectionProps) {
  const { getAssistantModelsByContext, assistantModels: allAssistantModels } =
    useAssistantModelStore(
      (state) => ({
        assistantModels: state.assistantModels,
        getAssistantModelsByContext: state.getAssistantModelsByContext,
      }),
      shallow,
    )

  const { selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )
  const [selectedModel, setSelectedModel] = useState<iAssistantModel | undefined>()
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>()

  const previewModelTooltip = `This model is for preview purposes only and are not production-ready. Use at your own risk.`
  const assistantModels = useMemo(() => {
    return getAssistantModelsByContext(context, selectedTeam?.id)
  }, [allAssistantModels, selectedTeam])

  useEffect(() => {
    const assistantModel = assistantModels.find((model) => model.id === modelId)

    setSelectedModel(assistantModel ?? undefined)
    setSelectedRegion(assistantModel?.region ?? undefined)
  }, [modelId, assistantModels])

  useEffect(() => {
    if (selectedModel && selectedRegion && onChange)
      onChange({
        modelName: selectedModel!.deploymentName,
        modelRegion: selectedRegion!,
        modelId: selectedModel!.id,
        maxOutputTokens: selectedModel!.maxOutputTokens,
      })
  }, [selectedModel, selectedRegion])

  const selectedModelKeys: 'all' | Iterable<Key> | undefined = useMemo(
    () =>
      selectedModel ? ([selectedModel.deploymentName] as Iterable<Key>) : new Set(),
    [selectedModel],
  )

  const selectedRegionKeys: 'all' | Iterable<Key> | undefined = useMemo(
    () => (selectedRegion ? ([selectedRegion] as Iterable<Key>) : new Set()),
    [selectedRegion],
  )

  const regions = useMemo(() => {
    const tmpRegions: { value: string }[] = []
    assistantModels.map((model) => {
      tmpRegions.push({ value: model.region })
    })
    return [...new Map(tmpRegions.map((item) => [item['value'], item])).values()]
  }, [assistantModels])

  const filterModelByRegion = (region: string) =>
    assistantModels.filter((model) => model.region === region)

  const models = useMemo(() => {
    return filterModelByRegion(selectedRegion!)
  }, [selectedRegion])

  const attributeDisplay = (attr: string) => {
    let icon = <QuestionMarkCircleIcon className={'w-5 h-5'} />
    let tooltip = attr
    switch (attr) {
      case AssistantModelAttribute.toolCalling:
        icon = <WrenchIcon className={'w-5 h-5'} />
        tooltip = 'Tool Calling'
        break
      case AssistantModelAttribute.parallelToolCalling:
        icon = <WrenchScrewdriverIcon className={'w-5 h-5'} />
        tooltip = 'Parallel Tool Calling'
        break
      case AssistantModelAttribute.textProcessing:
        icon = <DocumentTextIcon className={'w-5 h-5'} />
        tooltip = 'Text Processing'
        break
      case AssistantModelAttribute.imageProcessing:
        icon = <PhotoIcon className={'w-5 h-5'} />
        tooltip = 'Image Processing'
        break
    }
    return {
      tooltip,
      icon,
    }
  }

  return (
    !!assistantModels.length && (
      <div>
        <div className="join w-full">
          {regions.length > 0 && (
            <Select
              variant={variant}
              aria-label="select model region"
              selectedKeys={selectedRegionKeys}
              disabled={isDisabled}
              items={regions}
              disallowEmptySelection
              className="max-w-28"
              onChange={(e) => {
                const region = e.target.value
                const isCurrentModelInRegionAvailable =
                  selectedModel?.region === region
                if (!isCurrentModelInRegionAvailable)
                  setSelectedModel(filterModelByRegion(region)[0])
                setSelectedRegion(region)
              }}
              renderValue={(items) => {
                return items.map((item) => (
                  <span key={item.textValue}>{item.textValue}</span>
                ))
              }}
            >
              {regions.map((region, key) => (
                <SelectItem key={region.value} value={region.value}>
                  {region.value}
                </SelectItem>
              ))}
            </Select>
          )}
          <Select
            variant={variant}
            aria-label="model selection"
            selectedKeys={selectedModelKeys}
            items={models}
            disallowEmptySelection
            className="w-full"
            disabled={isDisabled}
            onChange={(e) => {
              setSelectedModel(
                models.find((model) => model.deploymentName === e.target.value),
              )
            }}
            renderValue={(items) => {
              return items.map((item) => (
                <span
                  key={item.data?.id}
                  className={item.data?.preview ? 'text-orange-400' : ''}
                >
                  {item.data?.name}
                </span>
              ))
            }}
          >
            {(model) => (
              <SelectItem key={model.deploymentName} value={model.deploymentName}>
                <div className={'flex items-center'}>
                  <span className={model?.preview ? 'text-orange-400' : ''}>
                    {model?.name}
                  </span>
                  {model?.preview && (
                    <Tooltip
                      content={previewModelTooltip}
                      color={'primary'}
                      className={'max-w-96'}
                    >
                      <InformationCircleIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.0}
                        stroke="currentColor"
                        className="w-4 h-4 ml-1"
                      />
                    </Tooltip>
                  )}
                </div>
              </SelectItem>
            )}
          </Select>
        </div>
        <div>
          {selectedModel?.attributes.map((attr, key) => {
            const attribute = attributeDisplay(attr)
            return (
              <Tooltip
                content={attribute.tooltip}
                color={'primary'}
                key={key + '-attr'}
                className={'max-w-96'}
              >
                <div
                  key={key + '-attr1'}
                  className="badge badge-lg h-8 mr-1 badge-primary my-2 "
                >
                  <div className="avatar -ml-1"></div>
                  <span className="ml-2">{attribute.icon}</span>
                </div>
              </Tooltip>
            )
          })}
        </div>
        {!disableHint && (
          <div className={'text-blue-400 my-3'}>
            Clear the Chat session if you change between the vision and the standard
            models.
          </div>
        )}
      </div>
    )
  )
}

export default AssistantModelSelection
