import React, { useEffect, useRef, useState } from 'react'
import { iFeedback, RatingType } from './iFeedback.ts'
import feedbackService from '../../../services/feedbackService'
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline'
import StarRating from './starRating'
import ThumbRating from './thumbRating'

interface iFeedbackWidgetProps {
  initialFeedback?: iFeedback | null
  ratingType: RatingType
  commentingEnabled?: boolean
  entityType: string
  entityId: string
  onFeedbackSaved?: (feedback: iFeedback) => void
  onBeforeFeedbackSaved?: () => void
}

interface iFeedbackData {
  ratingValue: number | null
  comment: string
}

function FeedbackWidget({
  initialFeedback,
  ratingType,
  entityType,
  entityId,
  commentingEnabled,
  onFeedbackSaved,
  onBeforeFeedbackSaved,
}: iFeedbackWidgetProps) {
  onFeedbackSaved = onFeedbackSaved || (() => {})
  onBeforeFeedbackSaved = onBeforeFeedbackSaved || (() => {})
  const [feedback, setFeedback] = useState(
    initialFeedback || {
      entityType,
      entityId,
      rating: {
        type: ratingType,
        value: null,
      },
      comment: '',
    },
  )
  const [lastFeedbackData, setLastFeedbackData] = useState<iFeedbackData>({
    ratingValue: feedback.rating?.value || null,
    comment: feedback.comment,
  })
  const [isCommentingEnabled] = useState(
    commentingEnabled === undefined ? true : commentingEnabled,
  )
  const [isCommentingOpen, setIsCommentingOpen] = useState(false)
  const commentInput = useRef<HTMLTextAreaElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (commentInput.current && isCommentingOpen) {
      commentInput.current.focus()
      const length = commentInput.current.value.length
      commentInput.current.setSelectionRange(length, length)
    }
    if (!isCommentingOpen) {
      saveFeedback(feedback)
    }
  }, [isCommentingOpen])

  const onRating = (value: number) => {
    const fb = { ...feedback }
    fb.rating = {
      type: ratingType,
      value,
    }
    setFeedback(fb)
    if (isCommentingEnabled) {
      setIsCommentingOpen(true)
    } else {
      saveFeedback(fb)
    }
  }

  const handleCommentClick = () => {
    if (feedback.rating.value === null) {
      return
    }
    setIsCommentingOpen(true)
  }

  const onCommenting = () => {
    setFeedback({ ...feedback, comment: commentInput.current?.value || '' })
  }

  const saveFeedback = (fb: iFeedback) => {
    if (
      fb.rating?.value === lastFeedbackData?.ratingValue &&
      fb.comment === lastFeedbackData.comment
    ) {
      return
    }
    if (isLoading) return
    setIsLoading(true)
    onBeforeFeedbackSaved()
    return feedbackService.upsertFeedback(fb).then((f) => {
      setFeedback(f)
      setLastFeedbackData({ ratingValue: f.rating.value, comment: f.comment })
      setIsLoading(false)
      onFeedbackSaved(f)
    })
  }

  return (
    <>
      <span>
        {(ratingType === 'stars' && (
          <StarRating
            currentValue={feedback.rating?.value || 0}
            onRated={onRating}
          />
        )) ||
          (ratingType === 'thumbs' && (
            <ThumbRating
              currentValue={feedback.rating.value || 0}
              onRated={onRating}
            />
          ))}
        {isCommentingEnabled && (
          <ChatBubbleBottomCenterIcon
            stroke={lastFeedbackData.comment ? '#e5e7eb' : '#6b7280'} // = stroke-gray-200 : stroke-gray-500 (https://tailwindcss.com/docs/stroke)
            className={'w-[1.5em] inline-block ml-2 cursor-pointer'}
            onClick={handleCommentClick}
          />
        )}
      </span>
      {isCommentingOpen && (
        <textarea
          ref={commentInput}
          className="absolute right-1 top-8 textarea textarea-bordered w-[300px] h-24 text-gray-700 z-10 float-right"
          placeholder="Additional feedback and reference answer"
          onChange={onCommenting}
          value={feedback.comment}
          onBlur={() => setIsCommentingOpen(false)}
        ></textarea>
      )}
    </>
  )
}

export default FeedbackWidget
