import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Page, Document, pdfjs } from 'react-pdf'
import { iKnowledgeReference, ViewerType } from './iSourceFileCitations'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from '@nextui-org/react'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import './pdfViewer.css'
import {
  ArrowsPointingOutIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline'
import useMouseDragScroll from '../../../hooks/useMouseDragScroll.ts'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

interface iPdfViewerProps {
  references: iKnowledgeReference[]
  acticeReferenceId?: number
}

const PdfViewer: React.FC<iPdfViewerProps> = ({ references, acticeReferenceId }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [numPages, setNumPages] = useState<number>()
  const [scale, setScale] = useState<number>(1)
  const [modalScale, setModalScale] = useState<number>(1)
  const [currentReferenceId, setCurrentReferenceId] = useState<number | null>(
    acticeReferenceId || null,
  )
  const [currentReference, setCurrentReference] =
    useState<iKnowledgeReference | null>(
      references.find((reference) => reference.id === acticeReferenceId) ||
        references[0] ||
        null,
    )

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }, [])

  const onDocumentLoadError = (e: Error) => {
    console.error(e)
  }

  useEffect(() => {
    const reference =
      references.find((reference) => reference.id === currentReferenceId) ||
      references[0]
    if (reference) {
      setCurrentReference(reference)
      setCurrentReferenceId(reference.id)
    }
  }, [currentReferenceId, references])

  useEffect(() => {
    if (currentReference) {
      setCurrentPage(currentReference.page)
    }
  }, [currentReference])

  const documentLoading = useMemo(
    () => (
      <div className="h-full flex items-center">
        <Spinner color="primary" size="sm" className="rounded-full" />
      </div>
    ),
    [],
  )

  const toolbar = useCallback(
    (isModal = false) => {
      const setScaleMethod = isModal ? setModalScale : setScale
      const scaleValue = isModal ? modalScale : scale
      return (
        <div className="flex justify-end">
          <Button
            isIconOnly
            variant="light"
            onClick={() => setScaleMethod(scaleValue + 0.25)}
          >
            <MagnifyingGlassPlusIcon className="h-5 w-5" />
          </Button>
          <Button
            isIconOnly
            variant="light"
            onClick={() => setScaleMethod(scaleValue > 1 ? scaleValue - 0.25 : 1)}
          >
            <MagnifyingGlassMinusIcon className="h-5 w-5" />
          </Button>
          {!isModal && (
            <Button isIconOnly variant="light" onClick={onOpen}>
              <ArrowsPointingOutIcon className="h-5 w-5" />
            </Button>
          )}
        </div>
      )
    },
    [scale, modalScale, onOpen],
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const containerRefModal = useRef<HTMLDivElement>(null)
  const { events, isMoving } = useMouseDragScroll(containerRef)
  const { events: modalEvents } = useMouseDragScroll(containerRefModal)

  return (
    <div className="flex text-primary">
      <div className="w-1/4 p-4 border-r">
        {references.map((reference) => (
          <div
            key={reference.id}
            className={`cursor-pointer p-2 hover:border-l-2 hover:border-secondary transition-colors duration-300 ${
              currentReferenceId === reference.id
                ? 'border-l-2 border-secondary'
                : 'border-l-2 border-transparent'
            }`}
            onClick={() => setCurrentReferenceId(reference.id)}
          >
            <p className="text-slate-700 text-xs font-bold">
              <span
                className="font-normal text-slate-600 mr-1"
                style={{ verticalAlign: 'super', fontSize: '0.62rem' }}
              >
                {reference.id}
              </span>{' '}
              {reference.content}
            </p>
            <p className="text-slate-600 text-xs mt-1">
              <p>{reference.container}</p>
              <p className="italic">
                {reference.documentAccess.documentName}, Page: {reference.page}
              </p>
            </p>
          </div>
        ))}
      </div>
      <div className="w-3/4 p-4 h-full">
        {toolbar()}
        <div className="relative h-full justify-center items-center flex">
          {(currentReference?.documentAccess.viewerType === ViewerType.GaiaViewer &&
            ((currentReference.documentAccess.mimeType === 'application/pdf' && (
              <Document
                {...events}
                inputRef={containerRef}
                file={currentReference?.documentAccess.accessUrl || ''}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) => onDocumentLoadError(error)}
                loading={documentLoading}
                className={`!h-[600px] overflow-auto ${isMoving ? 'cursor-grabbing' : 'cursor-grab'}`}
              >
                <Page
                  scale={scale}
                  renderTextLayer={false}
                  pageNumber={currentPage}
                />
              </Document>
            )) || (
              <a
                href={currentReference?.documentAccess.accessUrl}
                rel="noreferrer"
                className="flex btn items-center justify-center h-full w-full text-slate-600"
              >
                Download{' '}
                {(currentReference.documentAccess.documentName && (
                  <span className="text-slate-800 italic">
                    {currentReference.documentAccess.documentName}
                  </span>
                )) ||
                  'Document'}
              </a>
            ))) ||
            (currentReference?.documentAccess.viewerType ===
              ViewerType.HttpNewTab && (
              <a
                href={currentReference?.documentAccess.accessUrl}
                target="_blank"
                rel="noreferrer"
                className="flex btn items-center justify-center h-full w-full text-slate-600"
              >
                Open{' '}
                <span className="italic  text-slate-800">
                  {currentReference.documentAccess.documentName || ''}
                </span>{' '}
                in new tab
              </a>
            ))}
          <Modal
            size="full"
            backdrop="blur"
            isOpen={isOpen}
            scrollBehavior="inside"
            onOpenChange={onOpenChange}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1" />
                  <ModalBody>
                    <div className="flex flex-col h-full">
                      {toolbar(true)}
                      <div className="relative justify-center items-center flex overflow-hidden">
                        <Document
                          {...modalEvents}
                          inputRef={containerRefModal}
                          file={currentReference?.documentAccess.accessUrl || ''}
                          onLoadSuccess={onDocumentLoadSuccess}
                          className={`overflow-auto ${isMoving ? 'cursor-grabbing' : 'cursor-grab'} h-full`}
                          loading={documentLoading}
                        >
                          <Page
                            scale={modalScale}
                            renderTextLayer={false}
                            pageNumber={currentPage}
                          />
                        </Document>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="light" onPress={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </div>
        {Boolean(numPages) && (
          <div className="flex justify-between mt-4">
            <Button
              color="primary"
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <ChevronLeftIcon className="w-5 h-5" /> Previous
            </Button>
            <p className="flex items-center">
              Page {currentPage} of {numPages}
            </p>
            <Button
              color="primary"
              isDisabled={currentPage >= (numPages || 0)}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next <ChevronRightIcon className="w-5 h-5" />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PdfViewer
