import { createWithEqualityFn } from 'zustand/traditional'
import { iTeam } from '../components/sessionControls/userManagement/iTeam.tsx'
import TeamService from '../services/teamService.ts'
import SessionState, { getLastModifiedSession } from './sessionState.ts'
import { createJSONStorage, persist } from 'zustand/middleware'
import { Role } from '../interfaces/iShared.ts'
import UserProfileState from './userProfileState.ts'
import { getUserKey } from '../components/login/authentication.tsx'

interface iTeamState {
  isLoading: boolean
  teams: iTeam[]
  selectedTeam: iTeam | null
  role: Role | null
  isOwner: boolean
  setIsLoading: (isLoading: boolean) => void
  hasRightTo: (role: Role, team?: iTeam) => boolean
  editableTeams: iTeam[]
  setEditableTeams: (teams: iTeam[]) => void
  setTeams: (teams: iTeam[]) => void
  setSelectedTeam: (team: iTeam) => Promise<void>
  setSelectedTeamByString: (teamId: string) => Promise<void>
  setRole: (role: Role) => void
  getTeams: () => Promise<iTeam[]>
  deleteTeam: (teamId: string) => Promise<void>
  createTeam: (team: iTeam) => Promise<iTeam>
}

const useTeamStore = createWithEqualityFn<iTeamState>()(
  persist(
    (set, getState) => ({
      isLoading: true,
      teams: [],
      editableTeams: [],
      selectedTeam: null,
      hasRightTo: (minRole: Role, team?: iTeam) => {
        let isOwner = getState().isOwner
        let role = getState().role!
        if (team) {
          const userId = UserProfileState.getState().userProfile?.id
          role = team.shared.find((s) => s.id === userId)?.role ?? Role.LimitedMember
          isOwner = team.ownerId === getUserKey()
        }
        if (isOwner) return true
        return role <= minRole
      },
      role: null,
      isOwner: false,
      setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
      setRole: (role: Role) => set(() => ({ role })),
      setTeams: (teams: iTeam[]) => {
        set(() => ({ teams }))
        getState().setEditableTeams(teams)
      },
      setEditableTeams: (teams) => {
        const editableTeams = teams.filter((team) =>
          getState().hasRightTo(Role.Manager, team),
        )
        set(() => ({ editableTeams }))
      },
      setSelectedTeamByString: async (teamId: string) => {
        const team = getState().teams.find((team) => team.id === teamId)
        if (team) await getState().setSelectedTeam(team)
      },
      setSelectedTeam: async (selectedTeam: iTeam) => {
        //Todo: implement caching
        if (getState().selectedTeam && selectedTeam) {
          SessionState.getState().setActiveSession(null)
          const sessions = await SessionState.getState().getSessions({
            teamId: selectedTeam.id!,
          })
          const session = getLastModifiedSession(sessions ?? [])
          if (session) await SessionState.getState().activateSession(session)
        }
        const userId = UserProfileState.getState().userProfile?.id
        const role = selectedTeam.shared.find((s) => s.id === userId)?.role
        const isOwner = selectedTeam.ownerId === getUserKey()
        getState().setRole(role!)
        set(() => ({ isOwner }))
        set(() => ({ selectedTeam }))
      },
      getTeams: async () => {
        getState().setIsLoading(true)
        return TeamService.getTeams(false)
          .then((teams) => {
            getState().setTeams(teams)
            getState().setIsLoading(false)
            return teams
          })
          .catch((e: unknown) => {
            getState().setIsLoading(false)
            console.error(e)
            return []
          })
      },
      deleteTeam: async (teamId) => {
        await TeamService.deleteTeam(teamId)
        const newTeams = getState().teams.filter((team) => {
          return team.id !== teamId
        })
        getState().setTeams(newTeams)
        if (getState().selectedTeam?.id === teamId) {
          getState().setSelectedTeam(getState().teams[0] ?? undefined)
        }
      },

      createTeam: async (team: iTeam) => {
        const newTeam = await TeamService.createTeam(team)
        getState().setTeams([...getState().teams, newTeam])
        return newTeam
      },
    }),
    {
      name: 'team-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      partialize: () => ({}),
    },
  ),
)

export default useTeamStore
