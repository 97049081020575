class PromiseQueue {
  private static instance: PromiseQueue
  private queue: (() => Promise<void>)[] = []
  private completionCallbacks: (() => void)[] = []
  private inProgress = false
  private fulfilledCount = 0
  private totalCount = 0

  // Add a flag to keep track of whether the beforeunload event handler is attached
  private beforeUnloadAttached = false

  private constructor() {}

  public static getInstance(): PromiseQueue {
    if (!PromiseQueue.instance) {
      PromiseQueue.instance = new PromiseQueue()
    }
    return PromiseQueue.instance
  }

  /**
   * Enqueue promises, which are fulfilled in background
   * @param promiseFunction Promise function
   */
  enqueue(promiseFunction: () => Promise<void>) {
    this.queue.push(promiseFunction)
    this.totalCount++
    this.processQueue()
  }
  enqueueList(promiseFunctions: (() => Promise<void>)[]) {
    promiseFunctions.forEach((p) => {
      this.totalCount++
      this.queue.push(p)
    })

    this.processQueue()
  }

  public onCompletion(callback: () => void) {
    this.completionCallbacks.push(callback)
  }

  public getFulfilledCount(): number {
    return this.fulfilledCount
  }

  public getTotalCount(): number {
    return this.totalCount
  }

  private processQueue() {
    if (this.inProgress) {
      return
    }
    const nextPromiseFunction = this.queue.shift()
    // console.log("Processing queue");
    // console.log("Queue length: " + this.queue.length);
    // console.log("In progress: " + this.inProgress);
    // console.log("Next promise function type: ", typeof nextPromiseFunction);

    // Attach the beforeunload event listener when starting the queue processing
    if (!this.beforeUnloadAttached) {
      window.addEventListener('beforeunload', this.beforeUnloadHandler)
      this.beforeUnloadAttached = true
    }

    if (nextPromiseFunction) {
      this.inProgress = true
      nextPromiseFunction()
        .then(() => {
          this.fulfilledCount++
          this.inProgress = false
          this.checkQueueCompletion()
          this.processQueue()
        })
        .catch(() => {
          this.inProgress = false
          this.checkQueueCompletion()
          if (this.queue.length > 0) {
            this.processQueue()
          }
        })
    }
  }

  private beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    if (this.queue.length > 0 || this.inProgress) {
      // Set the returnValue to a string to trigger the confirmation dialog
      event.returnValue =
        'Files are still being uploaded. This process will be canceled. Are you sure you want to leave?'
      // Chrome requires returnValue to be set
      return event.returnValue
    }
  }

  /**
   * Reset counts if queue is fulfilled and call completion callbacks
   * @private
   */
  private checkQueueCompletion() {
    if (this.queue.length === 0 && !this.inProgress) {
      this.totalCount = 0
      this.fulfilledCount = 0
      // Execute all registered completion callbacks
      this.completionCallbacks.forEach((callback) => callback())
      // Clear the callbacks after execution to prevent repeated calls
      this.completionCallbacks = []

      if (this.beforeUnloadAttached) {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler)
        this.beforeUnloadAttached = false
      }
    }
  }
}

export default PromiseQueue
