import { createWithEqualityFn } from 'zustand/traditional'
import { iFolder } from '../components/sessionsGroups/interfaces/iFolder.ts'
import FolderService from '../services/folderService.ts'
import SessionState, { getLastModifiedSession } from './sessionState.ts'
import { iSession } from '../components/chatWindow/iSession.ts'

interface iTeamState {
  isLoading: boolean
  folders: iFolder[]
  addFolder: (
    folder: iFolder,
    withoutNewSession?: boolean,
  ) => Promise<iFolder | undefined>
  updateFolder: (folder: iFolder) => Promise<void>
  deleteFolder: (folder: iFolder) => Promise<void>
  getFolders: (options: { teamId?: string }) => Promise<void>
  setIsLoading: (isLoading: boolean) => void
  setFolders: (folders: iFolder[]) => void
}

const useFolderStore = createWithEqualityFn<iTeamState>((set, getState) => ({
  isLoading: false,
  folders: [],
  addFolder: async (folder, withoutNewSession) => {
    try {
      const newFolder = await FolderService.createFolder(folder)
      set(() => ({ folders: [...(getState().folders || []), newFolder] }))
      if (!withoutNewSession) {
        const session: iSession = {
          folderId: newFolder.id!,
          teamId: newFolder.teamId,
          title: 'New Session',
          isActive: false,
          automaticTitle: true,
        }
        await SessionState.getState().addSession(session)
      }
      return newFolder
    } catch (error) {
      console.error(error)
    } finally {
    }
  },
  updateFolder: async (folder: iFolder) => {
    try {
      await FolderService.updateFolder(folder)
      const folders = getState().folders
      const updatedFolders = (folders || []).map((g) =>
        g.id === folder.id ? folder : g,
      )
      set(() => ({ folders: updatedFolders }))
    } catch (error) {
      console.error(error)
    } finally {
    }
  },
  deleteFolder: async (folder: iFolder) => {
    try {
      await FolderService.deleteFolder(folder.id!)
    } catch (error) {
      console.error(error)
    } finally {
      const foldersLeft = getState().folders.filter((g) => g.id !== folder.id)
      getState().setFolders(foldersLeft)
      // check if activeSession is based on deleted folder
      const newSessions = SessionState.getState().removeSessionsByFolder(folder)
      const lastModifiedSession = getLastModifiedSession(newSessions)
      if (lastModifiedSession) {
        await SessionState.getState().activateSession(lastModifiedSession, true)
      } else {
        SessionState.getState().setActiveSession(null)
      }
    }
  },
  getFolders: async ({ teamId }) => {
    let loadedFolders: iFolder[] = []
    getState().setIsLoading(true)
    try {
      loadedFolders = await FolderService.getFolders(teamId)
      set(() => ({ folders: loadedFolders }))
    } catch (error) {
      console.error('Error loadingScreen folders: ', error)
      getState().setIsLoading(false)
    } finally {
      getState().setIsLoading(false)
    }
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setFolders: (folders: iFolder[]) => set(() => ({ folders })),
}))

export default useFolderStore
