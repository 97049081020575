import { useState, useEffect } from 'react'
import { Key } from '@react-types/shared'
import { InputField, TextField, SelectField, FlagField, EnumField } from './model'
import {
  InformationCircleIcon,
  XCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/solid'
import {
  Tooltip,
  Input,
  Textarea,
  Select,
  SelectItem,
  Checkbox,
} from '@nextui-org/react'

export interface iFieldNameProps {
  name: string
  info?: string
}

export function FieldName({ name, info }: iFieldNameProps) {
  return (
    <span className="flex">
      {name}
      {info && (
        <Tooltip content={info} color={'primary'} className={'max-w-96'}>
          <InformationCircleIcon
            className="w-4 h-4 ml-1"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.0}
            stroke="currentColor"
          />
        </Tooltip>
      )}
    </span>
  )
}

export interface iFieldLabel {
  name: string
  info?: string
}

export function FieldLabel({ name, info }: iFieldNameProps) {
  return (
    <label className="label">
      <span className="label-text">
        <FieldName name={name} info={info}></FieldName>
      </span>
    </label>
  )
}

export interface iTextFieldEditorProps {
  field: TextField
  onChange: (field: TextField) => void
}

export function TextFieldEditor({ field, onChange }: iTextFieldEditorProps) {
  return (
    <div className="mt-4">
      {field.lines && field.lines > 1 ? (
        <Textarea
          value={field.value}
          label={<FieldName name={field.name} info={field.info}></FieldName>}
          onChange={(e) => {
            onChange(new TextField({ ...field, value: e.target.value }))
          }}
          rows={field.lines}
        />
      ) : (
        <>
          <Input
            type={field.isSecret ? 'password' : 'text'}
            label={<FieldName name={field.name} info={field.info}></FieldName>}
            value={field.value}
            onChange={(e) =>
              onChange(new TextField({ ...field, value: e.target.value }))
            }
          />
        </>
      )}
    </div>
  )
}

export interface iSelectFieldEditorProps {
  field: SelectField
  onChange: (field: SelectField) => void
}

export function SelectFieldEditor({ field, onChange }: iSelectFieldEditorProps) {
  return (
    <div className="mt-4 flex">
      <FieldLabel name={field.name} info={field.info}></FieldLabel>
      {
        <Select
          className="w-1/2 max-w-xs ml-auto"
          selectedKeys={[field.value.key]}
          items={field.options}
          onChange={(e) =>
            onChange(
              new SelectField({
                ...field,
                value: field.options.find((opt) => opt.key === e.target.value)!,
              }),
            )
          }
        >
          {(item) => (
            <SelectItem key={item.key} value={item.key} textValue={item.display}>
              {item.display || item.key}
            </SelectItem>
          )}
        </Select>
      }
    </div>
  )
}

export interface iFlagFieldEditorProps {
  field: FlagField
  onChange: (field: FlagField) => void
}

export function FlagFieldEditor({ field, onChange }: iFlagFieldEditorProps) {
  return (
    <div className="mt-4 flex">
      <Checkbox
        isSelected={field.value}
        onValueChange={(value) => onChange(new FlagField({ ...field, value }))}
      />
      <FieldLabel name={field.name} info={field.info}></FieldLabel>
    </div>
  )
}

export interface iEnumFieldEditorProps {
  field: EnumField
  onChange: (field: EnumField) => void
}

export function EnumFieldEditor({ field, onChange }: iEnumFieldEditorProps) {
  const addValue = (value: string) => {
    field.values.push(value)
    onChange(field)
  }

  const removeValue = (index: number) => {
    field.values.splice(index, 1)
    onChange(field)
  }

  const changeValue = (index: number, value: string) => {
    field.values[index] = value
    onChange(field)
  }

  return (
    <div className="mt-4 w-full">
      <FieldLabel name={field.name} info={field.info}></FieldLabel>
      {field.values.map((value, index) => (
        <span style={{ whiteSpace: 'nowrap' }} key={index}>
          <XCircleIcon
            className="w-6 h-6 inline cursor-pointer"
            onClick={() => removeValue(index)}
          ></XCircleIcon>
          <input
            className="input mb-2 w-1/4"
            type="text"
            value={value}
            onChange={(e) => changeValue(index, e.target.value)}
          />
        </span>
      ))}
      <PlusCircleIcon
        onClick={(e) => addValue('')}
        className="w-8 h-8 inline cursor-pointer"
      ></PlusCircleIcon>
    </div>
  )
}

interface iFieldsEditorProps {
  fields: InputField[]
  onChange(fields: InputField[]): void
  hide?: string[]
}

export function FieldsEditor({ fields, onChange, hide }: iFieldsEditorProps) {
  const [currentFields, setCurrentFields] = useState<any[]>(fields)

  function handleChange(index: number, newField: InputField) {
    setCurrentFields(
      currentFields.map((field, i) => (i === index ? newField : field)),
    )
  }

  useEffect(() => onChange(currentFields), [currentFields])

  return (
    <div>
      {currentFields.map(
        (field, index) =>
          hide?.includes(field.key) ||
          (field instanceof TextField && (
            <TextFieldEditor
              key={index}
              field={field as TextField}
              onChange={(newField: TextField) => handleChange(index, newField)}
            />
          )) ||
          (field instanceof FlagField && (
            <FlagFieldEditor
              key={index}
              field={field as FlagField}
              onChange={(newField) => handleChange(index, newField)}
            />
          )) ||
          (field instanceof SelectField && (
            <SelectFieldEditor
              key={index}
              field={field as SelectField}
              onChange={(newField) => handleChange(index, newField)}
            />
          )) ||
          (field instanceof EnumField && (
            <EnumFieldEditor
              key={index}
              field={field as EnumField}
              onChange={(newField) => handleChange(index, newField)}
            />
          )) || <div>Unknown field type</div>,
      )}
    </div>
  )
}
