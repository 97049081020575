import React from 'react'
import GaiaLogo from '../components/basic/logo/gaiaLogo.tsx'
import { Button } from '@nextui-org/react'
import { Link } from 'react-router-dom'

interface NotFoundPageProp {
  theme: string
}

function NotFoundPage({ theme }: NotFoundPageProp) {
  return (
    <div
      className={'fix flex-col flex justify-center items-center w-[100vw] h-[100vh]'}
    >
      <GaiaLogo className={'w-44 h-44'} />
      <h1 className={'gaia-fade-in font-bold text-7xl'}>GAIA</h1>
      <div className={'mt-5 text-5xl'}>404</div>
      <Link to="/" className={'mt-5'}>
        <Button>Go Home</Button>
      </Link>
    </div>
  )
}

export default NotFoundPage
