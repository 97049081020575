import { useEffect, useState, useRef } from 'react'
import JSONFormatter from 'json-formatter-js'

interface iJsonDisplayProps {
  jsonString?: string
  jsonData?: any
}

export function JsonDisplay({ jsonString, jsonData }: iJsonDisplayProps) {
  const container = useRef<HTMLDivElement>(null)

  const [text, setText] = useState<string | null>(null)
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    if (jsonData === undefined && jsonString === undefined) {
      return
    }

    if (jsonData !== undefined) {
      setData(jsonData)
      return
    }

    let parsed: string | null
    try {
      parsed = JSON.parse(jsonString || '')
      setData(parsed)
    } catch (e) {
      setText(jsonString || '')
    }
  }, [])

  useEffect(() => {
    if (!container.current) {
      return
    }
    container.current.innerHTML = ''
    if (!data && !text) {
      return
    }
    if (!data && text) {
      container.current.appendChild(document.createTextNode(text))
      return
    }
    const formatter = new JSONFormatter(data, Infinity, {
      theme: 'light',
    })
    container.current.appendChild(formatter.render())
    container.current.querySelector('.json-formatter-toggler-link')?.remove()
  }, [data, text])

  return <>{(data && <div ref={container} />) || (text && <pre>{text}</pre>)}</>
}
