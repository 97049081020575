import React from 'react'
import './sessions.css'
import ProfileImage from './profileImage/profileImage.tsx'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import { Popup } from '../sessionControls/contextPopup.tsx'
import { deleteTokens, getUser, logoutURL } from '../login/authentication.tsx'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from '@nextui-org/react'
import {
  ArrowRightEndOnRectangleIcon,
  CloudArrowUpIcon,
  KeyIcon,
  PuzzlePieceIcon,
  ShieldExclamationIcon,
  SwatchIcon,
} from '@heroicons/react/24/outline'

const AccountMenu = () => {
  const { setPopup } = useMain()

  const logout = () => {
    deleteTokens()
    document.location.href = logoutURL
  }

  const { email, displayName, gaiaRole } = getUser()
  return (
    <Dropdown>
      <DropdownTrigger className={'h-18'}>
        <Button variant={'light'} className={'w-full justify-start py-4 truncate'}>
          <ProfileImage width={8} contact={email} />
          <div className="flex flex-col items-start truncate">
            <span className="text-small bold truncate  max-w-full">
              {displayName}
            </span>
            <span className="text-tiny align-start text-default-400 max-w-full">
              {gaiaRole}
            </span>
            <span className="text-tiny text-default-400 truncate max-w-full">
              {email}
            </span>
          </div>
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions">
        <DropdownSection showDivider>
          <DropdownItem
            textValue={'KnowledgeContainers'}
            startContent={<CloudArrowUpIcon className={'w-5 h-5'} />}
            onClick={() => setPopup(Popup.Container)}
          >
            <a>Your Knowledge Containers</a>
          </DropdownItem>
          <DropdownItem
            textValue={'Tools'}
            startContent={<PuzzlePieceIcon className={'w-5 h-5'} />}
            onClick={() => setPopup(Popup.Tools)}
          >
            <a>Your Tools</a>
          </DropdownItem>
          <DropdownItem
            textValue={'Assistants'}
            startContent={<SwatchIcon className={'w-5 h-5'} />}
            onClick={() => setPopup(Popup.AssistantContainer)}
          >
            <a>Your Assistants</a>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection showDivider>
          <DropdownItem
            textValue={'Secrets'}
            startContent={<KeyIcon className={'w-5 h-5'} />}
            onClick={() => setPopup(Popup.Secrets)}
          >
            <a>Secrets</a>
          </DropdownItem>
          <DropdownItem
            textValue={'Personal access tokens'}
            startContent={<ShieldExclamationIcon className={'w-5 h-5'} />}
            onClick={() => setPopup(Popup.PatKeys)}
          >
            <a>Personal access tokens</a>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownItem
            textValue={'logout'}
            startContent={<ArrowRightEndOnRectangleIcon className={'w-5 h-5'} />}
            onClick={logout}
          >
            <a>Logout</a>
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AccountMenu
