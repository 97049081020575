import {
  BlobUploadEvents,
  ContainerType,
  EprogressUpdateStatus,
  iBlobDictionary,
  iBlobList,
  iBlobManageFiles,
  newStatus,
  savingStatus,
} from './iBlobUpload.tsx'
import React, { Key, useEffect, useRef, useState } from 'react'
import KnowledgeContainerService from '../../../services/knowledgeContainerService.ts'
import {
  ArrowUturnLeftIcon,
  FolderIcon,
  PaperClipIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import FilePicker from '../files/filePicker.tsx'
import {
  CustomFile,
  CustomFileSystemEntry,
  processDirectory,
} from '../files/processDirectory.tsx'
import './knowledgeContainerStyle.css'
import PromiseQueue from '../../utils/promiseQueue.tsx'

import { iProgressMessage } from './iProgressMessage.ts'
import { Button, ModalBody, ModalFooter, ModalHeader } from '@nextui-org/react'
import useSignalRStore from '../../../stateManagement/signalRState.ts'
import { FileIcon } from 'react-file-icon'
import { shallow } from 'zustand/shallow'

// list of compiled files

export const BLACKLISTEDEXTENSIONS = [
  'exe',
  'dll',
  'dmg',
  'iso',
  'img',
  'msi',
  'msp',
  'deb',
  'rpm',
  'rar',
  '7z',
  'tar',
  'gz',
  'bz2',
  'xz',
  'lz',
  'lzma',
  'cab',
  'jar',
  'war',
  'ear',
  'sar',
  'rar',
  'alz',
  'ace',
  'z',
  'cpio',
  'arj',
  'wim',
  'swm',
  'dwm',
  'esd',
  'sfx',
  'lz',
  'lzh',
  'iso',
  'isz',
  'bzip2',
  'z',
  'tz',
  'rz',
  'tbz2',
  'tbz',
  'lzma',
  'tlz',
  'txz',
  'taz',
  'zipx',
  'sit',
  'sitx',
  'gz',
  'tgz',
  'tpz',
  'lz',
  'lzh',
  '7z',
  'rar',
  'gzip',
  'bzip2',
  'bzip',
  'tbz',
  'tbz2',
  'xz',
  'txz',
  'lzma',
  'tlz',
  'lz',
  'lzo',
  'rz',
  'egg',
  'jar',
  'war',
  'ear',
  'sar',
  'rar',
  'alz',
  'ace',
  'z',
  'cpio',
  'arj',
  'wim',
  'swm',
  'dwm',
  'esd',
  'sfx',
  'lz',
  'lzh',
  'iso',
  'isz',
  'bzip2',
  'z',
  'tz',
  'rz',
  'tbz2',
  'tbz',
  'lzma',
  'tlz',
  'txz',
  'taz',
  'zipx',
  'sit',
  'sitx',
  'gz',
  'tgz',
  'tpz',
  'lz',
  'lzh',
  '7z',
  'rar',
  'gzip',
  'bzip2',
  'bzip',
  'tbz',
  'tbz2',
  'xz',
  'txz',
  'lzma',
  'tlz',
  'lz',
  'lzo',
  'Z',
  'rz',
  'egg',
  'jar',
  'war',
  'ear',
  'sar',
  'rar',
  'alz',
  'ace',
  'z',
  'cpio',
  'arj',
  'wim',
  'swm',
  'dwm',
  'esd',
  'sfx',
  'mp4',
  'avi',
  'wmv',
  'mov',
  'mkv',
  'flv',
  'f4v',
  'm4v',
  'rmvb',
  'rm',
  '3gp',
  'dat',
  'vob',
  'mpg',
  'mp3',
  'wav',
  'wma',
  'ogg',
  'ape',
  'flac',
  'aac',
  'ac3',
  'aiff',
  'm4a',
  'mka',
  'm3u',
  'm3u8',
  'gif',
  'ds_store',
]

function BlobManageFiles({ containerid, closeFunction }: iBlobManageFiles) {
  const connection = useSignalRStore((state) => state.connection, shallow)
  const [structure, setStructure] = useState<iBlobList>()
  const [fileDictionary, setFileDictionary] = useState<iBlobDictionary>({})
  const [selectToBeDeletedFiles, setSelectToBeDeletedFiles] = useState<iBlobList[]>(
    [],
  )
  const [isDragging, setIsDragging] = useState(false)
  const [isDraggingTree, setIsDraggingTree] = useState('')
  const [lineHovered, setLineHovered] = useState('')
  // const [savingMax, setSavingMax] = useState(0);
  // const [savingProgress, setSavingProgress] = useState(0);
  // const [currentOperation, setCurrentOperation] = useState('');
  // const [processedFile, setProcessedFile] = useState('');
  const [saving, setSaving] = useState(false)
  const deletingListRef = useRef<iBlobList[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  // saving progress states: uploading, embeddings, indexing, deleting
  // const [savingProgress, setSavingProgress] = useState(savingStatus.None);

  const [uploadingStatus, setUploadingStatus] = useState(savingStatus.None)
  const [uploadingProgress, setUploadingProgress] = useState(0)
  const [uploadingMax, setUploadingMax] = useState(0)
  const [uploadingProcessedFile, setUploadingProcessedFile] = useState('')

  const [analyzingStatus, setAnalyzingStatus] = useState(savingStatus.None)
  const [analyzingProgress, setAnalyzingProgress] = useState(0)
  const [analyzingMax, setAnalyzingMax] = useState(0)
  const [analyzingProcessedFile, setAnalyzingProcessedFile] = useState('')

  const [indexingStatus, setIndexingStatus] = useState(savingStatus.None)
  const [indexingProgress, setIndexingProgress] = useState(0)
  const [indexingMax, setIndexingMax] = useState(0)
  const [indexingProcessedFile, setIndexingProcessedFile] = useState('')

  const [deletingStatus, setDeletingStatus] = useState(savingStatus.None)
  const [deletingProgress, setDeletingProgress] = useState(0)
  const [deletingMax, setDeletingMax] = useState(0)

  // constructor
  useEffect(() => {
    getBlobTree()
  }, [])

  useEffect(() => {
    if (connection) {
      connection.on(
        BlobUploadEvents.uploading,
        (progressMessage: iProgressMessage) => {
          switch (progressMessage.status) {
            case 'start':
              setUploadingProgress(progressMessage.progress)
              setUploadingMax(progressMessage.max)
              setUploadingStatus(savingStatus.Uploading)
              break
            case 'uploading':
              setUploadingProgress(progressMessage.progress)
              setUploadingProcessedFile(progressMessage.message)
              break
            case 'completed':
              setUploadingStatus(savingStatus.Completed)
              break
          }
        },
      )
      connection.on(
        BlobUploadEvents.indexing,
        (progressMessage: iProgressMessage) => {
          switch (progressMessage.status) {
            case 'start':
              setIndexingProgress(progressMessage.progress)
              setIndexingMax(progressMessage.max)
              setIndexingStatus(savingStatus.Indexing)
              break
            case 'indexing':
              setIndexingProgress(progressMessage.progress)
              setIndexingProcessedFile(progressMessage.message)
              break
            case 'completed':
              setIndexingStatus(savingStatus.Completed)
              break
          }
        },
      )
      connection.on(
        BlobUploadEvents.analyzing,
        (progressMessage: iProgressMessage) => {
          switch (progressMessage.status) {
            case 'start':
              setAnalyzingProgress(progressMessage.progress)
              setAnalyzingMax(progressMessage.max)
              setAnalyzingStatus(savingStatus.Analyzing)
              break
            case 'analyzing':
              setAnalyzingProgress(progressMessage.progress)
              setAnalyzingProcessedFile(progressMessage.message)
              break
            case 'completed':
              setAnalyzingStatus(savingStatus.Completed)
              break
          }
        },
      )
    }
  }, [connection])

  /**
   * Gets the blob tree from the backend
   */
  const getBlobTree = () => {
    setLoading(true)
    KnowledgeContainerService.getBlobTree(containerid).then((response) => {
      cleanStructure(response)
      setStructure(response)
      const files: iBlobDictionary = {}
      flatStructure(response.children, files)
      setFileDictionary(files)
      setLoading(false)
    })
  }

  const flatStructure = (structure: iBlobList[], files: iBlobDictionary) => {
    for (const item of structure) {
      if (item.type === 1) {
        files[item.node] = item
      } else if (item.children.length > 0) {
        flatStructure(item.children, files)
      }
    }
  }

  /**
   * Checks if there are any changes in the tree structure
   */
  const hasChanges = () => {
    return (
      Object.values(fileDictionary).some((value) => {
        return (
          value.newStatus === newStatus.New ||
          value.newStatus === newStatus.Updated ||
          value.newStatus === newStatus.Deleted
        )
      }) || selectToBeDeletedFiles.length > 0
    )
  }

  /**
   * Removes all ending slashes form the nodes
   * @param structure
   */
  const cleanStructure = (structure: iBlobList) => {
    // remove the last '/' from the path if it is not the root
    if (structure.node.endsWith('/') && structure.node.length > 1) {
      structure.node = structure.node.substring(0, structure.node.length - 1)
    }
    structure.children.forEach((child) => cleanStructure(child))
  }

  const saveAndUpload = async () => {
    setSaving(true)

    // Update the UI with the new file statuses
    // Updated as soon everything is done
    PromiseQueue.getInstance().onCompletion(() => {
      finishSaveProcess()
    })

    // upload all files with status New or Updated
    // filter the fileDictionary
    const filesToUpload = Object.values(fileDictionary)
      .filter((value) => {
        return (
          value.newStatus === newStatus.New || value.newStatus === newStatus.Updated
        )
      })
      .map((blobList) => blobList.file!)
      .filter(Boolean) as File[]
    // filter all directories from selectToBeDeletedFiles
    const selectToBeDeletedFilesFiltered = selectToBeDeletedFiles.filter(
      (file) => file.type === ContainerType.File,
    )

    // upload the files
    if (filesToUpload.length > 0) {
      setUploadingMax(Object.keys(filesToUpload).length)
      setUploadingProgress(0)
      setUploadingStatus(savingStatus.Uploading)

      setIndexingMax(Object.keys(filesToUpload).length)
      setIndexingProgress(0)
      setIndexingStatus(savingStatus.Indexing)

      console.debug('Uploading files: ', filesToUpload)

      PromiseQueue.getInstance().enqueueList(
        KnowledgeContainerService.uploadBlobs(containerid, filesToUpload),
      )
    }

    // delete all files with status Deleted
    if (selectToBeDeletedFilesFiltered.length > 0) {
      setDeletingMax(selectToBeDeletedFilesFiltered.length)
      setDeletingProgress(0)
      setDeletingStatus(savingStatus.Deleting)
      selectToBeDeletedFilesFiltered.forEach((file) => {
        PromiseQueue.getInstance().enqueue(() =>
          deleteProcess(containerid, file.node),
        )
      })
    }
  }

  /**
   * When the saving process is finished, reset the saving states, get the new blob tree
   */
  const finishSaveProcess = () => {
    // setSavingProgress(savingStatus.Completed);
    setUploadingStatus(savingStatus.None)
    setIndexingStatus(savingStatus.None)
    setAnalyzingStatus(savingStatus.None)
    setDeletingStatus(savingStatus.None)

    setDeletingMax(0)
    setDeletingProgress(0)
    setUploadingMax(0)
    setUploadingProgress(0)
    setIndexingMax(0)
    setIndexingProgress(0)
    setAnalyzingMax(0)
    setAnalyzingProgress(0)

    // Finalize the process and update the UI or state as needed
    setSaving(false)
    getBlobTree()
    setSelectToBeDeletedFiles([])
  }

  /**
   * When the deletingProgress is equal to the deletingMax, the deleting is completed
   * Since deleting is the last step, we can finish the process
   */
  useEffect(() => {
    if (deletingMax !== 0 && deletingProgress === deletingMax) {
      setDeletingStatus(savingStatus.Completed)
    }
  }, [deletingProgress])

  useEffect(() => {
    if (uploadingMax !== 0 && uploadingProgress === uploadingMax) {
      setUploadingStatus(savingStatus.Completed)
    }
  }, [uploadingProgress])

  useEffect(() => {
    if (analyzingMax !== 0 && analyzingProgress === analyzingMax) {
      setAnalyzingStatus(savingStatus.Completed)
    }
  }, [analyzingProgress])

  useEffect(() => {
    if (indexingMax !== 0 && indexingProgress === indexingMax) {
      setIndexingStatus(savingStatus.Completed)
    }
  }, [indexingProgress])

  /**
   * Deletes a file from the blob storage, and updates the progress
   * @param containerid
   * @param node
   */
  const deleteProcess = async (containerid: string, node: string) => {
    await KnowledgeContainerService.deleteBlobFile(containerid, node)
    setDeletingProgress((prevProgress) => prevProgress + 1)
  }

  const markAsDeleted = (blobList: iBlobList) => {
    blobList.newStatus = newStatus.Deleted
    blobList.children.forEach((child) => markAsDeleted(child))

    if (!selectToBeDeletedFiles.includes(blobList)) {
      deletingListRef.current.push(blobList)
      setSelectToBeDeletedFiles(deletingListRef.current)
    }

    setStructure(structure)
  }

  const onDragOver = (
    event: React.DragEvent<HTMLElement>,
    blobList: iBlobList | undefined = undefined,
  ) => {
    event.preventDefault()
    if (blobList) {
      setIsDraggingTree(blobList.node)
      return
    }
    if (!isDragging) {
      setIsDragging(true)
    }
  }

  const onDragLeave = (
    event: React.DragEvent<HTMLElement>,
    blobList: iBlobList | undefined = undefined,
  ) => {
    if (blobList) {
      setIsDraggingTree('')
      return
    }
    setIsDragging(false)
  }

  /**
   * Event handler for when a file or folder is dropped
   * @param event
   * @param blobList current folder in the tree or root level
   */
  const onDrop = async (
    event: React.DragEvent<HTMLElement>,
    blobList: iBlobList,
  ) => {
    event.preventDefault()
    setIsDragging(false)
    setIsDraggingTree('')

    const items = event.dataTransfer.items
    const processedFiles: File[] = []

    const queue: Promise<File | void>[] = []
    for (const item of items) {
      const entry = item.webkitGetAsEntry()! as CustomFileSystemEntry
      // the path where the file/s are dropped
      const path = blobList.node
      if (entry.isDirectory) {
        queue.push(
          processDirectory(entry, path).then((files) => {
            processedFiles.push(...files)
          }),
        )
      } else if (entry.isFile) {
        // get the file extension
        const extension = entry.name.split('.').pop()
        // check if the file is blacklisted
        if (extension && BLACKLISTEDEXTENSIONS.includes(extension!.toLowerCase())) {
          console.warn('File is blacklisted: ', entry.name)
          continue
        }
        queue.push(
          new Promise<File>((resolve) => entry.file(resolve)).then((file) => {
            // set the webkitRelativePath with custom file
            const cfile = new CustomFile(
              [file],
              entry.name,
              { type: file.type },
              concatPaths(path, entry.name),
            )
            processedFiles.push(cfile as File)
          }),
        )
      }
    }
    await Promise.all(queue)

    const fileList = Object.setPrototypeOf(processedFiles, FileList.prototype)
    handleFilesSelect(fileList)
  }

  const removeLeadingSlash = (path: string) => {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    return path
  }

  /**
   * Concats two string paths together, so there is only one '/' between them
   * @param string1
   * @param string2
   */
  const concatPaths = (string1: string, string2: string) => {
    if (string1 === '' || string1 === '/') {
      // if string2 starts with a slash, remove it
      if (string2.startsWith('/')) {
        string2 = string2.substring(1)
      }
      return string2
    }

    // Ensure that string1 does not end with a slash
    if (string1.endsWith('/')) {
      string1 = string1.slice(0, -1)
    }
    // Ensure that string2 does not start with a slash
    if (string2.startsWith('/')) {
      string2 = string2.slice(1)
    }
    // Now it's safe to concatenate with a single slash
    return string1 + '/' + string2
  }

  /**
   * Sorts the dropped files into the folder structure
   * @param files dropped files
   */
  const handleFilesSelect = (files: FileList) => {
    if (!structure) {
      return
    }
    const filesArray = Array.from(files)

    // add the new files into the structure, respecting the folder structure
    const newStructure = { ...structure }
    filesArray.forEach((file) => {
      // if the file already exists, update it
      const existingFile =
        fileDictionary[
          file.webkitRelativePath.length > 0
            ? removeLeadingSlash(file.webkitRelativePath)
            : file.name
        ]
      if (existingFile) {
        existingFile.file = file
        existingFile.newStatus = newStatus.Updated
        // set lastModified status to now
        existingFile.lastModified = new Date().toISOString()
        return
      }

      // add the file recursively to the structure
      const path =
        file.webkitRelativePath.length > 0 ? file.webkitRelativePath : file.name
      const pathParts = path.split('/')
      pathParts.pop()
      const fileName = file.name
      let currentFolderChildren = newStructure.children
      let currentFolder = newStructure
      pathParts.forEach((part) => {
        if (part === '') return
        let existingNode = currentFolderChildren.find(
          (node) => concatPaths(currentFolder.node, part) === node.node,
        )
        if (!existingNode) {
          // create a new folder
          existingNode = {
            node: concatPaths(currentFolder.node, part),
            // name: part,
            type: 0,
            lastModified: '',
            size: 0,
            children: [],
            showChildren: true,
            file: null,
            newStatus: newStatus.New,
          }
          currentFolderChildren.push(existingNode)
        }
        currentFolderChildren = existingNode.children
        currentFolder = existingNode
      })

      // add the file to the deepest folder
      const newObject = {
        node: path,
        name: fileName!,
        type: 1,
        lastModified: '',
        size: 0,
        children: [],
        showChildren: false,
        selected: false,
        file: file,
        newStatus: newStatus.New,
      }
      currentFolderChildren.push(newObject)
      // add the file to the dictionary for fast lookup
      fileDictionary[path] = newObject
    })
    setFileDictionary(fileDictionary)
    setStructure(newStructure)
  }

  /**
   * Toggles the folder open/close in the tree
   * @param node  folder to toggle
   */
  const toggleFolder = (node: iBlobList) => {
    node.showChildren = !node.showChildren
    setStructure(structure)
  }

  const getNewStatus = (node: iBlobList) => {
    switch (node.newStatus) {
      case newStatus.New:
        return 'new'
      case newStatus.Deleted:
        return 'deleted'
      case newStatus.Updated:
        return 'updated'
      default:
        return ''
    }
  }

  const getFileName = (nodePath: string) => {
    const parts = nodePath.split('/')
    return parts[parts.length - 1]
  }

  const getFolderName = (nodePath: string) => {
    if (nodePath === '/') return 'root'
    // remove leading / and trailing /
    if (nodePath.startsWith('/')) {
      nodePath = nodePath.substring(1)
    }
    if (nodePath.endsWith('/')) {
      nodePath = nodePath.substring(0, nodePath.length - 1)
    }
    const parts = nodePath.split('/')

    return parts[parts.length - 1]
  }

  const handleClose = () => {
    if (hasChanges()) {
      if (
        window.confirm('You have unsaved changes. Are you sure you want to cancel?')
      ) {
        closeFunction()
      }
    } else {
      closeFunction()
    }
  }

  const handleLineMouseEnter = (node: iBlobList) => {
    setLineHovered(node.node)
  }
  const handleLineMouseLeave = (node: iBlobList) => {
    if (lineHovered === node.node) setLineHovered('')
  }

  const reverseAction = (node: iBlobList) => {
    node.children.forEach((child) => reverseAction(child))
    // if new, delete
    if (node.newStatus === newStatus.New) {
      // delete from fileDictionary
      delete fileDictionary[node.node]
      setFileDictionary(fileDictionary)
      node.newStatus = newStatus.None
      node.file = null
    }
    // if deleted, existing
    if (node.newStatus === newStatus.Deleted) {
      node.newStatus = newStatus.Existing
      setFileDictionary(fileDictionary)
      // remove from
      selectToBeDeletedFiles.splice(selectToBeDeletedFiles.indexOf(node), 1)
      setSelectToBeDeletedFiles([...selectToBeDeletedFiles])
    }
    // if updated, existing
    if (node.newStatus === newStatus.Updated) {
      node.newStatus = newStatus.Existing
      node.file = null
      setFileDictionary(fileDictionary)
    }

    setStructure(structure)
  }

  /**
   * Renders a node in the tree, recursively
   * @param node
   */
  const renderNode = (node: iBlobList) => {
    const isFolder = node.children.length > 0
    const key: Key = node.node
    if (node.newStatus === newStatus.None) return null

    return (
      <React.Fragment key={key}>
        {isFolder ? (
          <li>
            <details open>
              <summary
                onMouseEnter={() => handleLineMouseEnter(node)}
                onMouseLeave={() => handleLineMouseLeave(node)}
                className={` ${isDraggingTree === node.node ? 'text-secondary bg-white' : ''}`}
                onDragOver={(event) => onDragOver(event, node)}
                onDragLeave={(event) => onDragLeave(event, node)}
                onDrop={(event) => onDrop(event, node)}
              >
                <span className={'flex justify-between col-start-1 col-end-6'}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FolderIcon className={'h-4 w-4'} />
                    <span
                      className={`ml-2 ${node.newStatus === newStatus.Deleted ? 'line-through text-rose-600' : ''} 
                                    ${node.newStatus === newStatus.New ? 'text-lime-600 font-semibold' : ''}
                                    ${node.newStatus === newStatus.Updated ? 'text-amber-500 font-semibold' : ''}
                                    `}
                    >
                      {getFolderName(node.node)}
                    </span>
                    <span className={'text-xs italic ml-2'}>
                      {getNewStatus(node)}
                    </span>
                  </div>
                  {lineHovered === node.node && node.node !== '/' && (
                    <div className={'height16'}>
                      {node.newStatus >= newStatus.New && (
                        <button className="btn btn-ghost btn-xs btn-circle marginMinus mr-2 height16">
                          <ArrowUturnLeftIcon
                            className="h-4 w-4"
                            onClick={() => reverseAction(node)}
                          />
                        </button>
                      )}
                      {node.newStatus !== newStatus.New && (
                        <button
                          className="btn btn-ghost btn-xs btn-circle marginMinus mr-2 height16"
                          onClick={() => markAsDeleted(node)}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </button>
                      )}
                    </div>
                  )}
                </span>
              </summary>
              <ul>{node.children.map((child) => renderNode(child))}</ul>
            </details>
          </li>
        ) : (
          <div>
            <li
              onMouseEnter={() => handleLineMouseEnter(node)}
              onMouseLeave={() => handleLineMouseLeave(node)}
            >
              <span className={'flex justify-between'}>
                <a style={{ display: 'flex', alignItems: 'center' }}>
                  {/*<input id={key + "id"} type="checkbox" className="checkbox checkbox-xs checkbox-primary" checked={checked} onChange={handleCheckboxTick(node)} />*/}
                  <div className={'w-4 h-4'}>
                    <FileIcon extension={getFileName(node.node).split('.').pop()} />
                  </div>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg> */}
                  <span
                    className={`ml-2 ${node.newStatus === newStatus.Deleted ? 'line-through text-rose-600' : ''} 
                                    ${node.newStatus === newStatus.New ? 'text-lime-600 font-semibold' : ''}
                                    ${node.newStatus === newStatus.Updated ? 'text-primary font-semibold' : ''}
                                    `}
                  >
                    {getFileName(node.node)}
                  </span>
                  <span className={'text-xs italic ml-2'}>{getNewStatus(node)}</span>
                </a>
                {lineHovered === node.node && (
                  <div className={'mr-4 height16'}>
                    {node.newStatus >= newStatus.New && (
                      <button
                        className="btn btn-ghost btn-xs btn-circle marginMinus mr-2 height16"
                        onClick={() => reverseAction(node)}
                      >
                        <ArrowUturnLeftIcon className="h-4 w-4" />
                      </button>
                    )}
                    {node.newStatus !== newStatus.New &&
                      node.newStatus !== newStatus.Deleted && (
                        <button
                          className="btn btn-ghost btn-xs btn-circle marginMinus mr-2 height16"
                          onClick={() => markAsDeleted(node)}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </button>
                      )}
                  </div>
                )}
              </span>
            </li>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>Knowledge Container</h1>
      </ModalHeader>
      <ModalBody>
        <>
          <p className={''}>
            Use your files and upload them to your blob storage. They will be indexed
            and can be used as context.
          </p>
          <p>You can drag an drop into the folder structure.</p>
          <SupportedFiles />
          <div
            className={`w-full h-40 bg-gray-200 border-dashed border-2 border-gray-400 flex items-center justify-center 
        ${isDragging ? 'border-blue-500' : ''}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={(event) => onDrop(event, structure!)}
          >
            <div className={'text-center items-center justify-center'}>
              <div className={'flex items-center justify-center'}>
                <PaperClipIcon className={'h-8 w-8 text-gray-400'} />
                <p>Drag and drop files/folders here</p>
              </div>
              <p>or</p>
              <FilePicker onFilesSelect={handleFilesSelect} forFiles={true} />
            </div>
          </div>
          {(loading && (
            <div className={'grid grid-cols-1 h-20'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )) ||
            (structure && structure.children.length > 0 && (
              <>
                <div className="mt-3">
                  <div className="overflow-auto no-scrollbar">
                    <ul className="menu menu-xs bg-base-200 rounded-lg w-full max-h-80 overflow-auto flow-root">
                      {renderNode(structure)}
                    </ul>
                  </div>
                </div>
              </>
            ))}
        </>
        <div className={'text-center mt-2'}>
          {uploadingStatus === savingStatus.Uploading && (
            <>
              <p>Uploading files</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={uploadingProgress}
                max={uploadingMax}
              />
            </>
          )}

          {uploadingStatus === savingStatus.Completed && (
            <p>Uploading files completed</p>
          )}

          {/*          {indexingStatus === savingStatus.Indexing && (
            <>
              <p>Indexing files</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={indexingProgress}
                max={indexingMax}
              />
            </>
          )}

          {indexingStatus === savingStatus.Completed && (
            <p>Indexing files completed</p>
          )}*/}

          {analyzingStatus === savingStatus.Analyzing && (
            <>
              <p>Indexing files</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={analyzingProgress}
                max={analyzingMax}
              />
            </>
          )}

          {indexingStatus === savingStatus.Completed && (
            <p>Indexing files completed</p>
          )}

          {deletingStatus === savingStatus.Deleting && (
            <>
              <p>Deleting files</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={deletingProgress}
                max={deletingMax}
              />
            </>
          )}

          {deletingStatus === savingStatus.Completed && (
            <p>Deleting files completed</p>
          )}

          {uploadingStatus === savingStatus.Uploading ||
          indexingStatus === savingStatus.Indexing ||
          deletingStatus === savingStatus.Deleting ? (
            <p>You may close this window but not the tab.</p>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <>
          {hasChanges() && !saving ? (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color={'primary'} className="ml-3" onClick={saveAndUpload}>
                Save
              </Button>
            </>
          ) : (
            <Button onClick={closeFunction}>Close</Button>
          )}
        </>
      </ModalFooter>
    </>
  )
}

function SupportedFiles() {
  const [show, setShow] = useState(false)

  if (show)
    return (
      <>
        <div
          className={'text-[#47b3ff] cursor-pointer'}
          onClick={() => setShow(false)}
        >
          Collapse list of supported files
        </div>

        <div>
          CSV, EPUB, GZ, HTML, JSON, KML, Microsoft Office formats, Open Document
          formats, PDF, Plain text files, RTF, XML, ZIP
        </div>
      </>
    )
  else
    return (
      <>
        <div
          className={'text-[#47b3ff] cursor-pointer'}
          onClick={() => setShow(true)}
        >
          Expand list of supported files
        </div>
      </>
    )
}

export default BlobManageFiles
