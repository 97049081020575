import React, { useState, useEffect } from 'react'
import { iTeam } from '../sessionControls/userManagement/iTeam.tsx'
import TeamService from '../../services/teamService.ts'
import { ArrowPathIcon, ClipboardIcon, TrashIcon } from '@heroicons/react/24/outline'
import patService from '../../services/patService.ts'
import { iPat } from './iPat.ts'
import MarkdownPreview from '@uiw/react-markdown-preview'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
interface iPatManagerProps {
  onClose: () => void
}
import usageExampleContent from '../../assets/pat-usage-examples.md'
import CopyToClipboard from './copyToClipboard.tsx'

interface iPatKeys {
  [key: string]: iPat
}

function PatManager({ onClose }: iPatManagerProps) {
  // patkeys is a dict with the teamId as key and the pat as value

  const [patKeys, setPatKeys] = useState<iPatKeys>({})
  const [teams, setTeams] = useState<iTeam[]>([])
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [patLoading, setPatLoading] = useState(false)

  useEffect(() => {
    setPatLoading(true)
    TeamService.getTeams(false).then((teams) => {
      setTeams(teams.filter((team) => team.apiAccess))
    })
  }, [])

  useEffect(() => {
    patService.getPats().then((pats) => {
      pats.forEach((pat) => {
        setPatKeys((prevPatKeys) => {
          const newPatKeys = { ...prevPatKeys }
          newPatKeys[pat.teamId] = pat
          return newPatKeys
        })
      })
      setPatLoading(false)
    })
  }, [teams])

  const handleAddKey = (teamId: string) => {
    setPatLoading(true)
    const newKeyLifetime = new Date()
    newKeyLifetime.setDate(newKeyLifetime.getDate() + 30)

    patService.createPat(teamId, newKeyLifetime).then((response) => {
      setPatKeys((prevPatKeys) => {
        const newPatKeys = { ...prevPatKeys }
        newPatKeys[teamId] = response
        return newPatKeys
      })
      setPatLoading(false)
    })
  }

  const handleDeleteKey = (teamId: string) => {
    const tokenId = patKeys[teamId]?.id as string
    setPatLoading(true)

    patService.deletePat(tokenId).then((response) => {
      if (response.status === 200)
        setPatKeys((prevPatKeys) => {
          const newPatKeys = { ...prevPatKeys }
          delete newPatKeys[teamId]
          return newPatKeys
        })
      else {
        setAlert(true)
        setAlertMessage('PAT could not be deleted' + response.statusText)
      }
      setPatLoading(false)
    })
  }

  /**
   * Convert date like 2024-04-07T11:47:04.781+02:00 to 07.04.2024 11:47:04
   */
  const convertDate = (date: string) => {
    const dateObj = new Date(date)
    return dateObj.toLocaleString()
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: '!max-w-[60vw]',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center w-full justify-between">
            <h3 className="text-xl font-semibold">Personal Access Tokens</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='className="overflow-x-auto'>
            {patLoading && (
              <div className={'w-full grid mt-8'}>
                <span className="loading loading-spinner loading-lg place-self-center"></span>
              </div>
            )}
            {!patLoading && (
              <table className="table w-full fit">
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Personal Access Token</th>
                    <th>Expiry Date</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {teams.map((team) => (
                    <tr key={team.id}>
                      <td>{team.name}</td>
                      <td>
                        {patKeys[team.id!] ? (
                          <div>
                            <input
                              readOnly
                              value={patKeys[team.id!].token}
                              type="password"
                            />
                          </div>
                        ) : (
                          'No PAT'
                        )}
                      </td>
                      <td>
                        {patKeys[team.id!] ? (
                          <div>
                            <input
                              value={convertDate(
                                patKeys[team.id!].expiryDate.toString(),
                              )}
                              readOnly
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {patKeys[team.id!] ? (
                          <div>
                            <CopyToClipboard
                              textToCopy={patKeys[team.id!]?.token ?? ''}
                            ></CopyToClipboard>
                            <button
                              onClick={() => handleAddKey(team.id!)}
                              type="button"
                            >
                              <ArrowPathIcon className="w-5 h-5 ml-2" />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteKey(team.id!)}
                            >
                              <TrashIcon className="w-5 h-5 ml-2" />
                            </button>
                          </div>
                        ) : (
                          <button onClick={() => handleAddKey(team.id!)}>
                            <ArrowPathIcon className="w-5 h-5 ml-2" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <>
            {alert && (
              <div className="alert alert-warning mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <span>{alertMessage}</span>
              </div>
            )}
          </>
          <details className="mt-8">
            <summary className="text-xl font-semibold">
              How to use your Personal Access Token
            </summary>
            <div className="p-2">
              <MarkdownPreview source={usageExampleContent}></MarkdownPreview>
            </div>
          </details>
        </ModalBody>
        <ModalFooter>
          <Button color={'primary'} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PatManager
