import { iTool } from '../components/sessionControls/tools/iTool.ts'
import axiosClient from '../utils/axiosClient'

class ToolService {
  private static instance: ToolService

  private constructor() {}

  public static getInstance(): ToolService {
    if (!ToolService.instance) {
      ToolService.instance = new ToolService()
    }
    return ToolService.instance
  }

  /**
   * Get all tools
   * @param baseSettingsId base id of the assistant, optional. Adds all assistant tools, even the ones that are not accessible by the user
   */
  public async getTools(baseSettingsId: string = ''): Promise<iTool[]> {
    if (!baseSettingsId || baseSettingsId === '') baseSettingsId = 'null'
    return (await axiosClient.get(`/tools?baseSettingsId=` + baseSettingsId))
      .data as iTool[]
  }

  /**
   * @deprecated commented out in backend as well
   */
  // public async getTool(toolsId: string): Promise<iTool> {
  //   return (await axiosClient.get(`/tools/${toolsId}`)).data as iTool
  // }

  public async createTool(tool: iTool): Promise<iTool> {
    return (await axiosClient.post(`/tools`, tool)).data as iTool
  }

  public async updateTool(tool: iTool): Promise<void> {
    await axiosClient.put(`/tools`, tool)
  }

  public async deleteTool(toolsId: string): Promise<void> {
    await axiosClient.delete(`/tools/${toolsId}`)
  }
}

export default ToolService.getInstance()
