import { createWithEqualityFn } from 'zustand/traditional'
import { iKnowledgeContainer } from '../components/sessionControls/knowledgeContainer/iKnowledgeContainer.ts'
import knowledgeContainerService from '../services/knowledgeContainerService.ts'
import { Role } from '../interfaces/iShared.ts'
import { getUserKey } from '../components/login/authentication.tsx'

interface iKnowledgeContainerState {
  isLoading: boolean
  knowledgeContainer: iKnowledgeContainer[]
  getKnowledgeContainerRole: (assistantId: string) => Role | undefined
  hasRightTo: (minRole: Role, assistantId: string) => boolean
  setKnowledgeContainer: (teams: iKnowledgeContainer[]) => void
  loadKnowledgeContainer: (baseSettingsId?: string) => Promise<iKnowledgeContainer[]>
}

const useKnowledgeContainerStore = createWithEqualityFn<iKnowledgeContainerState>(
  (set, getState) => ({
    isLoading: false,
    knowledgeContainer: [],
    getKnowledgeContainerRole: (kcId) => {
      const container = getState().knowledgeContainer.find(
        (a: iKnowledgeContainer) => a.id === kcId,
      )
      const shared = container?.shared?.find((s) => s.id === getUserKey())

      return shared?.role
    },
    hasRightTo: (minRole: Role, kcId) => {
      const container = getState().knowledgeContainer.find(
        (kc: iKnowledgeContainer) => kc.id === kcId,
      )
      const isOwner = container?.ownerId === getUserKey()
      if (isOwner) return true
      const role = getState().getKnowledgeContainerRole(kcId)
      if (role === undefined) return false
      return role <= minRole
    },
    setKnowledgeContainer: (knowledgeContainer: iKnowledgeContainer[]) =>
      set(() => ({ knowledgeContainer })),

    /**
     * Load knowledge container,
     * @param baseSettingsId base id of the assistant, optional
     */
    loadKnowledgeContainer: async (baseSettingsId?: string) => {
      const kc =
        await knowledgeContainerService.getKnowledgeContainerContainerList(
          baseSettingsId,
        )
      getState().setKnowledgeContainer(kc)
      return kc
    },
  }),
)

export default useKnowledgeContainerStore
