import React, { useMemo } from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  Divider,
  Chip,
  Accordion,
  AccordionItem,
} from '@nextui-org/react'
import { getMarkdownTag } from '../utils/markdownUtils.ts'
import Markdown from '../chatWindow/Markdown.tsx'
import FaqTitle from './faqTitle.tsx'

type FaqItemProps = {
  markdown: string
}

function FaqPage({ markdown }: FaqItemProps) {
  const title = useMemo(() => {
    return getMarkdownTag('G-Title', markdown) as string
  }, [])

  const categories: string[] = useMemo(() => {
    return getMarkdownTag('G-Categories', markdown) as string[]
  }, [])

  return (
    <Card className="m-2 max-w-[800px]">
      <Accordion>
        <AccordionItem
          aria-label={title}
          title={<FaqTitle markdown={markdown}></FaqTitle>}
          classNames={{
            heading: 'pr-4',
          }}
        >
          <Divider />
          <CardBody>
            <Markdown value={markdown}></Markdown>
          </CardBody>
          <Divider />
          <CardFooter>
            {categories!.map((categorie) => {
              return (
                <Chip key={categorie} color={'primary'} className={'mr-2'}>
                  {categorie}
                </Chip>
              )
            })}
          </CardFooter>
        </AccordionItem>
      </Accordion>
    </Card>
  )
}

export default FaqPage
