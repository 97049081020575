import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './sessionFolders.css'
import { Accordion, AccordionItem } from '@nextui-org/react'
import SessionFoldersSearch from './sessionFoldersSearch.tsx'
import SessionFolderTitle from './sessionsFolderTitle.tsx'
import { iFolder } from './interfaces/iFolder.ts'
import { Selection } from '@react-types/shared/src/selection'
import useTeamStore from '../../stateManagement/teamState.ts'
import useFolderStore from '../../stateManagement/folderState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import SessionFolder from './sessionsFolder.tsx'
import CreateSessionButton from './createSessionButton.tsx'
import { twMerge } from 'tailwind-merge'
import { shallow } from 'zustand/shallow'

type SessionFoldersProps = React.HTMLAttributes<HTMLDivElement> & {}
function SessionFolders({ className }: SessionFoldersProps) {
  const classes = twMerge('flex-1', className)

  const {
    sessions,
    activeSession,
    isLoading: isSessionsLoading,
  } = useSessionStore(
    (state) => ({
      sessions: state.sessions,
      isLoading: state.isLoading,
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const { folders, isLoading: isFoldersLoading } = useFolderStore(
    (state) => ({
      folders: state.folders,
      isLoading: state.isLoading,
    }),
    shallow,
  )
  const { teams, selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
    }),
    shallow,
  )

  const [searchValue, setSearchValue] = useState('')
  const [selectedFolders, setSelectedFolders] = useState<Selection>(new Set())

  useEffect(() => {
    if (activeSession === null) return
    const folderdId = sessions.find(
      (session) => session.id === activeSession.id,
    )?.folderId
    if (folderdId) setSelectedFolders(new Set([folderdId]))
  }, [activeSession])

  const filteredSessions = useCallback(
    (folder: iFolder) => {
      return Array.from(sessions)
        .sort((a, b) => (a.modified! > b.modified! ? -1 : 1))
        .filter((session) => session.folderId === folder.id)
        .filter((session) => session.title.toLowerCase().indexOf(searchValue) >= 0)
    },
    [selectedTeam, searchValue, sessions],
  )
  const filteredFolders = useMemo(() => {
    return Array.from(folders!).filter(
      (folder) => !searchValue.length || filteredSessions(folder).length,
    )
  }, [filteredSessions, folders])
  return (
    <>
      <div className={classes}>
        {!!selectedTeam && (
          <SessionFoldersSearch
            isLoading={isSessionsLoading}
            onValueChange={(value: string) => setSearchValue(value)}
          />
        )}
        <div className="overflow-auto mt-8">
          {!isFoldersLoading && (
            <Accordion
              selectionMode="multiple"
              variant="splitted"
              selectedKeys={selectedFolders}
              onSelectionChange={(keys) => setSelectedFolders(keys)}
            >
              {filteredFolders.map((folder) => (
                <AccordionItem
                  textValue={folder.id}
                  key={folder.id}
                  className={'px-0 overflow-hidden !shadow-none !bg-[#f6f6f6]'}
                  classNames={{
                    heading: 'px-4',
                  }}
                  title={<SessionFolderTitle folder={folder} />}
                >
                  <div className={'px-4'}>
                    <CreateSessionButton
                      folder={folder}
                      selectedTeam={selectedTeam!}
                    />
                  </div>
                  <div className={'mt-6'}></div>
                  <SessionFolder
                    folder={folder}
                    searchValue={searchValue}
                    selectedTeam={selectedTeam}
                    sessions={sessions}
                  ></SessionFolder>
                </AccordionItem>
              ))}
            </Accordion>
          )}
          {/*          <SessionFolder
              searchValue={searchValue}
              selectedTeam={selectedTeam}
              sessions={sessions}
          ></SessionFolder>
          <div className={'flex justify-center my-8'}>
            <CreateSessionButton selectedTeam={selectedTeam!} />
          </div>*/}
        </div>
      </div>
    </>
  )
}

export default SessionFolders
