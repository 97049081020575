import React, { useCallback, useEffect, useState } from 'react'
import ToolService from '../../../services/toolService.ts'
import { iTool } from './iTool.ts'
import { PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import ProfileImage from '../../utils/profileImage/profileImage'
import { getUserEmail } from '../../login/authentication.tsx'
import {
  Avatar,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { CameraIcon } from '@heroicons/react/16/solid'
import useToolStore from '../../../stateManagement/toolState.ts'
import { Role } from '../../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'
import { iSecret } from '../../utils/iSecret.ts'
import SecretService from '../../../services/secretService.ts'
import { checkSecrets, SecretsDisplay } from './SecretsDisplay.tsx'

interface iToolView {
  closeFunction: () => void
  editToolFunction: (tool: iTool | undefined) => void
  selector: 'Selection' | 'Action'
}

function ToolView({ closeFunction, editToolFunction, selector }: iToolView) {
  const { activeSession, updateSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
      updateSession: state.updateSession,
    }),
    //TODO: quick fix till we found a better update solution for activeSession
    //shallow,
  )

  const { hasRightTo, loadTools } = useToolStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      loadTools: state.loadTools,
    }),
    shallow,
  )

  const [secrets, setSecrets] = useState<iSecret[]>([])
  const [allTools, setAllTools] = useState<iTool[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getTools()
    SecretService.getSecrets().then((secrets) => {
      setSecrets(secrets)
    })
  }, [])

  useEffect(() => {
    getTools()
  }, [activeSession?.assistantSettings?.baseAssistantSettingsId])

  const getTools = () => {
    setLoading(true)
    loadTools(activeSession?.assistantSettings?.baseAssistantSettingsId ?? '').then(
      (tools) => {
        // filter out duplicates, keep the one with fromBaseAssistantInherited = true
        tools = Object.values(
          tools.reduce(
            (acc, tool) => {
              if (!acc[tool.id!] || tool.fromBaseAssistantInherited) {
                acc[tool.id!] = tool
              }
              return acc
            },
            {} as { [id: string]: iTool },
          ),
        )

        setAllTools(tools)
        setLoading(false)
      },
    )
  }

  const filteredTools = allTools
    .filter((tool) => {
      if (!searchQuery || searchQuery.length <= 3) return true
      tool.name.toLowerCase().includes(searchQuery.toLowerCase())
    })
    .filter((tool) => {
      if (selector === 'Selection') {
        return true
      }
      return tool.ownerEmail === getUserEmail() || hasRightTo(Role.Manager, tool.id!)
    })

  const toggleTool = async (toolId: string) => {
    if (!activeSession) return

    // create list of toolIds if it doesn't exist
    if (!activeSession.assistantSettings?.toolIds) {
      activeSession.assistantSettings!.toolIds = []
    }

    // Remove toolId if it exists, otherwise add it
    if (activeSession.assistantSettings?.toolIds.some((p) => p === toolId)) {
      activeSession.assistantSettings.toolIds =
        activeSession.assistantSettings.toolIds.filter((p) => p !== toolId)
    } else {
      activeSession.assistantSettings!.toolIds.push(toolId)
    }

    await updateSession(activeSession!)
  }

  const isToolEnabled = useCallback(
    (toolId: string) => {
      return activeSession?.assistantSettings?.toolIds?.some((p) => p === toolId)
    },
    [activeSession?.assistantSettings?.toolIds?.length],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const deleteTool = (toolId: string) => {
    if (!confirm('Are you sure you want to delete this predefined setting?')) {
      return
    }
    setLoading(true)
    ToolService.deleteTool(toolId).then(() => {
      getTools()
    })
  }

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>Tools</h1>
      </ModalHeader>
      <ModalBody className={'min-w-96'}>
        <>
          {selector === 'Selection' && (
            <p className={''}>Select tools you want to use for this session.</p>
          )}
          {selector === 'Action' && <p className={''}>Manage your tools.</p>}
          {allTools.length > 5 && (
            <>
              <input
                id="search"
                type="text"
                placeholder="Search..."
                className="input input-bordered w-full"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <br />
            </>
          )}
          {loading && (
            <div className={'w-full grid'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )}

          {!loading && (
            <div className="overflow-x-hidden ">
              <table className="table">
                <thead>
                  <tr>
                    {selector === 'Selection' && <th></th>}
                    <th>Name</th>
                    <th>Author</th>
                    {selector === 'Action' && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {filteredTools.map((tool) => (
                    <tr key={tool.id}>
                      {selector === 'Selection' && (
                        <th>
                          <label>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={isToolEnabled(tool.id!)}
                              onChange={() => toggleTool(tool.id!)}
                              disabled={
                                tool.fromBaseAssistantInherited ||
                                !checkSecrets(tool, secrets).result
                              }
                            />
                          </label>
                        </th>
                      )}
                      <td>
                        <div className="flex items-center space-x-3">
                          <Avatar
                            className="flex-shrink-0"
                            showFallback
                            fallback={
                              <CameraIcon className="w-6 h-6 text-default-500" />
                            }
                            size={'md'}
                            src={tool.image}
                          ></Avatar>
                          <div>
                            <div className={'flex items-center'}>
                              <div className="font-bold mr-3">{tool.name}</div>
                              <SecretsDisplay secrets={secrets} tool={tool} />
                            </div>
                            <div className="text-sm opacity-50">
                              {tool.description}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <ProfileImage contact={tool.ownerEmail!} />
                      </td>
                      {selector === 'Action' && (
                        <td>
                          <div className="flex items-center">
                            <button
                              className="btn btn-ghost btn-circle"
                              onClick={() => editToolFunction(tool)}
                            >
                              <PencilIcon className="w-5 h-5" />
                            </button>
                            <button
                              className="btn btn-ghost btn-circle"
                              onClick={() => deleteTool(tool.id!)}
                            >
                              <TrashIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {selector === 'Action' && (
            <div className="w-full  grid place-items-center">
              <Button
                className={'m-1'}
                fullWidth
                isIconOnly
                variant={'light'}
                color={'primary'}
                onClick={() => editToolFunction(undefined)}
              >
                <PlusCircleIcon className="w-8 h-8" />
              </Button>
            </div>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeFunction}>Close</Button>
      </ModalFooter>
    </>
  )
}

export default ToolView
