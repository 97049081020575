import axiosClient from '../utils/axiosClient'
import {
  iAssistantSettings,
  iAssistantDetails,
} from '../components/sessionControls/assistants/iAssistantTypes'
class AssistantSettingsService {
  private static instance: AssistantSettingsService

  private constructor() {}

  public static getInstance(): AssistantSettingsService {
    if (!AssistantSettingsService.instance) {
      AssistantSettingsService.instance = new AssistantSettingsService()
    }

    return AssistantSettingsService.instance
  }

  public async createAssistantSettings(
    assistantSettings: iAssistantSettings,
  ): Promise<iAssistantSettings> {
    return (await axiosClient.post('/assistantsettings', assistantSettings))
      .data as iAssistantSettings
  }

  public async deleteAssistantSettings(assistantSettingsId: string): Promise<void> {
    const response = await axiosClient.delete(
      `/assistantsettings/${assistantSettingsId}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not delete system prompt')
    }
  }

  public async getAssistantSettings(
    assistantSettingsId: string,
  ): Promise<iAssistantSettings> {
    return (await axiosClient.get(`/assistantsettings/${assistantSettingsId}`))
      .data as iAssistantSettings
  }

  public async getSessionSettingDetails(
    assistantSettingsId: string,
  ): Promise<iAssistantDetails> {
    return (
      await axiosClient.get(`assistantsettings/${assistantSettingsId}/details`)
    ).data as iAssistantDetails
  }

  public async getAllAssistantSettings(): Promise<iAssistantSettings[]> {
    return (await axiosClient.get('/assistantsettings')).data as iAssistantSettings[]
  }

  public async getBlueprintAssistants(): Promise<iAssistantSettings[]> {
    return (await axiosClient.get('/assistantsettings/blueprint/list'))
      .data as iAssistantSettings[]
  }

  public async updateAssistantSettings(
    assistantSettings: iAssistantSettings,
  ): Promise<void> {
    const response = await axiosClient.put('/assistantsettings', assistantSettings)
    if (response.status !== 200) {
      throw new Error('Could not update system prompt')
    }
  }
}

export default AssistantSettingsService.getInstance()
