import React, { useEffect, useState } from 'react'
import {
  deleteTokens,
  evaluateTokens,
  openLoginPage,
  validateToken,
} from './components/login/authentication.tsx'
import UserService from './services/userService'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DefaultLayout from './layouts/default.tsx'
import SessionContentPage from './pages/sessionContentPage.tsx'
import ExploreGaiaPage from './pages/exploreGaiaPage.tsx'
import NotFoundPage from './pages/NotFoundPage.tsx'
import ForbiddenPage from './pages/ForbiddenPage.tsx'
import FaqPage from './pages/faqPage.tsx'

function App() {
  const [theme] = useState<string>('draeger')

  const [loginState, setLoginState] = useState(-1)

  useEffect(() => {
    let loginProcess = false
    const params = window.location.href.split('&')
    if (params.length > 1 && params[1].indexOf('state=STATE') === 0) {
      loginProcess = true
      evaluateTokens()
        .then(async (e) => {
          loginProcess = false
          userLogin()
        })
        .catch((e) => {
          console.error('Error: ', e)
          debugger
        })
    }

    //handle user identification and login redirection
    const validateTokenState = validateToken()

    if (!loginProcess) {
      if (!validateTokenState.refreshToken) {
        openLoginPage()
        return
      } else {
        userLogin()
      }
    }
  }, [])

  const userLogin = () => {
    try {
      UserService.identifyUser().then((e) => {
        if (e) {
          if (e.status === 401) {
            // reset cookies
            deleteTokens()
            // redirect to login page
            openLoginPage()
            return
          }
          setLoginState(1)
          if (window.location.hash.indexOf('state=STATE') === 1) {
            window.location.href = window.location.href.split('#')[0]
          }
          return
        }
        setLoginState(0)
        return
      })
    } catch (e) {
      console.error('Error: ', e)
      setLoginState(0)
      deleteTokens()
    }
    const params = new URLSearchParams(document.location.search)
    let stateParam = params.get('state')
    if (stateParam) {
      stateParam = stateParam.replace('STATE-', '')
      const stateParamAsString = atob(stateParam)
      const stateParamAsJSON = JSON.parse(stateParamAsString)
      window.location.href = stateParamAsJSON.redirectUri
    }
    //window.history.replaceState({}, document.title, window.location.pathname)
  }
  console.log('test log')
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout loginState={loginState} />}>
          <Route index element={<SessionContentPage theme={theme} />} />
          <Route path="explore" element={<ExploreGaiaPage theme={theme} />} />
          <Route path="forbidden" element={<ForbiddenPage theme={theme} />} />
          <Route path="faq" element={<FaqPage theme={theme} />} />
        </Route>
        <Route path="*" element={<NotFoundPage theme={theme} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
