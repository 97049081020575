import React, { useEffect, useState } from 'react'
import userService from '../../../services/userService.ts'
import TeamService from '../../../services/teamService.ts'
import { iButtonHandles, UserManagementState } from './iUserManagement.tsx'
import { iTeam } from './iTeam.tsx'
import {
  iShared,
  iShareLink,
  SharableEntityType,
} from '../../../interfaces/iShared.ts'
import MembersSelection from '../../utils/memberSelection.tsx'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { ShareType } from '../../../interfaces/iItem.js'
import {
  Badge,
  Button,
  Checkbox,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
  Textarea,
  Tooltip,
} from '@nextui-org/react'
import useTeamStore from '../../../stateManagement/teamState.ts'
import { useForm, SubmitHandler } from 'react-hook-form'
import { iUser } from '../../login/iUser.ts'
import AssistantSelection from '../../utils/assistantSelection.tsx'
import ToolSelection from '../../utils/toolSelection.tsx'
import KnowledgeContainerSelection from '../../utils/knowledgeContainerSelection.tsx'
import { hasFeatureFlag } from '../../../utils/featureFlags.ts'
import { shallow } from 'zustand/shallow'
import InvitationLinkSettings from '../../invitation/invitationLinkSettings.tsx'

interface iTeamFormInput {
  teamName: string
  description: string
  costCenter: string
  budget: number
  apiAccess: boolean
  isLimited: boolean
}

function UserManagementCreateEdit({
  closeFunction,
  submitFunction,
  setLoading,
  mode,
  team,
}: iButtonHandles) {
  const [assistantIds, setAssistantIds] = useState<string[]>([])
  const [toolIds, setToolIds] = useState<string[]>([])
  const [knowledgeContainerIds, setKnowledgeContainerIds] = useState<string[]>([])
  const [members, setMembers] = useState<iShared[]>([])
  const [shareLinks, setShareLinks] = useState<iShareLink[]>([])
  const [allUsers, setAllUsers] = useState<iUser[]>([])
  const [alert, setAlert] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { createTeam } = useTeamStore(
    (state) => ({ createTeam: state.createTeam }),
    shallow,
  )
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<iTeamFormInput>({
    defaultValues: {
      teamName: team?.name,
      description: team?.description,
      costCenter: team?.costCenter,
      budget: team?.budget,
      apiAccess: team?.apiAccess,
      isLimited: team?.isAssistantSettingsAccessLimited,
    },
  })
  const watchIsLimited = watch('isLimited')

  // constructor, load all users
  useEffect(() => {
    // if the edit mode is active, set the values of the team
    if (mode === UserManagementState.Edit && team) {
      setMembers(team.shared)
      setAssistantIds(team.resourceRestrictions.assistantIds ?? [])
      setToolIds(team.resourceRestrictions.toolIds ?? [])
      setKnowledgeContainerIds(team.resourceRestrictions.knowledgeContainerIds ?? [])
    }

    userService.searchUsers().then((response) => {
      setAllUsers(response)
    })
  }, [])

  // if the edit mode is active, set the members to the shared members of the team
  // as soon as the allUsers are loaded
  useEffect(() => {
    if (mode === UserManagementState.Edit && team) {
      setMembers(team.shared || [])
      setShareLinks(team.shareLinks || [])
    }
  }, [allUsers])

  const onSubmit: SubmitHandler<iTeamFormInput> = (data) => {
    // check if limited access is set but no settings are selected
    if (data.isLimited && assistantIds.length === 0) {
      setAlert(true)
      return
    }

    setLoading()
    setIsLoading(true)
    // convert members to shared
    const tmpTeam: iTeam = {
      ...(team ?? {}),
      name: data.teamName,
      costCenter: data.costCenter,
      spent: team?.spent ?? 0,
      budget: data.budget,
      description: data.description,
      shared: members,
      isAssistantSettingsAccessLimited: data.isLimited,
      shareLinks: shareLinks,
      resourceRestrictions: {
        modelIds: null,
        assistantIds: assistantIds.length ? assistantIds : null,
        toolIds: toolIds.length ? toolIds : null,
        knowledgeContainerIds: knowledgeContainerIds.length
          ? knowledgeContainerIds
          : null,
      },
      apiAccess: data.apiAccess,
      sharedType: ShareType.Limited,
    }
    if (mode === UserManagementState.Edit && team) {
      TeamService.updateTeam(tmpTeam).then(() => {
        submitFunction()
      })
      return
    } else {
      createTeam(tmpTeam).then(() => {
        submitFunction()
      })
    }
  }

  const handleCancel = () => {
    closeFunction()
  }

  // validate cost center
  const plausabilityCostCenter = (costCenter: string) =>
    /^[0-9]*$/.test(costCenter) || 'Cost centre may only contain figures'

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-4'}>
          {mode === UserManagementState.Create ? 'Create' : 'Edit'} Team
        </h1>
      </ModalHeader>
      <ModalBody>
        <div className={'min-w-fit w-[32rem]'}>
          <div className="flex flex-col ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                type="text"
                label="Team Name"
                variant="flat"
                {...register('teamName', { required: 'Team name is required' })}
                className="mb-4"
                isInvalid={!!errors.teamName}
                errorMessage={errors.teamName?.message}
              />

              <Textarea
                label="Description"
                variant="flat"
                {...register('description')}
                rows={2}
                className="mb-4"
              />

              <Input
                type="text"
                label="Cost Center"
                isDisabled={!!team?.id}
                variant="flat"
                {...register('costCenter', {
                  required: 'Cost Center is required',
                  validate: plausabilityCostCenter,
                })}
                className="mb-4"
                isInvalid={!!errors.costCenter}
                errorMessage={errors.costCenter?.message}
              />

              <Input
                type="number"
                label="Monthly Budget"
                variant="flat"
                {...register('budget', {
                  required: 'Monthly Budget is required',
                  min: { value: 1, message: 'Minimum of 1 € is required' },
                  valueAsNumber: true,
                })}
                className="mb-4"
                isInvalid={!!errors.budget}
                errorMessage={errors.budget?.message}
              />
              <Checkbox {...register('apiAccess')} className="mb-2 flex">
                <Tooltip
                  content={
                    'Team members can access the GAIA API via their own PAT (Personal Access Token).'
                  }
                  color={'primary'}
                  placement={'right'}
                  className={'max-w-96'}
                >
                  <div className="flex place-content-center cursor-pointer">
                    <span className="label-text">Enable API Access</span>
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </Tooltip>
              </Checkbox>

              <Checkbox {...register('isLimited')} className="mb-2 flex">
                <Tooltip
                  content={
                    'Control all resources that are to be released for your team. If no special resources, e.g. assistants, are selected, your team can access all assistants.'
                  }
                  color={'primary'}
                  className={'max-w-96'}
                  placement={'right'}
                >
                  <div className="flex place-content-center cursor-pointer">
                    <span className="label-text">
                      Limit access to fixed resources
                    </span>
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </div>
                </Tooltip>
              </Checkbox>
              {watchIsLimited && (
                <>
                  <Tabs aria-label="limited resources">
                    <Tab key="photos" title="Assistant">
                      <AssistantSelection
                        selectedItemId={assistantIds}
                        setSelectedItemId={setAssistantIds}
                      />
                    </Tab>
                    <Tab key="tools" title="Tools" isDisabled={!hasFeatureFlag()}>
                      <ToolSelection
                        selectedItemId={toolIds}
                        setSelectedItemId={setToolIds}
                      ></ToolSelection>
                    </Tab>
                    <Tab
                      key="knowledgeContainer"
                      title="KnowledgeContainer"
                      isDisabled={!hasFeatureFlag()}
                    >
                      <KnowledgeContainerSelection
                        selectedItemId={knowledgeContainerIds}
                        setSelectedItemId={setKnowledgeContainerIds}
                      ></KnowledgeContainerSelection>
                    </Tab>
                  </Tabs>
                </>
              )}

              <label className="label ">
                <span className="label-text">Members</span>
              </label>
              <Tabs aria-label="members">
                <Tab key="members" title="Current Members">
                  <MembersSelection
                    shared={members}
                    setShared={setMembers}
                    ownerId={team?.ownerId}
                    roles={team?.roles}
                    writeOnly={team?.sharedType === ShareType.Public}
                  />
                </Tab>
                <Tab
                  key="links"
                  title={
                    <Badge
                      isInvisible={!shareLinks.length}
                      isOneChar
                      content={shareLinks.length}
                      color="primary"
                      shape="rectangle"
                      classNames={{
                        badge: '-right-2',
                      }}
                    >
                      Invitation Links
                    </Badge>
                  }
                >
                  <InvitationLinkSettings
                    links={shareLinks}
                    resourceType={SharableEntityType.Team}
                    resourceId={team!.id!}
                    roles={team?.roles}
                    writeOnly={team?.sharedType === ShareType.Public}
                    onChange={(links) => setShareLinks(links)}
                  ></InvitationLinkSettings>
                </Tab>
                <Tab key="requests" title="Requests" isDisabled={true}></Tab>
              </Tabs>
            </form>
          </div>

          {alert && watchIsLimited && assistantIds.length === 0 && (
            <div className="alert alert-warning mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>
                If limited access is selected, at least one setting must also be
                selected.
              </span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button isDisabled={isLoading} variant={'light'} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          color={'primary'}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

export default UserManagementCreateEdit
