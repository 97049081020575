import axiosClient from '../utils/axiosClient'
import { iAssistantModel } from '../interfaces/iAssistantModel.ts'
import {
  EntityType,
  iShareLink,
  iShareLinkItemPreview,
  SharableEntityType,
} from '../interfaces/iShared.ts'

class ShareLinkService {
  private static instance: ShareLinkService

  private constructor() {}

  public static getInstance(): ShareLinkService {
    if (!ShareLinkService.instance) {
      ShareLinkService.instance = new ShareLinkService()
    }
    return ShareLinkService.instance
  }

  public async addEntityToItemViaShareLink(
    itemId: string,
    itemType: SharableEntityType,
    linkId: string,
    entityId: string,
    entityType: EntityType,
  ): Promise<any> {
    // message is optional and a query parameter
    const response = await axiosClient.post(`/sharelinks/addentity`, {
      itemId,
      itemType,
      linkId,
      entityId,
      entityType,
    })
    return response.data
  }

  public async validateShareLink(
    itemId: string,
    itemType: SharableEntityType,
    linkId: string,
  ): Promise<{ shareLink: iShareLink; itemPreview: iShareLinkItemPreview }> {
    const response = await axiosClient.get(`/sharelinks/validate`, {
      params: {
        itemId,
        itemType,
        linkId,
      },
    })
    return response.data
  }

  public async generateShareLink(
    itemId: string,
    itemType: SharableEntityType,
    allowedEntityTypes: EntityType[],
  ): Promise<iShareLink> {
    const response = await axiosClient.post(`/sharelinks/generate`, {
      itemId,
      itemType,
      allowedEntityTypes,
    })
    return response.data
  }
}

export default ShareLinkService.getInstance()
