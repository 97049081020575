import React from 'react'
import { Card, CardBody } from '@nextui-org/react'

interface NotFoundPageProp {
  theme: string
}

function ForbiddenPage({ theme }: NotFoundPageProp) {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <Card className="p-10">
        <CardBody className="flex flex-col items-center">
          <h1 className="mb-6 text-5xl text-red-600">403</h1>
          <h2 className="mb-2 text-xl">Forbidden</h2>
          <span className="text-center text-gray-600">
            Sorry, you do not have access to this page.
          </span>
        </CardBody>
      </Card>
    </div>
  )
}

export default ForbiddenPage
