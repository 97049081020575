import React, { useEffect, useState } from 'react'
import {
  EBlobContainerViewVariant,
  iBlobContainer,
  iBlobContainerView,
} from './iBlobUpload.tsx'
import KnowledgeContainerService from '../../../services/knowledgeContainerService.ts'
import {
  PaperClipIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import ProfileImage from '../../utils/profileImage/profileImage.tsx'

import Description from '../../utils/description.tsx'
import { getUserEmail } from '../../login/authentication.tsx'
import promiseQueue from '../../utils/promiseQueue.tsx'
import { iKnowledgeContainer } from './iKnowledgeContainer.ts'
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import useKnowledgeContainerStore from '../../../stateManagement/knowledgeContainerState.ts'
import { Role } from '../../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'

function BlobContainerView({
  closeFunction,
  addContainerFunction,
  editContainerFunction,
  manageContainerFunction,
  variant,
}: iBlobContainerView) {
  const [blobcontainers, setBlobcontainers] = useState<iBlobContainer[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { activeSession, updateSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
      updateSession: state.updateSession,
    }),
    shallow,
  )

  const { hasRightTo, loadKnowledgeContainer } = useKnowledgeContainerStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      loadKnowledgeContainer: state.loadKnowledgeContainer,
    }),
    shallow,
  )
  // container status
  const [fulfilledCount, setFulfilledCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setFulfilledCount(promiseQueue.getInstance().getFulfilledCount())
    }, 1000)

    // destructor
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    loadBlobContainers()
  }, [])

  useEffect(() => {
    loadBlobContainers()
  }, [activeSession?.assistantSettings?.baseAssistantSettingsId])

  const loadBlobContainers = () => {
    setLoading(true)
    loadKnowledgeContainer(
      activeSession?.assistantSettings?.baseAssistantSettingsId ?? '',
    ).then((response) => {
      if (!activeSession) {
        return
      }
      deselectInvalidContainers(response)
      let blobContainers: iBlobContainer[]
      if (EBlobContainerViewVariant.Selection === variant) {
        blobContainers = response.map((blob) => {
          const selected =
            activeSession.assistantSettings!.knowledgeContainerIds?.includes(
              blob.id!,
            ) ?? false
          return { ...blob, selected: selected }
        })
        // filter out duplicates, keep the one with fromBaseAssistantInherited = true
        blobContainers = Object.values(
          blobContainers.reduce(
            (acc, blob) => {
              if (acc[blob.id!]) {
                acc[blob.id!].fromBaseAssistantInherited = true
              } else {
                acc[blob.id!] = { ...blob }
              }
              return acc
            },
            {} as { [key: string]: (typeof blobContainers)[0] },
          ),
        )
      } else {
        //if (EBlobContainerViewVariant.Action === variant) {
        blobContainers = response
          .filter(
            (x) =>
              x.ownerEmail === getUserEmail() || hasRightTo(Role.Manager, x.id!),
          )
          .map((blob) => {
            const selected =
              activeSession.assistantSettings!.knowledgeContainerIds?.includes(
                blob.id!,
              ) ?? false
            return { ...blob, selected: selected }
          })
      }

      setLoading(false)
      setBlobcontainers(blobContainers)
    })
  }

  /**
   * Deselects invalid containers from session
   * @param containers
   */
  const deselectInvalidContainers = (containers: iKnowledgeContainer[]) => {
    // check if every id in session.sessionSettings?.knowledgeContainerIds is in blobcontainers
    const selectedIds = activeSession?.assistantSettings?.knowledgeContainerIds
    if (!selectedIds) {
      return
    }
    const validIds = containers.map((blob) => blob.id)
    const newSelectedIds = selectedIds.filter((id) => validIds.includes(id))
    if (activeSession && activeSession.assistantSettings) {
      activeSession.assistantSettings.knowledgeContainerIds = newSelectedIds
      updateSession(activeSession)
    }
  }

  const filteredBlobContainers = () => {
    if (searchTerm === '' || searchTerm.length < 3) {
      return blobcontainers
    } else {
      return blobcontainers.filter((blobcontainer) => {
        return (
          blobcontainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          blobcontainer.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
    }
  }

  const handleSelect = (blobcontainer: iBlobContainer) => {
    // prevent the uncheck if it is from base assistant inherited
    if (blobcontainer.fromBaseAssistantInherited) return

    const updatedBlobContainers = blobcontainers.map((blob) => {
      if (blob.id === blobcontainer.id) {
        return { ...blob, selected: !blob.selected }
      }
      return blob
    })
    setBlobcontainers(updatedBlobContainers)

    const selectedBlobContainers = updatedBlobContainers.filter(
      (blob) => blob.selected,
    )
    if (activeSession) {
      // session.sessionSettings!.knowledgeContainer = selectedBlobContainers.map((blob) => blob);
      activeSession.assistantSettings!.knowledgeContainerIds =
        selectedBlobContainers.map((blob) => blob.id!)
      updateSession(activeSession)
    }
  }

  const deleteBlobContainer = (blobcontainer: iBlobContainer) => {
    if (!confirm('Are you sure you want to delete this container?')) {
      return
    }
    setLoading(true)
    KnowledgeContainerService.deleteKnowledgeContainerContainer(blobcontainer.id!)
      .then(() => {})
      .finally(() => {
        loadBlobContainers()
      })
  }

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>Knowledge Container</h1>
      </ModalHeader>
      <ModalBody>
        <>
          {variant === EBlobContainerViewVariant.Selection && (
            <p className={''}>
              Select the knowledge container you want to use in this session.
            </p>
          )}
          {variant === EBlobContainerViewVariant.Action && (
            <p className={''}>
              Use your files and upload them to your knowledge container. They will
              be indexed and can be used as context.
            </p>
          )}
          {(loading && (
            <div className={'grid grid-cols-1 h-20'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )) ||
            (blobcontainers.length > 0 && (
              <>
                {blobcontainers.length > 9 && (
                  <div className={'w-full mt-3'}>
                    <input
                      type="text"
                      placeholder="Search for container or description"
                      className="input input-bordered w-full"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                )}
                <div className=" mt-2 max-h-96 overflow-y-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        {variant === EBlobContainerViewVariant.Selection && (
                          <td>Select</td>
                        )}
                        <td>Name</td>
                        <td>Description</td>
                        <td>Owner</td>
                        <td>Last Update</td>
                        {variant === EBlobContainerViewVariant.Action && <td></td>}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBlobContainers().map((blobcontainer, key) => {
                        return (
                          <tr key={key}>
                            {variant === EBlobContainerViewVariant.Selection && (
                              <td>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-xs"
                                  checked={blobcontainer.selected}
                                  onChange={() => handleSelect(blobcontainer)}
                                  disabled={blobcontainer.fromBaseAssistantInherited}
                                />
                              </td>
                            )}
                            <td>{blobcontainer.name}</td>
                            <td className={'max-w-[25vw]'}>
                              <Description description={blobcontainer.description} />
                            </td>
                            <td>
                              <ProfileImage
                                contact={blobcontainer.ownerEmail}
                                width={8}
                              />
                            </td>
                            <td>
                              {new Date(blobcontainer.modified!).toLocaleString()}
                            </td>

                            {variant === EBlobContainerViewVariant.Action && (
                              <td>
                                <Tooltip
                                  content="Manage files"
                                  color={'primary'}
                                  className={'max-w-96'}
                                >
                                  <button
                                    className="btn btn-ghost btn-circle tooltip inline-flex"
                                    onClick={() =>
                                      manageContainerFunction(blobcontainer)
                                    }
                                  >
                                    <PaperClipIcon className="w-5 h-5" />
                                  </button>
                                </Tooltip>
                                <Tooltip
                                  content="Edit container"
                                  color={'primary'}
                                  className={'max-w-96'}
                                >
                                  <button
                                    className="btn btn-ghost btn-circle tooltip inline-flex"
                                    onClick={() =>
                                      editContainerFunction(blobcontainer)
                                    }
                                  >
                                    <PencilIcon className="w-5 h-5" />
                                  </button>
                                </Tooltip>
                                <Tooltip
                                  content="Delete"
                                  color={'primary'}
                                  className={'max-w-96'}
                                >
                                  <button
                                    className="btn btn-ghost btn-circle tooltip inline-flex"
                                    onClick={() =>
                                      deleteBlobContainer(blobcontainer)
                                    }
                                  >
                                    <TrashIcon className="w-5 h-5" />
                                  </button>
                                </Tooltip>
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
          {variant === EBlobContainerViewVariant.Action && (
            <div className="w-full  grid place-items-center">
              <Button
                className={'m-1'}
                fullWidth
                isIconOnly
                variant={'light'}
                color={'primary'}
                onClick={addContainerFunction}
              >
                <PlusCircleIcon className="w-8 h-8" />
              </Button>
            </div>
          )}
          {fulfilledCount !== 0 && (
            <div className="alert alert-warning mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6 animate-bounce"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>
                Knowledge container is currently being processed. Please wait until
                you use/edit your last used container.
              </span>
            </div>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeFunction}>Close</Button>
      </ModalFooter>
    </>
  )
}

export default BlobContainerView
