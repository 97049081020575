import Citation from '../components/chatWindow/sourceFiles/citation.tsx'
import { iMessage } from '../components/chatWindow/iMessage.ts'
import katex from 'katex'
import { getCodeString } from 'rehype-rewrite'
import 'katex/dist/katex.min.css'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
  iCitation,
  iFile,
} from '../components/chatWindow/sourceFiles/iSourceFileCitations.ts'
import CopyToClipboard from '../components/utils/copyToClipboard.tsx'

export const liMarkdown = (
  props: any,
  message?: iMessage,
  onClickCitation?: (citation: iCitation) => void,
) => {
  if (props.id?.startsWith('user-content-fn')) {
    let cidNumber = props.id.replace('user-content-fn-', '')
    cidNumber = cidNumber.replace('cid-', '')
    const pElement = props.node!.children.find(
      (child: any) => child.tagName === 'p',
    ) as any
    if (!pElement) {
      return <li id={props.id}>{props.children}</li>
    }
    if (pElement.children.length === 0) {
      return <li id={props.id}>{props.children}</li>
    }
    const text = pElement.children.find((child: any) => child.type === 'text')!
      .value as any
    const backHRefs = pElement.children
      .filter((child: any) => child.tagName === 'a')
      .map((a: any) => {
        if (!a.properties.href) return
        let fnrefNumber = a.properties.href.replace('#user-content-fnref-', '')
        fnrefNumber = fnrefNumber.replace('cid-', '')
        return `#user-content-fnref-cid-${fnrefNumber}`
      })
    let citation: iCitation | null = null
    message?.citations?.files.find((file: iFile) => {
      file.citations.find((c) => {
        if (c.citationId === parseInt(cidNumber)) {
          c.citation = text
          citation = c
        }
      })
    })

    if (!citation) {
      return <></>
    }

    return (
      <li id={`user-content-fn-cid-${cidNumber}`}>
        <Citation
          cid={cidNumber}
          text={text as string}
          backHRefs={backHRefs}
          onClick={() => onClickCitation && onClickCitation(citation!)}
        />
      </li>
    )
  } else {
    return <li {...props}>{props.children}</li>
  }
}

export const codeMarkdown = (props: any) => {
  const { children, className, ...rest } = props
  // syntax for inline katex and latex code
  if (typeof children === 'string' && /^\$\$(.*)\$\$/.test(children)) {
    const html = katex.renderToString(children.replace(/^\$\$(.*)\$\$/, '$1'), {
      throwOnError: false,
    })
    return (
      <code
        dangerouslySetInnerHTML={{ __html: html }}
        style={{ background: 'transparent' }}
      />
    )
  }
  const code =
    props.node && props.node.children ? getCodeString(props.node.children) : children
  // syntax for katex and latex code
  if (
    typeof code === 'string' &&
    typeof className === 'string' &&
    /^language-katex/.test(className.toLocaleLowerCase())
  ) {
    const html = katex.renderToString(code, {
      throwOnError: false,
    })
    return (
      <code
        style={{ fontSize: '150%' }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }
  const match = /language-(\w+)/.exec(className || '')
  if (match) {
    return (
      <SyntaxHighlighter
        {...rest}
        PreTag="pre"
        useInlineStyles={false}
        children={String(children).replace(/\n$/, '')}
        language={match[1]}
      />
    )
  }
  return <code className={String(className)}>{children}</code>
}

export const sectionMarkdown = (props: any, message?: iMessage) => {
  if (props.className === 'footnotes' && message?.citations?.files.length === 0) {
    // Hide footnotes section if there are no citations in current message
    return <></>
  }
  return <section {...props}>{props.children}</section>
}

export const h2Markdown = (props: any) => {
  return <h4 className="text-lg font-semibold">{props.children}</h4>
}

export const aMarkdown = (props: any) => {
  if (props.id?.startsWith('user-content-fnref')) {
    let fnrefNumber = props.id.replace('user-content-fnref-', '')
    fnrefNumber = fnrefNumber.replace('cid-', '')
    const cidNumber = fnrefNumber.split('-').shift()
    return (
      <a
        {...props}
        id={`user-content-fnref-cid-${fnrefNumber}`}
        href={`#user-content-fn-cid-${cidNumber}`}
      >
        {cidNumber}
      </a>
    )
  }
  return <a {...props}>{props.children}</a>
}

export const preMarkdown = (pre: any) => {
  const codeChunk = pre.node.children[0].children[0].value
  return (
    <div className="preWrapper">
      <CopyToClipboard textToCopy={codeChunk}></CopyToClipboard>
      <pre {...pre}></pre>
    </div>
  )
}
