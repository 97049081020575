import { useEffect, useState } from 'react'
import { iOverridableParameter, iOverridableParameterItem } from './model'
import { TrashIcon } from '@heroicons/react/24/outline'
import { FieldsEditor } from '../../utils/inputfields/fieldEditors'
import { InputField, TextField, FlagField } from '../../utils/inputfields/model'

const tooltipHasDefaullt = `
  If the parameter has a default value, the value is used when the parameter is not mapped to a user secret.
`
const tooltipDefault = 'The actual default value for the parameter.'

interface iOverridableParameterEditorProps {
  item: iOverridableParameterItem
  index: number
  onNameChange: (index: number, newName: string) => void
  onValueChange: (index: number, overridable: iOverridableParameter) => void
  onRemove(index: number): void
}

function HttpOverridableEditor({
  item,
  index,
  onNameChange,
  onValueChange,
  onRemove,
}: iOverridableParameterEditorProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [fields, setFields] = useState<InputField[]>([
    new FlagField({
      key: 'hasDefaultValue',
      name: 'Has default value',
      value: item.overridableParameter.hasDefaultValue,
      info: tooltipHasDefaullt,
    }),
    new TextField({
      key: 'defaultValue',
      name: 'Default value',
      value: item.overridableParameter.defaultValue,
      info: tooltipDefault,
    }),
  ])
  const [hiddenFields, setHiddenFields] = useState<string[]>([])

  useEffect(() => {
    if (!item.name) {
      setIsExpanded(true)
    }
  }, [])

  useEffect(() => {
    const hasDefaultValue = (fields[0] as FlagField).value
    if (hasDefaultValue) {
      setHiddenFields([])
    } else {
      setHiddenFields(['defaultValue'])
    }
    onValueChange(index, {
      hasDefaultValue,
      defaultValue: (fields[1] as TextField).value,
    })
  }, [fields])

  return (
    <>
      <div className="p-2 bg-gray-200 rounded-lg mb-2">
        <details open={isExpanded}>
          <summary>
            <div className={'inline ml-1'}>
              <input
                type="text"
                className="input input-bordered w-10/12"
                value={item.name}
                placeholder="Name"
                onChange={(e) => onNameChange(index, e.target.value)}
                required
              />
              <TrashIcon
                className="w-6 h-6 inline-block ml-4 cursor-pointer"
                onClick={() => onRemove(index)}
              />
            </div>
          </summary>
          <div className="w-10/12">
            <FieldsEditor
              fields={fields}
              onChange={setFields}
              hide={hiddenFields}
            ></FieldsEditor>
          </div>
        </details>
      </div>
    </>
  )
}
export default HttpOverridableEditor
