import { CancelTokenSource } from 'axios'
import { iMessage } from './iMessage'
import { iItem } from '../../interfaces/iItem'
import { iFileData } from '../sessionControls/files/fileSetting'
import { CustomFile } from '../sessionControls/files/processDirectory.tsx'
import { iAssistantSettings } from '../sessionControls/assistants/iAssistantTypes.tsx'

export interface iSession extends iItem {
  title: string
  automaticTitle: boolean
  messages?: iMessage[]
  assistantSettings?: iAssistantSettings
  assistantSettingsId?: string
  assistantStatus?: AssistantStatus
  folderId?: string
  teamId: string
  isActive: boolean
  originalFiles?: CustomFile[]
  selectedFiles?: CustomFile[]
  cancelSource?: CancelTokenSource | null
  // is true if message is generating
  isGenerating?: boolean
  // is true until assistant isn´t finished eg. with title generation ...
  isAssistantRunning?: boolean
  currentAssistantAction?: iAssistantAction
  fileData?: iFileData[]
}

export interface iAssistantAction {
  Text: string
  PhraseType: PhraseType
}
enum PhraseType {
  ToolCall,
  ReadingContent,
  WarmingUp,
  WaitForAssistant,
  Generating,
  TearingDown,
  AssistantError,
}

export interface iCustomFileInfo {
  name: string
  webkitRelativePath: string
}

export enum AssistantStatus {
  Idle,
  Generating,
  Cancelled,
}
