import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://467263ed96ef56383ff92af8781d0c3c@o4507922417975296.ingest.de.sentry.io/4508046760083536',
  integrations: [Sentry.browserTracingIntegration()],
  environment:
    window.location.hostname === 'gaia.draeger.net' ? 'production' : 'development',
  // beforeSend(event, hint) {
  //     // Check if it is an exception, and if so, show the report dialog
  //     if (event.exception) {
  //         Sentry.showReportDialog({ eventId: event.event_id });
  //     }
  //     return event;
  // },

  // reactivate for prod
  //tracePropagationTargets: [/^https:\/\/gaia\.draeger\.net/],
  tracePropagationTargets: [/^https:\/\/dev\.gaia\.draeger\.net/],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
})

export { Sentry }
