import React, { useEffect, useMemo, useState } from 'react'
import iSettingsProps from '../../settings/iSettingsProps.ts'
import {
  AssistantTab as AssistantTab,
  iAssistantSettings,
} from './iAssistantTypes.tsx'
import AddEditpreSettings from './addEditAssistant.tsx'
import AssistantInspect from './assistantInspect.tsx'
import { Popup } from '../contextPopup.tsx'
import { useMain } from '../../../stateManagement/contexts/mainContext.tsx'
import { Button, Modal, ModalBody, ModalContent } from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import useAssistantModelStore from '../../../stateManagement/assistantModelState.ts'
import { ShareType } from '../../../interfaces/iItem.ts'
import SearchInput from '../../utils/searchInput.tsx'
import i18n from 'i18next'
import Description from '../../utils/description.tsx'
import ProfileImage from '../../utils/profileImage/profileImage.tsx'
import {
  MagnifyingGlassIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import ClipboardCopyButton from '../../utils/copyToClipboard.tsx'
import { useTranslation } from 'react-i18next'
import { getUserEmail } from '../../login/authentication.tsx'
import { Role } from '../../../interfaces/iShared.ts'
import useAssistantStore from '../../../stateManagement/assistantState.ts'
import AssistantSettingsService from '../../../services/sessionSettingsService.ts'
import { iAssistantModel } from '../../../interfaces/iAssistantModel.ts'
import { shallow } from 'zustand/shallow'

interface iAssistantProps extends iSettingsProps {
  selector: 'Containers' | 'New' | 'Edit' | 'Inspect' | 'OnlyInspect'
  currentPreSetting?: iAssistantSettings
}

function Assistant(props: iAssistantProps) {
  const { setPopup } = useMain()
  const { t } = useTranslation()

  const [currentPreSetting, setCurrentPreSetting] = useState(props.currentPreSetting)
  const [blobViewTab, setBlobViewTab] = useState(
    props.selector === 'Containers'
      ? AssistantTab.Containers
      : props.selector === 'Edit'
        ? AssistantTab.Edit
        : props.selector === 'New'
          ? AssistantTab.New
          : props.selector === 'Inspect'
            ? AssistantTab.Inspect
            : AssistantTab.Inspect,
  )
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { activeSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const { assistantModels } = useAssistantModelStore(
    (state) => ({
      assistantModels: state.assistantModels,
    }),
    shallow,
  )

  const {
    assistants: allAssistants,
    hasRightTo,
    deleteAssistant,
  } = useAssistantStore(
    (state) => ({
      assistants: state.assistants,
      hasRightTo: state.hasRightTo,
      deleteAssistant: state.deleteAssistant,
    }),
    shallow,
  )

  // update states on props change
  useEffect(() => {
    if (props.currentPreSetting) {
      setCurrentPreSetting(props.currentPreSetting)
    }
    if (props.selector) {
      switch (props.selector) {
        case 'Containers':
          setBlobViewTab(AssistantTab.Containers)
          break
        case 'New':
          setBlobViewTab(AssistantTab.New)
          break
        case 'Edit':
          setBlobViewTab(AssistantTab.Edit)
          break
        case 'Inspect':
          setBlobViewTab(AssistantTab.Inspect)
          break
        case 'OnlyInspect':
          setBlobViewTab(AssistantTab.OnlyInspect)
          break
        default:
          break
      }
    }
  }, [props])

  const assistants: iAssistantSettings[] = useMemo(() => {
    return allAssistants.filter(
      (x) =>
        x.ownerEmail === getUserEmail() || // you are the owner
        hasRightTo(Role.Manager, x.id!), // you are a manager of the assistant
    )
  }, [allAssistants])

  const filterAssistants = useMemo(() => {
    if (searchTerm === '' || searchTerm.length < 3) {
      return assistants
    } else {
      // split the search term into words and each word must be found in the title or description, unless the word is enclosed in quotes
      const searchWords = searchTerm.split(' ').filter((word) => word !== '')
      const assistantsReturn = assistants.filter((blobcontainer) => {
        return searchWords.every((word) => {
          return (
            blobcontainer.title.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.description?.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.instruction?.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.ownerEmail?.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.id?.toLowerCase().includes(word.toLowerCase())
          )
        })
      })
      return assistantsReturn
    }
  }, [assistants, searchTerm])

  const deletePreSetting = (preSetting: iAssistantSettings) => {
    if (!confirm('Are you sure you want to delete this predefined setting?')) {
      return
    }
    deleteAssistant(preSetting.id!)
  }
  useEffect(() => {
    if (!currentPreSetting) {
      setCurrentPreSetting(
        activeSession?.assistantSettings
          ? activeSession.assistantSettings
          : undefined,
      )
    }
  }, [])
  const renderCurrentAssistantComponent = () => {
    switch (blobViewTab) {
      case AssistantTab.Containers:
        return (
          <div className="space-y-4 mx-auto overflow-y-auto p-4 rounded-xl overflow-hidden sticky top-0 w-full">
            <div className="justify-center flex w-full">
              <div>
                <div className={'justify-center'}>
                  <h1 className={'text-xl font-semibold mb-2'}>Assistants</h1>
                  <p className={''}>Manage your Assistants.</p>
                </div>
                <>
                  {assistants.length > 0 && (
                    <SearchInput
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      placeholder="Search for Assistant, Owner, Description or Instruction"
                    />
                  )}

                  <div className="flex flex-wrap justify-center">
                    <table className="table">
                      <thead>
                        <tr>
                          <td> {i18n.format(t('name'), 'capitalize')}</td>
                          <td> {i18n.format(t('description'), 'capitalize')}</td>
                          <td> {i18n.format(t('owner'), 'capitalize')}</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {filterAssistants.map((preSetting, key) => {
                          return (
                            <tr key={key}>
                              <td>{preSetting.title}</td>
                              <td className={'max-w-[25vw]'}>
                                <Description description={preSetting.description!} />
                              </td>
                              <td>
                                <ProfileImage
                                  contact={preSetting.ownerEmail!}
                                  width={8}
                                />
                              </td>

                              <td>
                                <Button
                                  isIconOnly
                                  variant={'light'}
                                  onClick={() =>
                                    setPopup(Popup.AssistantInspect, preSetting)
                                  }
                                >
                                  <MagnifyingGlassIcon className="w-5 h-5" />
                                </Button>
                                <Button
                                  isIconOnly
                                  variant={'light'}
                                  onClick={() =>
                                    setPopup(Popup.AssistantEdit, preSetting)
                                  }
                                >
                                  <PencilIcon className="w-5 h-5" />
                                </Button>
                                <ClipboardCopyButton
                                  textToCopy={preSetting.id!}
                                  tooltip={'Assistant ID'}
                                />
                                <Button
                                  isIconOnly
                                  variant={'light'}
                                  color={'danger'}
                                  onClick={() => deletePreSetting(preSetting)}
                                >
                                  <TrashIcon className="w-5 h-5" />
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full  grid place-items-center">
                    <Button
                      className={'m-1'}
                      fullWidth
                      isIconOnly
                      variant={'light'}
                      color={'primary'}
                      onClick={() => setPopup(Popup.AssistantNew)}
                    >
                      <PlusCircleIcon className="w-8 h-8" />
                    </Button>
                  </div>
                </>
              </div>
            </div>
            <div className="modal-action">
              <div className={'text-right mt-2'}>
                <Button onClick={props.closeFunction}>Close</Button>
              </div>
            </div>
          </div>
        )

      case AssistantTab.New:
        // wait for the session
        if (!activeSession || !currentPreSetting) return <></>
        const emptySetting: iAssistantSettings = {
          title: '',
          description: '',
          instruction: '',
          toolIds: [],
          knowledgeContainerIds: [],
          settings: {
            maxTokens: 16384,
            temperature: 0.6,
            topP: 0.95,
            modelId: assistantModels.find(
              (a: iAssistantModel) =>
                a.region === 'Sweden Central' || a.region === 'France Central',
            )!.id,
            transferLength: 21,
          },
          isPublic: false,
          blueprint: false,
          files: [],
          tools: [],
          knowledgeContainer: [],
          selected: false,
          sharedType: ShareType.None,
          ownerEmail: currentPreSetting!.ownerEmail,
          ownerName: currentPreSetting!.ownerName,
          ownerId: currentPreSetting!.ownerId,
        }
        return (
          <AddEditpreSettings
            currentPreSetting={emptySetting!}
            closeFunction={() => setPopup(Popup.AssistantContainer)}
          />
        )

      case AssistantTab.Edit:
        if (!currentPreSetting) {
          setCurrentPreSetting(
            activeSession?.assistantSettings
              ? activeSession.assistantSettings
              : undefined,
          )
        }
        return (
          <AddEditpreSettings
            currentPreSetting={currentPreSetting!}
            closeFunction={() => setPopup(Popup.AssistantContainer)}
          />
        )

      case AssistantTab.Inspect:
        return (
          <AssistantInspect
            currentPreSetting={currentPreSetting!}
            closeFunction={() => setPopup(Popup.AssistantContainer)}
          />
        )

      case AssistantTab.OnlyInspect:
        return (
          <AssistantInspect
            currentPreSetting={currentPreSetting!}
            closeFunction={props.closeFunction}
          />
        )

      default:
        return (
          <div className="flex justify-center w-full py-2 gap-2">
            <div className="alert alert-error" role="alert">
              <div className="flex-1">
                <label>Invalid Tab</label>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
      onClose={props.closeFunction}
    >
      <ModalContent>
        <ModalBody>{renderCurrentAssistantComponent()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default Assistant
