import React, { useEffect, useState } from 'react'
import { iAssistantEdit } from './iAssistantTypes.tsx'
import KnowledgeContainerService from '../../../services/knowledgeContainerService.ts'
import { iTool } from '../tools/iTool.ts'
import { iKnowledgeContainer } from '../knowledgeContainer/iKnowledgeContainer.ts'
import ProfileImage from '../../utils/profileImage/profileImage.tsx'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import AssistantModelSelection from '../../settings/system/assistantModelSelection.tsx'
import { Button, Input, Textarea } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import InvitationLinkSettings from '../../invitation/invitationLinkSettings.tsx'
import { Role, SharableEntityType } from '../../../interfaces/iShared.ts'
import useAssistantStore from '../../../stateManagement/assistantState.ts'

function AssistantInspect({ currentPreSetting, closeFunction }: iAssistantEdit) {
  const { getTools } = useSessionStore(
    (state) => ({
      getTools: state.getTools,
    }),
    shallow,
  )
  const { hasRightTo } = useAssistantStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )
  const [allTools, setAllTools] = useState<iTool[]>([])
  const [maxOutputTokens, setMaxOutputTokens] = useState<number>()
  const [allAzureContainers, setAllAzureContainers] = useState<
    iKnowledgeContainer[]
  >([])

  useEffect(() => {
    if (!currentPreSetting) {
      return
    }

    // get all accessible tools
    if (currentPreSetting.toolIds && currentPreSetting.toolIds.length > 0) {
      getTools().then((tools) => {
        setAllTools(tools)
      })
    }

    // get all accessible azure containers
    if (
      currentPreSetting.knowledgeContainerIds &&
      currentPreSetting.knowledgeContainerIds.length > 0
    ) {
      KnowledgeContainerService.getKnowledgeContainerContainerList().then(
        (containers) => {
          setAllAzureContainers(containers)
        },
      )
    }
  }, [])

  if (!currentPreSetting) {
    return <></>
  }

  return (
    <div className={'max-w-fit w-50 min-w-[28rem]'}>
      <div>
        <h1 className={'text-xl font-semibold mb-2'}>
          Predefined settings for {currentPreSetting.title}
        </h1>
      </div>

      <div className={'grid grid-col-2'}>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Description</span>
          </label>
          <Input isDisabled value={currentPreSetting.description}></Input>
        </div>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Owner</span>
          </label>
          <div className={'text-sm flex '}>
            <ProfileImage contact={currentPreSetting.ownerEmail!} width={8} />
            <div className={'pl-2 self-center'}>{currentPreSetting.ownerName}</div>
          </div>
        </div>
        {hasRightTo(Role.Manager, currentPreSetting!.id!) &&
          !!(currentPreSetting.shareLinks ?? []).length && (
            <div className={'mt-2'}>
              <label className="label ">
                <span className="label-text">Sharing Links</span>
              </label>
              <InvitationLinkSettings
                readOnly={true}
                links={currentPreSetting.shareLinks ?? []}
                resourceType={SharableEntityType.Assistant}
                resourceId={currentPreSetting!.id!}
                roles={currentPreSetting.roles}
              ></InvitationLinkSettings>
            </div>
          )}
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Model</span>
          </label>
          <AssistantModelSelection
            modelId={currentPreSetting.settings!.modelId!}
            disableHint
            isDisabled
            onChange={(modelValue) => setMaxOutputTokens(modelValue.maxOutputTokens)}
          ></AssistantModelSelection>
        </div>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Instructions</span>
          </label>
          <Textarea
            id="systemPrompt"
            placeholder="System Prompt"
            value={currentPreSetting.instruction}
            isDisabled
            rows={
              currentPreSetting?.instruction &&
              currentPreSetting.instruction.length > 1000
                ? 5
                : 2
            }
          />
        </div>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Max-Output-Tokens</span>
          </label>
          <input
            type="range"
            id="range-mt"
            min={1}
            max={maxOutputTokens}
            step={10}
            inputMode="numeric"
            value={currentPreSetting.settings?.maxTokens || 0}
            className="range cursor-default range-xs"
            disabled={true}
          />
          <div className={'text-center'}>
            {currentPreSetting.settings?.maxTokens}
          </div>
        </div>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Temperature</span>
          </label>
          <input
            type="range"
            id="range-temp"
            min={0}
            max={1}
            step={0.01}
            inputMode="numeric"
            value={currentPreSetting.settings?.temperature || 0}
            className="range cursor-default range-xs"
            disabled={true}
          />
          <div className={'text-center'}>
            {currentPreSetting.settings?.temperature}
          </div>
        </div>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Top-P</span>
          </label>
          <input
            type="range"
            id="range-top-p"
            min={0}
            max={1}
            step={0.01}
            inputMode="numeric"
            value={currentPreSetting.settings?.topP || 0}
            className="range cursor-default range-xs"
            disabled={true}
          />
          <div className={'text-center'}>{currentPreSetting.settings?.topP}</div>
        </div>
        <div className={'mt-2'}>
          <label className="label ">
            <span className="label-text">Transferred</span>
          </label>
          <input
            type="range"
            id="range-fp"
            min={0}
            max={21}
            step={0.01}
            inputMode="numeric"
            value={currentPreSetting.settings?.transferLength || 0}
            className="range cursor-default range-xs"
            disabled={true}
          />
          <div className={'text-center'}>
            {currentPreSetting.settings?.transferLength === 21
              ? 'all'
              : currentPreSetting.settings?.transferLength}
          </div>
        </div>
        {currentPreSetting.toolIds && currentPreSetting.toolIds.length > 0 && (
          <div className={'mt-2'}>
            <label className="label ">
              <span className="label-text">Active Tools</span>
            </label>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {allTools
                  .filter((p) =>
                    currentPreSetting.toolIds?.some((id) => id === p.id),
                  )
                  .map((tool) => (
                    <tr key={tool.id}>
                      <td>{tool.name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {currentPreSetting.knowledgeContainerIds &&
          currentPreSetting.knowledgeContainerIds.length > 0 && (
            <div className={'mt-2'}>
              <label className="label ">
                <span className="label-text">Active Knowledge Container</span>
              </label>
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPreSetting.knowledgeContainerIds?.map((id) => {
                    const container = allAzureContainers.find((p) => p.id === id)
                    return container ? (
                      <tr key={container.id}>
                        <td>{container.name}</td>
                      </tr>
                    ) : (
                      <tr key={id}>
                        <td>Knowledge container with ID {id} has been deleted</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}

        <div className={'text-right mt-2'}>
          <Button onClick={closeFunction}>Close</Button>
        </div>
      </div>
    </div>
  )
}

export default AssistantInspect
