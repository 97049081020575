export interface iShared {
  id: string
  name: string
  email?: string
  accessRight: AccessRight
  entityType: EntityType
  role: Role
}

export enum AccessRight {
  Read,
  Write,
}

export enum EntityType {
  User,
  Team,
}
export enum Role {
  // Owner // not in shared object, has its own field in the item
  Manager,
  Member,
  GaiaUser,
  LimitedMember,
}

export interface iRole {
  // Owner // not in shared object, has its own field in the item
  type: Role
  permissions: iPermission[]
}

export interface iPermission {}

export interface iShareLink {
  linkId: string
  expiration: Date
  usageCount?: number
  allowedEntityTypes: EntityType[]
  role: Role
  linkType: SharableEntityType
}

export interface iShareLinkItemPreview {
  icon: string
  itemId: string
  itemType: SharableEntityType
  name: string
  shared: iShared[]
  onwerId: string
  ownerEmail: string
}

export enum SharableEntityType {
  Assistant,
  Team,
  Tool,
  Session,
  KnowledgeContainer,
}
