import React, { useEffect, useState } from 'react'
import KnowledgeContainerService from '../../../services/knowledgeContainerService.ts'
import SearchInput from '../../utils/searchInput.tsx'
import { iBlobContainer } from '../knowledgeContainer/iBlobUpload.tsx'
import KnowledgeCard from './knowledgeCard.tsx'
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { shallow } from 'zustand/shallow'
import { Button } from '@nextui-org/react'

export interface iKnowledgeConViewProps {
  goNextFunction: () => void
  goBeforeFunction: () => void
}

function KnowledgeConView({
  goNextFunction,
  goBeforeFunction,
}: iKnowledgeConViewProps) {
  const [blobcontainers, setBlobcontainers] = useState<iBlobContainer[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { activeSession, updateSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      activeSession: state.activeSession,
    }),
    shallow,
  )

  /**
   * Reload containers when the selected assistant changes
   */
  useEffect(() => {
    loadBlobContainers()
  }, [activeSession, activeSession?.assistantSettings?.baseAssistantSettingsId])

  const loadBlobContainers = () => {
    setLoading(true)
    KnowledgeContainerService.getKnowledgeContainerContainerList(
      activeSession?.assistantSettings?.baseAssistantSettingsId ?? '',
    ).then((response) => {
      if (!activeSession) {
        return
      }
      let knowledgeContainers = response.map((blob) => {
        const selected =
          activeSession.assistantSettings!.knowledgeContainerIds?.includes(
            blob.id!,
          ) ?? false
        return { ...blob, selected: selected }
      })
      // filter out duplicates, keep the one with fromBaseAssistantInherited = true
      knowledgeContainers = Object.values(
        knowledgeContainers.reduce(
          (acc, blob) => {
            if (acc[blob.id!]) {
              acc[blob.id!].fromBaseAssistantInherited = true
            } else {
              acc[blob.id!] = { ...blob }
            }
            return acc
          },
          {} as { [key: string]: (typeof knowledgeContainers)[0] },
        ),
      )

      setLoading(false)
      setBlobcontainers(knowledgeContainers)
    })
  }

  const filteredBlobContainers = () => {
    if (searchTerm === '' || searchTerm.length < 3) {
      return blobcontainers
    } else {
      return blobcontainers.filter((blobcontainer) => {
        return (
          blobcontainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          blobcontainer.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
    }
  }

  const handleSelect = (blobcontainer: iBlobContainer) => {
    // prevent the uncheck if it is from base assistant inherited
    if (blobcontainer.fromBaseAssistantInherited) return

    const updatedBlobContainers = blobcontainers.map((blob) => {
      if (blob.id === blobcontainer.id) {
        return { ...blob, selected: !blob.selected }
      }
      return blob
    })
    setBlobcontainers(updatedBlobContainers)

    const selectedBlobContainers = updatedBlobContainers.filter(
      (blob) => blob.selected,
    )
    if (activeSession) {
      activeSession.assistantSettings!.knowledgeContainerIds =
        selectedBlobContainers.map((blob) => blob.id!)
      updateSession(activeSession)
    }
  }

  return (
    <>
      <div className="justify-center space-y-4 mx-auto overflow-y-auto p-4 max-w-[1500px] rounded-xl overflow-hidden sticky top-0 w-full flex">
        <div className={'h-full justify-center flex flex-col-reverse'}>
          <button
            onClick={() => goBeforeFunction()}
            className={
              'btn-glass px-2 max-h-12 rounded-lg hover:-translate-y-1 hover:scale-110 duration-300 indicator'
            }
          >
            <ArrowLeftIcon
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            />
          </button>
        </div>
        <div className={'w-full'}>
          <div className={'justify-center'}>
            <h1 className={'text-xl font-semibold mb-2'}>Knowledge Container</h1>
            <p className={''}>
              Select the knowledge container you want to add to the session.
            </p>
          </div>
          {(loading && (
            <div className={'grid grid-cols-1 h-20'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )) ||
            (blobcontainers.length > 0 && (
              <>
                <SearchInput
                  searchTerm={searchTerm}
                  placeholder={'Search for container or description'}
                  setSearchTerm={setSearchTerm}
                >
                  <Button
                    isIconOnly
                    variant={'light'}
                    onClick={loadBlobContainers}
                    className=" mr-2"
                  >
                    <ArrowPathIcon className={'h-6 w-6'}></ArrowPathIcon>
                  </Button>
                </SearchInput>
                <div className="flex flex-wrap justify-center overflow-y-auto">
                  {filteredBlobContainers().map((blobcontainer, key) => {
                    return (
                      <KnowledgeCard
                        key={key}
                        knowledgeContainer={blobcontainer}
                        handleSelect={handleSelect}
                      />
                    )
                  })}
                </div>
              </>
            ))}
        </div>
        <div className={'h-full justify-center flex flex-col-reverse !m-0'}>
          <button
            onClick={() => goNextFunction()}
            className={
              'btn-glass px-2 max-h-12 rounded-lg hover:-translate-y-1 hover:scale-110 duration-300 indicator'
            }
          >
            <ArrowRightIcon
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default KnowledgeConView
