import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import legalJSON from '../../../public/legal/legal.json'
import useUserProfileStore from '../../stateManagement/userProfileState.ts'
import { shallow } from 'zustand/shallow'
import { iTermsOfUse, iTermsOfUseContent } from './iTermsOfUse.ts'
import MarkdownPreview from '@uiw/react-markdown-preview'
import FileAttachmentsService from '../../services/fileAttachmentService.ts'
import { iFileAttachment } from '../fileAttachments/iFileAttachment.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { getUserCountry } from '../login/authentication.tsx'

export const GAIA_LEGAL_JSON = {
  version: legalJSON.date,
  content: [
    {
      language: 'en',
      isFallback: true,
      text: `In order to use GAIA, the terms of use and privacy policy must be
              accepted. If you agree to the terms and conditions, click on the
              "Accept" button`,
      documents: [
        {
          id: 'gaia-en-tou',
          displayName: 'Terms of Use',
          fileName: `./legal/${
            legalJSON.termsOfUse.find(
              (tou: { lang: string; fileName: string }) => tou.lang === 'en',
            )?.fileName
          }`,
        },
        // {
        //   id: 'gaia-en-data-protection',
        //   displayName: 'Data Protection',
        //   fileName: `./legal/${
        //     legalJSON.dataProtection.find(
        //       (tou: { lang: string; fileName: string }) => tou.lang === 'en',
        //     )?.fileName
        //   }`,
        // },
      ],
    },
  ],
}

type LegalDialogProps = React.HTMLAttributes<HTMLDialogElement> & {
  termsOfUse?: iTermsOfUse
  resourceId: string | 'gaia'
}
const LegalDialog = ({ termsOfUse, resourceId }: LegalDialogProps) => {
  const [selectedLanguage] = useState<string>(getUserCountry() || 'en')
  const [termsOfUseContent, setTermsOfUseContent] =
    useState<iTermsOfUseContent | null>(null)
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { acceptedToUVersion, userProfile } = useUserProfileStore(
    (state) => ({
      acceptedToUVersion: state.acceptedToUVersion,
      userProfile: state.userProfile,
    }),
    shallow,
  )

  const { activeSession, updateSession, getSession, activateSession } =
    useSessionStore(
      (state) => ({
        updateSession: state.updateSession,
        activeSession: state.activeSession,
        getSession: state.getSession,
        activateSession: state.activateSession,
      }),
      shallow,
    )

  const hasOpenToU = useMemo(() => {
    if (!termsOfUse || !userProfile) return false
    const acceptedDate =
      userProfile.acceptedToUVersion && userProfile.acceptedToUVersion[resourceId]
    if (acceptedDate) {
      return termsOfUse.version > acceptedDate
    } else {
      return true
    }
  }, [userProfile, termsOfUse, resourceId])

  const findContent = (
    termsOfUse: iTermsOfUse,
    lang: string,
  ): iTermsOfUseContent => {
    let content =
      termsOfUse.content.find(
        (content: iTermsOfUseContent) => content.language === lang,
      ) || null
    if (!content) {
      content =
        termsOfUse.content.find(
          (content: iTermsOfUseContent) => content.isFallback,
        ) || null
    }
    if (!content) {
      content = termsOfUse.content[0]
    }
    return { ...content }
  }

  const getDocumentHref = (document: iFileAttachment): string => {
    if (document.id.startsWith('gaia-')) {
      return document.fileName || ''
    }
    return FileAttachmentsService.getFileAttachmentLink(document)
  }

  useEffect(() => {
    if (!termsOfUse) return
    setTermsOfUseContent({ ...findContent(termsOfUse, selectedLanguage) })
    if (hasOpenToU) {
      onOpen()
    }
  }, [hasOpenToU, termsOfUse, selectedLanguage])

  const handleAcceptLegalDialog = () => {
    setIsLoading(true)
    acceptedToUVersion(resourceId)
      .then(() => {
        const session = activeSession!
        session.assistantSettings!.baseAssistantSettingsId = resourceId
        return updateSession(session)
      })
      .then(() => onClose())
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={isOpen}
        hideCloseButton={resourceId === 'gaia'}
        isDismissable={false}
        classNames={{
          base: '!max-w-[500px] w-fit',
        }}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            getSession(activeSession!.id!)
              .then((session) => {
                return activateSession(session!, true)
              })
              .then(() => onClose())
          } else {
            onOpenChange()
          }
        }}
      >
        <ModalContent>
          <ModalHeader>
            <h3 className="font-bold text-lg">Terms of Service</h3>
          </ModalHeader>
          <ModalBody>
            <MarkdownPreview
              source={termsOfUseContent?.text}
              className={'bg-white text-black'}
              style={{
                color: 'black',
                backgroundColor: 'white',
              }}
              warpperElement={{ 'data-color-mode': 'light' }}
            ></MarkdownPreview>
            {termsOfUseContent?.documents.map((document) => (
              <Link
                key={document.displayName}
                underline={'always'}
                isExternal
                href={getDocumentHref(document)}
                showAnchorIcon
              >
                {document.displayName}
              </Link>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              color={'primary'}
              onClick={() => handleAcceptLegalDialog()}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default LegalDialog
