import { iRole, iShared, iShareLink } from '../../../interfaces/iShared.ts'
import { iItem } from '../../../interfaces/iItem.ts'

export interface iTeam extends iItem {
  name: string
  costCenter: string
  spent: number
  budget: number
  description: string
  shared: iShared[]
  selected?: boolean
  isAssistantSettingsAccessLimited?: boolean
  // TODO remove in future versions
  assistantSettingsId?: string
  ownerId?: string
  apiAccess?: boolean
  roles?: iRole[]
  shareLinks: iShareLink[]
  resourceRestrictions: {
    assistantIds: string[] | null
    knowledgeContainerIds: string[] | null
    modelIds: string[] | null
    toolIds: string[] | null
  }
}

export const isDefaultTeam = (team: iTeam): boolean =>
  team.id === '00000000-0000-0000-0000-000000000000'
