import React, { useMemo, useState } from 'react'
import { iFolder, SessionModalMode } from './interfaces/iFolder.ts'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useTeamStore from '../../stateManagement/teamState.ts'
import useFolderStore from '../../stateManagement/folderState.ts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { shallow } from 'zustand/shallow'

interface iFolderFormInput {
  title: string
}

interface iDialogSessionFolderModalProps {
  open: boolean
  folder?: iFolder | undefined
  onCreated?: (newFolder: iFolder) => Promise<void>
  onClose?: () => void
}

function DialogSessionFolderModal({
  open,
  folder,
  onCreated,
  onClose,
}: iDialogSessionFolderModalProps) {
  const { updateFolder, addFolder } = useFolderStore(
    (state) => ({
      updateFolder: state.updateFolder,
      addFolder: state.addFolder,
    }),
    shallow,
  )
  const selectedTeam = useTeamStore((state) => state.selectedTeam, shallow)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const folderModalMode = useMemo(
    () => (folder ? SessionModalMode.Edit : SessionModalMode.Create),
    [folder],
  )

  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<iFolderFormInput>({
    defaultValues: {
      title: folder?.title ?? '',
    },
  })

  // validate the new folder name and the settings
  const plausabilityCheckTitle = (title: string) => {
    const validName = /^[A-Za-z0-9 -]*[A-Za-z0-9][A-Za-z0-9 -]*$/i.test(title)
    return validName
      ? true
      : 'Folder name must be larger than 3 letters and must not contain special characters.'
  }

  /**
   * Check if the new folder name is valid and create the folder
   */
  const saveFolder: SubmitHandler<iFolderFormInput> = (data) => {
    const tmpfolder: iFolder =
      folder ??
      ({
        title: data.title,
        teamId: selectedTeam?.id,
      } as iFolder)
    tmpfolder.title = data.title.trim()
    setIsLoading(true)
    const method = tmpfolder.id ? updateFolder : addFolder
    method(tmpfolder).then(() => {
      onCreated && onCreated(tmpfolder)
      setIsLoading(false)
      prepareClose()
    })
  }

  /**
   * reset the form and close the modal
   */
  const prepareClose = () => {
    reset({})
    onClose && onClose()
  }

  const saveButtonTitle = useMemo(
    () =>
      folderModalMode === SessionModalMode.Edit ? 'Edit Folder' : 'Create Folder',
    [],
  )

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={open}
      onClose={onClose}
      classNames={{
        base: '!max-w-[100vw] w-fit w-[350px]',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <h3 className="font-bold text-lg">{saveButtonTitle}</h3>
        </ModalHeader>

        <ModalBody>
          <>
            <Input
              type="text"
              label="Folder Name"
              {...register('title', {
                required: 'Folder name is required',
                minLength: {
                  value: 3,
                  message: 'Title must have an minimum of four characters',
                },
                maxLength: {
                  value: 64,
                  message: 'Title can only have a maximum of 64 characters',
                },
                validate: plausabilityCheckTitle,
              })}
              isInvalid={!!errors.title}
              errorMessage={errors.title?.message}
            />
          </>
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              variant={'light'}
              className="mr-4"
              onClick={() => prepareClose()}
            >
              Close
            </Button>

            <Button
              disabled={isLoading}
              isLoading={isLoading}
              color={'primary'}
              onClick={handleSubmit(saveFolder)}
            >
              {saveButtonTitle}
            </Button>
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DialogSessionFolderModal
