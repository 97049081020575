import React, { useEffect, useState } from 'react'
import FilePicker from './filePicker'
import FileNodeTree from '../node/fileNodeTree'
import iSettingsProps from '../../settings/iSettingsProps.ts'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import {
  CustomFile,
  CustomFileSystemEntry,
  processDirectory,
} from './processDirectory.tsx'
import { iMessage } from '../../chatWindow/iMessage.ts'
import { useMessages } from '../../../stateManagement/contexts/messageContext.tsx'
import KnowledgeContainerService from '../../../services/knowledgeContainerService'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import useTeamStore from '../../../stateManagement/teamState.ts'
import DocumentAnalysisSelection, {
  DocumentAnalysisModelId,
} from '../knowledgeContainer/documentAnalysisSelection.tsx'
import { shallow } from 'zustand/shallow'

export interface iFileData {
  name: string
  document: iDocument
  tokenCount: number
  extension: string
}

export interface iDocument {
  content: string
  sourcefile: string
}

interface iFileDictionary {
  [key: string]: iFileData
}

function FileSettings({ closeFunction }: iSettingsProps) {
  const { updateSession, activeSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const { createMessage } = useMessages()
  const [selectedFiles, setSelectedFiles] = useState<Set<CustomFile>>(new Set())
  const [documentAnalysis, setDocumentAnalysis] =
    useState<DocumentAnalysisModelId>('prebuilt-read')
  const [originalFiles, setOriginalFiles] = useState<CustomFile[]>([])
  const [alertMessages, setAlertMessages] = useState<string[]>([])
  // const [messages, setMessages] = useState<iMessage[]>([]);
  const [isDragging, setIsDragging] = useState(false)
  const [fileData, setFileData] = useState<iFileDictionary>({})
  const [loadingFileData, setLoadingFileData] = useState(false)
  const { selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  useEffect(() => {
    if (!activeSession) {
      return
    }

    if (
      activeSession.originalFiles &&
      activeSession.originalFiles.length !== 0 &&
      originalFiles?.length === 0
    ) {
      setOriginalFiles(
        activeSession.originalFiles.filter((file) => file.name !== null),
      )
    }

    if (
      activeSession.selectedFiles &&
      activeSession.selectedFiles.length !== 0 &&
      selectedFiles?.size === 0
    ) {
      setSelectedFiles(
        new Set(activeSession.selectedFiles.filter((file) => file.name !== null)),
      )
    }
    const fileDataImport: iFileDictionary =
      activeSession.fileData
        ?.filter((file) => file.name !== null)
        .map((file: iFileData) => {
          return { [file.name]: file }
        })
        .reduce((acc, file) => {
          return { ...acc, ...file }
        }, {}) || {}
    setFileData(fileDataImport || {})
  }, [activeSession])

  useEffect(() => {
    // get document text for each file
    if (!selectedFiles) {
      return
    }
    if (selectedFiles.size === 0) {
      return
    }
    // filenames with complete path
    const selectedFileNames = new Set(
      Array.from(selectedFiles).map((file) => {
        if (file.webkitRelativePath && file.webkitRelativePath.length > 0) {
          return file.webkitRelativePath
        }
        return file.name
      }),
    )
    // already processed files
    const fileDataNames = new Set(Object.keys(fileData))

    const missingFileNames = Array.from(selectedFileNames).filter(
      (name) => !fileDataNames.has(name),
    )
    if (missingFileNames.length === 0) {
      return
    }

    // get the file data for the missing files

    const missingFiles = Array.from(selectedFiles).filter((file) => {
      const fullFileName =
        file.webkitRelativePath && file.webkitRelativePath.length > 0
          ? file.webkitRelativePath
          : file.name
      return missingFileNames.includes(fullFileName)
    })
    setLoadingFileData(true)
    const promises = missingFiles.map(async (file) => {
      return getDocumentText(file)
    })
    Promise.all(promises).then(() => {
      setLoadingFileData(false)

      activeSession!.originalFiles = originalFiles
      activeSession!.selectedFiles = Array.from(selectedFiles)
      updateSession(activeSession!)
    })
  }, [selectedFiles])

  useEffect(() => {
    if (activeSession && !loadingFileData) {
      activeSession.fileData = Object.keys(fileData).map((key) => fileData[key])
      updateSession(activeSession)
    }
  }, [fileData, loadingFileData])

  const getDocumentText = async (file: File): Promise<void> => {
    const response = await KnowledgeContainerService.getDocumentText(
      file,
      file.name,
      selectedTeam?.id || '',
      null,
      activeSession?.assistantSettings?.baseAssistantSettingsId ?? '',
      documentAnalysis,
    )
    const fullFileName =
      file.webkitRelativePath.length > 0 ? file.webkitRelativePath : file.name
    const fileData: iFileData = {
      name: fullFileName,
      document: response.document,
      tokenCount: response.tokenCount,
      extension: file.name.split('.').pop()!,
    }
    setFileData((prevFileData) => {
      return { ...prevFileData, [fullFileName]: fileData }
    })
  }

  const handleRemoveAlert = (index: number) => {
    setAlertMessages((prevAlertMessages) =>
      prevAlertMessages.filter((_, i) => i !== index),
    )
  }

  const handleAddToChatAndClose = async () => {
    try {
      if (!selectedFiles) {
        return
      }

      // get the file data for the selected files
      const selectedFileData = Array.from(selectedFiles).map((file) => {
        const fullFileName =
          file.webkitRelativePath.length > 0 ? file.webkitRelativePath : file.name
        return fileData[fullFileName]
      })
      const contents = selectedFileData.map(
        (file) => file.name + ' -> ' + file.document.content,
      )
      const content = contents.join('\n')

      const contentMessage: iMessage = {
        role: 'user',
        content: content,
        sessionId: activeSession!.id!,
        contentType: 'contentMessage',
        files: Array.from(selectedFiles),
        filesData: selectedFileData,
      }

      if (!activeSession) return

      activeSession.originalFiles = originalFiles
      activeSession.selectedFiles = Array.from(selectedFiles)
      activeSession.fileData = Object.keys(fileData).map((key) => fileData[key])

      await createMessage(activeSession!, contentMessage)

      // const newMessages = [...session.messages!, contentMessage];
      // setMessages(newMessages);
      await updateSession(activeSession!)
      closeFunction()
    } catch (error) {
      console.error('Error reading file content:', error)
    }
  }

  const clearFiles = () => {
    setOriginalFiles([])
    setSelectedFiles(new Set())
    setFileData({})

    // clear the values in the session and update the session
    if (activeSession) {
      activeSession.originalFiles = []
      activeSession.selectedFiles = []
      activeSession.fileData = []
      updateSession(activeSession)
    }
  }

  const clearUnusedFiles = () => {
    const newOriginalFiles = originalFiles.filter((file) => selectedFiles.has(file))
    setOriginalFiles(newOriginalFiles)
  }

  const handleSelectionChange = (
    path: string,
    name: string,
    isSelected: boolean,
  ) => {
    const file = originalFiles.find(
      (file) => file.webkitRelativePath === path && file.name === name,
    )

    if (file) {
      setSelectedFiles((prevSelectedFiles) => {
        const newSelectedFiles = new Set(prevSelectedFiles)
        if (isSelected) {
          newSelectedFiles.add(file)
        } else {
          newSelectedFiles.delete(file)
        }
        return newSelectedFiles
      })
    }
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!isDragging) {
      setIsDragging(true)
    }
  }

  const onDragLeave = () => {
    setIsDragging(false)
  }

  /**
   * When files are dropped into the field event, process them
   * @param event
   */
  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)

    const items = event.dataTransfer.items
    const processedFiles: File[] = []

    for (const item of items) {
      const entry = item.webkitGetAsEntry()! as CustomFileSystemEntry
      if (entry.isDirectory) {
        const files = await processDirectory(entry)
        processedFiles.push(...files)
      } else if (entry.isFile) {
        const file = await new Promise<File>((resolve) => entry.file(resolve))
        // create a custom file object
        const customFile = new CustomFile(
          [file],
          file.name,
          { type: file.type },
          file.name,
        )
        processedFiles.push(customFile)
      }
    }

    const fileList = Object.setPrototypeOf(processedFiles, FileList.prototype)
    handleFilesSelect(fileList)
  }

  /**
   * Pute the selected files into the original files list
   * @param files
   */
  const handleFilesSelect = (files: FileList) => {
    const filesArray = Array.from(files)
    // merge existing files with new files
    const newFiles = filesArray.filter(
      (file) =>
        !originalFiles.find(
          (existingFile) =>
            existingFile.webkitRelativePath === file.webkitRelativePath &&
            existingFile.name === file.name,
        ),
    )
    setOriginalFiles(newFiles.concat(originalFiles))
  }

  const calculateTokenCount = () => {
    const selectedFileNames = new Set(
      Array.from(selectedFiles).map((file) => {
        if (file.webkitRelativePath && file.webkitRelativePath.length > 0) {
          return file.webkitRelativePath
        }
        return file.name
      }),
    )
    return Object.keys(fileData)
      .filter((name) => selectedFileNames.has(name))
      .map((key) => fileData[key])
      .reduce((acc, file) => acc + file.tokenCount, 0)
  }

  return (
    (activeSession && (
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={true}
        onClose={closeFunction}
        classNames={{
          base: '!max-w-[100vw] w-fit',
        }}
      >
        <ModalContent>
          <ModalHeader>
            <>
              {alertMessages.map((message, index) => (
                <div
                  key={index}
                  className="alert alert-error"
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1000,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>{message}</span>
                  <button onClick={() => handleRemoveAlert(index)}>Close</button>
                </div>
              ))}

              <div>
                <h1 className={'text-xl font-semibold mb-2'}>Local Files</h1>
                <p>
                  Use your files and add them to the context of the model. <br />
                  Please only use files that contain text.
                </p>
              </div>
            </>
          </ModalHeader>
          <ModalBody>
            <>
              <div
                className={`w-full h-40 bg-gray-200 border-dashed border-2 border-gray-400 flex items-center justify-center 
        ${isDragging ? 'border-blue-500' : ''}`}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              >
                <div className={'text-center items-center justify-center'}>
                  <div className={'flex items-center justify-center'}>
                    <PaperClipIcon className={'h-8 w-8 text-gray-400'} />
                    <p>Drag and drop files/folders here</p>
                  </div>
                  <p>or</p>
                  <FilePicker onFilesSelect={handleFilesSelect} forFiles={true} />
                </div>
              </div>
              <DocumentAnalysisSelection
                onSelectionChange={(analysisType) => {
                  setDocumentAnalysis(analysisType)
                }}
                defaultValue={documentAnalysis}
              ></DocumentAnalysisSelection>
              {originalFiles.length > 0 && (
                <>
                  <div className="mt-3">
                    <h2 className="text-lg font-semibold mb-2">Select files</h2>

                    <FileNodeTree
                      originalFiles={originalFiles}
                      selectedFiles={Array.from(selectedFiles)}
                      onSelectionChange={handleSelectionChange}
                    />
                    <div className="flex justify-between my-2">
                      <button
                        className="btn btn-primary btn-xs"
                        onClick={clearFiles}
                      >
                        Clear Files
                      </button>
                      <button
                        className="btn btn-primary btn-xs"
                        onClick={clearUnusedFiles}
                      >
                        Clear unused Files
                      </button>
                    </div>
                  </div>

                  <div className="flex justify-between my-2">
                    <div className="text-sm text-gray-500">
                      {selectedFiles.size} file(s) selected
                      <br />
                      with a total size of {calculateTokenCount()} tokens
                    </div>
                  </div>
                </>
              )}
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeFunction}>Close</Button>
            <Button
              color={'primary'}
              className="ml-3"
              onClick={handleAddToChatAndClose}
              disabled={selectedFiles.size === 0 || loadingFileData}
            >
              {loadingFileData && <span className="loading loading-spinner"></span>}
              {!loadingFileData && <span>Add to Chat</span>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )) || <></>
  )
}

export default FileSettings
