import React from 'react'

import ContextPopup from '../components/sessionControls/contextPopup.tsx'
import FeedbackAlert from '../components/utils/feedbackAlert.tsx'
import AppDrawer from '../components/app/appDrawer.tsx'
import BuildProviderTree from '../utils/buildProviderTree.tsx'
import { NextUIProvider } from '@nextui-org/react'
import MainProvider from '../stateManagement/providers/mainProvider.tsx'
import MessageProvider from '../stateManagement/providers/messageProvider.tsx'
import LoadingScreen from '../components/loadingScreen/loadingScreen.tsx'
import InvitationDialog from '../components/invitation/invitationDialog.tsx'
import LegalDialog, { GAIA_LEGAL_JSON } from '../components/legal/legalDialog.tsx'

type DefaultLayoutProps = {
  loginState: number
}

function DefaultLayout({ loginState }: DefaultLayoutProps) {
  const Providers = BuildProviderTree([
    <NextUIProvider children={''} />,
    <MainProvider />,
    <MessageProvider />,
  ])
  return loginState === 1 ? (
    <Providers>
      <ContextPopup />
      <FeedbackAlert />
      <LegalDialog termsOfUse={GAIA_LEGAL_JSON} resourceId={'gaia'}></LegalDialog>
      <InvitationDialog></InvitationDialog>
      <div className={'flex flex-col h-screen'}>
        {/* Drawer left side */}
        <AppDrawer></AppDrawer>
      </div>
    </Providers>
  ) : (
    <LoadingScreen isLoading={loginState !== 1}></LoadingScreen>
  )
}

export default DefaultLayout
